export function users(params = {}) {
  return window.$axios.get(`${window.service.api}/customer/users`, { params });
}

export function delUser(id) {
  return window.$axios.delete(`${window.service.api}/customer/user_delete?id=${id}`);
}

export function user_reset_pass(data) {
  return window.$axios.post(`${window.service.api}/customer/user_reset_pass`, data);
}

export function user_force_logout(data) {
  return window.$axios.put(`${window.service.api}/customer/user_force_logout`, data);
}

export function user_create(data) {
  return window.$axios.post(`${window.service.api}/customer/user_create`, data);
}

export function user_create_v2(data) {
  return window.$axios.post(`${window.service.api}/customer/user_create_v2`, data);
}
