<template>
  <el-container>
    <div class="empty hide">
      <div>
        <img src="@/assets/images/common/empty/img_xssj.svg" alt="暂无数据" width="400" class="img" type="image/svg+xml">
        <p>暂无数据</p>
      </div>
    </div>
    <div class="w-100 yq-statistics pb-20">
      <el-row :gutter="20" class="el-row-20">
        <el-col :span="12">
          <el-card class="box-card mod-echarts-box h-400 ov-v" id="voicetrendCard" v-loading="loading.voicetrend">
            <div slot="header" class="clearfix pos-r">
              <span class="tit">信息声量走势</span>
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，各媒体发布的信息数量<br>随时间变化的趋势</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
              <feature :filter="filterData.voicetrend" v-on:downPhoto="downPhoto('voicetrendCard')" :showDown="true" v-on:filter="voicetrendFilter" />
            </div>
            <div class="echarts_empty" v-show="emptyChart.voicetrend">
              <div class="img">暂无数据</div>
            </div>
            <div id="voicetrend" class="mod-echarts-cont" v-show="!emptyChart.voicetrend"></div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="box-card mod-echarts-box h-400" v-loading="loading.emotiontrend" id="emotiontrendCard">
            <div slot="header" class="clearfix pos-r ov-v">
              <span class="tit">情感声量走势</span>
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，敏感和非敏感信息数量<br>随时间变化的趋势</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
              <feature :filter="filterData.emotiontrend" v-on:downPhoto="downPhoto('emotiontrendCard')" :showDown="true" v-on:filter="emotiontrendFilter" />
            </div>
            <div class="echarts_empty" v-show="emptyChart.emotiontrend">
              <div class="img">暂无数据</div>
            </div>
            <div id="emotiontrend" class="mod-echarts-cont" v-show="!emptyChart.emotiontrend"></div>
          </el-card>
        </el-col>
      </el-row>
      <el-row class="mar-t-20 el-row-20" :gutter="20">
        <el-col :span="12">
          <el-card class="box-card mod-echarts-box h-400" id="mediadistrictCard" v-loading="loading.mediadistrict">
            <div slot="header" class="clearfix pos-r ov-v">
              <span class="tit">媒体类型分布</span>
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，各媒体发布的信息占总<br />信息数量的比重</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>

              <feature :filter="[]" v-on:downPhoto="downPhoto('mediadistrictCard')" :showDown="true" />
            </div>
            <div class="echarts_empty" v-show="emptyChart.mediadistrict">
              <div class="img">暂无数据</div>
            </div>
            <div id="mediadistrict" class="mod-echarts-cont" v-show="!emptyChart.mediadistrict"></div>
            <div class="top10media-container" v-if="!emptyChart.top10media" v-loading="loading.top10media">
              <div class="btn_cont">
                <a class="left" @click="top10mediaLeft"></a>
                <span class="tag">{{ chartData.top10mediaName }}</span>
                <a class="right" @click="top10mediaRight"></a>
              </div>
              <div class="lh-28">
                <ul class="top10media-echarts-box f-l">
                  <li :class="top10mediaClassName(index)" v-for="(row, index) in chartData.top10media" :key="index" :style="top10mediaStyle()" @click="list({key: 'top10media', value: {source_name: row.name}})">{{ row.rate }}%</li>
                </ul>
                <ul class="top10media-box-right f-l">
                  <li :class="top10mediaClassName(index)" v-for="(row, index) in chartData.top10media" :key="index" @click="list({key: 'top10media', value: {source_name: row.name}})" :title="row.name">{{ row.name }}</li>
                </ul>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="box-card mod-echarts-box h-400" id="sensitiveCard" v-loading="loading.sensitive">
            <div slot="header" class="clearfix pos-r ov-v">
              <span class="tit">敏感信息占比</span>
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，敏感信息占总信息数量<br />的比重</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>

              <feature :filter="[]" v-on:downPhoto="downPhoto('sensitiveCard')" :showDown="true" />
            </div>
            <div class="echarts_empty" v-show="emptyChart.sensitive">
              <div class="img">暂无数据</div>
            </div>
            <div id="sensitive" class="mod-echarts-cont" v-show="!emptyChart.sensitive"></div>
          </el-card>
        </el-col>
      </el-row>
      <el-row class="mar-t-20 el-row-20" :gutter="20">
        <el-col :span="12">
          <el-card class="box-card mod-echarts-box h-400 hot-keywords" id="hotkeysCard" v-loading="loading.hotkeys">
            <div slot="header" class="clearfix pos-r ov-v">
              <span class="tit">热门关键词</span>
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">对时间段内的信息内容进行分词及<br />重要关键词词频统计，展示词频<br />最高的50个重要关键词</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>

              <feature :filter="[]" v-on:downPhoto="downPhoto('hotkeysCard')" :showDown="true" />
              <i :class="{'iconfont': true, 'icon-ciyun': true, 'selected': showTableState.hotKey === 'chart'}" @click="showTableState.hotKey = 'chart'"></i>
              <i :class="{'iconfont': true, 'icon-cipin': true, 'selected': showTableState.hotKey === 'table'}" @click="showTableState.hotKey = 'table'"></i>
            </div>
            <div class="echarts_empty" v-show="emptyChart.hotkeys">
              <div class="img">暂无数据</div>
            </div>
            <div v-show="!emptyChart.hotkeys">
              <div id="hotkeys" class="mod-echarts-cont" v-show="showTableState.hotKey === 'chart'"></div>
              <div class="table-list" v-show="showTableState.hotKey === 'table'">
                <ul>
                  <li>
                    <h3>排名</h3>
                    <h3>热门关键词</h3>
                    <h3>信息数量</h3>
                  </li>
                  <li v-for="(item, index) in hotKeyData" :key="index">
                    <span>{{ index + 1 }}</span>
                    <span>{{ item.name }}</span>
                    <span>{{ item.value }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="box-card mod-echarts-box surge-keywords h-400" id="ascendkeyCard" v-loading="loading.ascendkey">
            <div slot="header" class="clearfix pos-r ov-v">
              <span class="tit">飙升关键词</span>
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，词频上升最快的30个<br />重要关键词</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
              <feature :filter="[]" v-on:downPhoto="downPhoto('ascendkeyCard')" :showDown="true" />
              <i :class="{'iconfont': true, 'icon-citiao': true, 'selected': showTableState.ascendkey === 'chart'}" @click="showTableState.ascendkey = 'chart'">
              </i>
              <i :class="{'iconfont': true, 'icon-cipin': true, 'selected': showTableState.ascendkey === 'table'}" @click="showTableState.ascendkey = 'table'">
              </i>
            </div>
            <div class="echarts_empty" v-show="emptyChart.ascendkey">
              <div class="img">暂无数据</div>
            </div>
            <div v-show="!emptyChart.ascendkey">
              <ul class="mod-list" v-show="showTableState.ascendkey === 'chart'">
                <li v-for="(item, index) in chartData.ascendkey" :key="index" v-if="index <= 9" @click="list({key: 'ascendkey', value: {keyword: item.key}})">
                  <span class="mod-list-title1">{{ item.key }}</span>
                  <el-progress :text-inside="true" :stroke-width="10" :percentage="item.percent" :show-text="false"></el-progress>
                  <span class="tag">{{ item.rise }}</span>
                </li>
              </ul>
              <div class="table-list" v-show="showTableState.ascendkey === 'table'">
                <ul>
                  <li>
                    <h3>排名</h3>
                    <h3>飙升关键词</h3>
                    <h3>新增信息量</h3>
                  </li>
                  <li v-for="(item, index) in chartData.ascendkey" :key="index">
                    <span>{{ index + 1 }}</span>
                    <span>{{ item.key }}</span>
                    <span>{{ item.num }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row class="mar-t-20 el-row-20" :gutter="20">
        <el-col :span="12">
          <el-card class="box-card mod-echarts-box viocedistrict h-400" id="viocedistrictCard" v-loading="loading.viocedistrict">
            <div slot="header" class="clearfix pos-r ov-v">
              <span class="tit">地区声量分布</span>
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">时间段内，与监测方案相关的信息<br />在各地区的分布情况</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>

              <feature :filter="[]" v-on:downPhoto="downPhoto('viocedistrictCard')" :showDown="true" />
            </div>
            <div class="echarts_empty" v-show="emptyChart.viocedistrict">
              <div class="img">暂无数据</div>
            </div>
            <div id="viocedistrict" class="mod-echarts-cont" v-show="!emptyChart.viocedistrict"></div>
            <div class="content" v-loading="loading.viocedistrict" v-show="!emptyChart.viocedistrict">
              <h3>地区声量排行</h3>
              <ul class="mod-list">
                <li v-for="(row, index) in chartData.viocedistrict" :key="index" v-if="index <= 2">
                  <span class="num bg-555" v-if="index == 0">1</span>
                  <span class="num bg-689" v-else-if="index == 1">2</span>
                  <span class="num bg-8ea" v-else-if="index == 2">3</span>
                  <a class="tit" @click="list({key: 'viocedistrict', value: {province: row.name}})">{{ row.name }} {{ row.value }}条</a>
                </li>
              </ul>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="box-card mod-echarts-box frequency h-400 ov-v" id="frequencyCard" v-loading="loading.frequency">
            <div slot="header" class="clearfix pos-r ov-v">
              <span class="tit">高频情感词汇</span>
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">对时间段内的信息进行情感分析，<br />提取敏感信息中的负面关键词，非<br />敏感信息中的正面关键词，并进行<br />词频统计，分别展示词频最高的10个<br />情感关键词</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>

              <feature :filter="[]" v-on:downPhoto="downPhoto('frequencyCard')" :showDown="true" />
            </div>
            <div class="echarts_empty" v-show="emptyChart.frequency">
              <div class="img">暂无数据</div>
            </div>
            <div class="keysword-list" v-show="!emptyChart.frequency">
              <ul>
                <li v-for="(item, index) in chartData.frequency" :class="{positive: item.emotion === 'positive', negative: item.emotion === 'negative'}" :key="index" @click="list({key: 'frequency', value: {keyword: item.name, emotion: item.emotion}})">{{ item.name }}
                  <div class="node-info">
                    <span class="tit">{{ item.name }}</span>
                    <div>
                      <span :class="{ico: true, fm: item.emotion === 'negative', zm: item.emotion === 'positive'}"></span>
                      <span>{{ item.value }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <ul class="zs" v-show="!emptyChart.frequency">
              <li><span class="ico bg_ea3"></span><span>正面</span></li>
              <li><span class="ico"></span><span>负面</span></li>
            </ul>
          </el-card>
        </el-col>
      </el-row>
      <article-list ref="list" />
    </div>
  </el-container>
</template>
<script>
import feature from '@components/common/feature'
import articleList from '@components/common/article-list.vue'
import {
  globalGetEcharts,
  globalCreateChart,
  globalMediaColor,
  globalFormatDate,
  dom2image
} from '@/utils/helpers'
import { dateFilterOptions } from './constants'
import { mapGetters } from 'vuex'
import {
  voicetrend as voicetrendReq,
  emotiontrend as emotiontrendReq,
  mediadistrict as mediadistrictReq,
  top10media as top10mediaReq,
  sensitive as sensitiveReq,
  hotkeys as hotkeysReq,
  ascendkey as ascendkeyReq,
  viocedistrict as viocedistrictReq,
  frequency as frequencyReq
} from '@/api/visual'
const _ = window._
const d3 = require("d3");
export default {
  name: 'yqxx-statistics',
  props: {},
  components: {
    feature,
    articleList
  },
  data() {
    return {
      params: {},
      mustLoading: false,
      loading: {
        voicetrend: true,
        emotiontrend: true,
        mediadistrict: true,
        sensitive: true,
        hotkeys: true,
        ascendkey: true,
        viocedistrict: true,
        frequency: true,
        top10media: true
      },
      emptyChart: {
        voicetrend: true,
        emotiontrend: true,
        mediadistrict: true,
        sensitive: true,
        hotkeys: true,
        ascendkey: true,
        viocedistrict: true,
        frequency: true,
        top10media: true
      },
      filterData: {
        voicetrend: JSON.parse(JSON.stringify(dateFilterOptions)),
        emotiontrend: JSON.parse(JSON.stringify(dateFilterOptions))
      },
      chartData: {
        hotkeys: [],
        ascendkey: [],
        viocedistrict: [],
        top10media: [],
        top10mediaName: '',
        mediadistrict: [],
        frequency: [],
        mediaCount: []
      },
      isDestroyed: false,
      statistics: undefined,
      hotKeyData: [],
      showTableState: {
        ascendkey: 'chart',
        hotKey: 'chart'
      }
    }
  },
  destroyed() {
    this.isDestroyed = true
  },
  computed: {
    ...mapGetters(['medias'])
  },
  methods: {
    downPhoto(id) {
      dom2image(window.document.getElementById(id), id)
    },
    top10mediaStyle() {
      return 'background: ' + globalMediaColor(this.chartData.top10mediaName) + ';'
    },
    top10mediaClassName(index) {
      let map = [
        'item-10 cur-p',
        'item-9 cur-p',
        'item-8 cur-p',
        'item-7 cur-p',
        'item-6 cur-p',
        'item-5 cur-p',
        'item-4 cur-p',
        'item-3 cur-p',
        'item-2 cur-p',
        'item-1 cur-p'
      ]
      return map[index]
    },
    top10mediaLeft() {
      // 两个以上才可以切换
      if (_.size(this.chartData.mediadistrict) <= 1) {
        return false
      }
      let _this = this
      let currentIndex = _.findIndex(this.chartData.mediadistrict, media => {
        return media.name === _this.chartData.top10mediaName
      })
      let nextIndex = currentIndex - 1
      if (nextIndex < 0) {
        this.top10media(_.last(this.chartData.mediadistrict).name)
      } else {
        this.top10media(_.nth(this.chartData.mediadistrict, nextIndex).name)
      }
    },
    top10mediaRight() {
      // 两个以上才可以切换
      if (_.size(this.chartData.mediadistrict) <= 1) {
        return false
      }
      let _this = this
      let currentIndex = _.findIndex(this.chartData.mediadistrict, media => {
        return media.name === _this.chartData.top10mediaName
      })
      let nextIndex = currentIndex + 1
      if (_.nth(this.chartData.mediadistrict, nextIndex) === undefined) {
        this.top10media(_.nth(this.chartData.mediadistrict, 0).name)
      } else {
        this.top10media(_.nth(this.chartData.mediadistrict, nextIndex).name)
      }
    },
    loadData(params) {
      this.params = params
      let modules = [
        'voicetrend',
        'emotiontrend',
        'mediadistrict',
        'sensitive',
        'hotkeys',
        'ascendkey',
        'viocedistrict',
        'frequency'
      ]
      let promiseQueue = []
      let _this = this
      _(modules).forEach(module => {
        promiseQueue.push(() => {
          return new Promise((resolve, reject) => {
            _this[module](resolve)
          })
        })
      })
      async function queue(arr) {
        let res = null
        for (let promise of arr) {
          if (_this.isDestroyed) {
            return false
          }
          res = await promise(res)
        }
        return await res
      }
      // promise 队列
      _this.mustLoading = true
      queue(promiseQueue).then(data => {
        _this.mustLoading = false
        // 通知父组件加载完成
        this.$emit('done', { statistics: _this.statistics })
      })
    },
    voicetrendFilter(field, option) {
      this.voicetrend(null, option.value)
    },
    voicetrend(resolve = null, unit = null) {
      let _this = this
      this.loading.voicetrend = true
      let params = JSON.parse(JSON.stringify(this.params))
      if (unit) {
        params['interval_unit'] = unit
      }
      voicetrendReq(params)
        .then(res => {
          _this.loading.voicetrend = false
          if (resolve) resolve('voicetrend')
          if (res.data.state) {
            let data = res.data.data
            _this.emptyChart.voicetrend = _.size(data.data) < 1 ? true : false
            let element = window.document.getElementById('voicetrend')
            let unitMap = { hour: '小时', day: '日', month: '月' }
            this.filterData.voicetrend[0].value = {
              label: unitMap[data.interval_unit],
              value: data.interval_unit
            }
            if (!element || _this.emptyChart.voicetrend) return false // 切换时直接break
            // 计算全部
            let allMedia = {
              data: [],
              name: '全部',
              smooth: true,
              type: 'line',
              itemStyle: { color: globalMediaColor('全部') }
            }
            _(data.data).forEach((item, i) => {
              data.data[i].itemStyle = { color: globalMediaColor(item.name) }
              for (let j = 0; j < item.data.length; j++) {
                if (i == 0) {
                  // 第一次赋值
                  allMedia.data.push(item.data[j])
                } else {
                  // 依次累加
                  allMedia.data[j] += item.data[j]
                }
              }
            })
            data.data.unshift(allMedia)
            data.media.unshift('全部')
            let option = {
              tooltip: {
                trigger: 'axis'
              },
              legend: {
                data: data.media,
                type: 'scroll',
                itemWidth: 10,
                itemHeight: 10,
                itemGap: 10,
                orient: 'horizontal',
                // right:"0",
                top: '10px',
                // left:"0",
                icon: 'circle',
                pageIconColor: '#555DFE'
              },
              dataZoom: [
                {
                  type: 'inside',
                  start: 0,
                  end: 100
                }
              ],
              grid: {
                left: '3%',
                right: '4%',
                top: '60px',
                width: '90%',
                bottom: '20',
                containLabel: true
              },
              toolbox: {
                show: false,
                feature: {
                  magicType: { type: ['line', 'bar'] }
                },
                right: '24px',
                top: '10px'
              },
              xAxis: {
                type: 'category',
                boundaryGap: false,
                data: data.keys
              },
              yAxis: {
                type: 'value'
              },
              series: data.data
            }
            _this.$nextTick(() => {
              var myChart = globalCreateChart(element, option, true)
              myChart.resize()
              myChart.off('click')
              myChart.on('click', function(e) {
                let media = e.seriesName
                let name = e.name
                let month = name.split('-')[0]
                let dateSpan = _this.params.span.split('~')
                let baseMinDate = new Date(window.$moment(dateSpan[0]).valueOf())
                let baseMaxDate = new Date(window.$moment(dateSpan[1]).valueOf())
                let year =
                  month < baseMinDate.getMonth() + 1
                    ? baseMinDate.getFullYear() + 1
                    : baseMinDate.getFullYear()
                let date = new Date(year + '-' + name)
                let begin = globalFormatDate(date)
                switch (data.interval_unit) {
                  case 'hour':
                    date.setHours(date.getHours() + 1)
                    break
                  case 'day':
                    date.setHours(23)
                    date.setMinutes(59)
                    date.setSeconds(59)
                    // 最大区间
                    date = date.getTime() > baseMaxDate ? baseMaxDate : date
                    break
                  case 'month':
                    let days = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
                    // 最小区间
                    begin =
                      baseMinDate.getTime() > date.getTime() ? globalFormatDate(baseMinDate) : begin
                    date.setDate(days)
                    date.setHours(23)
                    date.setMinutes(59)
                    date.setSeconds(59)
                    // 最大区间
                    date = date.getTime() > baseMaxDate ? baseMaxDate : date
                    break
                }
                let end = globalFormatDate(date)
                let map = { key: 'voicetrend', value: { date: begin + '~' + end, media: media } }
                _this.list(map)
              })
              window.addEventListener('resize', () => {
                myChart.resize()
              })
            })
          }
        })
        .catch(res => {
          if (resolve) resolve('voicetrend')
          _this.$message.error('获取信息声量走势图错误')
        })
    },
    emotiontrendFilter(field, option) {
      this.emotiontrend(null, option.value)
    },
    emotiontrend(resolve = null, unit = null) {
      let _this = this
      this.loading.emotiontrend = true
      let params = JSON.parse(JSON.stringify(this.params))
      if (unit) {
        params['interval_unit'] = unit
      }
      emotiontrendReq(params)
        .then(res => {
          _this.loading.emotiontrend = false
          if (resolve) resolve('emotiontrend')
          if (res.data.state) {
            let echarts = globalGetEcharts()
            let result = res.data.data
            let option = {
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                  label: {
                    backgroundColor: '#6a7985'
                  }
                }
              },
              legend: {
                data: ['敏感', '非敏感'],
                top: '10px',
                itemWidth: 10,
                itemHeight: 10,
                itemGap: 20,
                icon: 'circle'
              },
              grid: {
                left: '3%',
                right: '4%',
                top: '60px',
                width: '90%',
                bottom: '20',
                containLabel: true
              },
              xAxis: [
                {
                  type: 'category',
                  boundaryGap: false,
                  data: result.keys
                }
              ],
              yAxis: [
                {
                  type: 'value'
                }
              ],
              dataZoom: [
                {
                  type: 'inside',
                  start: 0,
                  end: 100
                }
              ],
              color: ['#555DFE', '#FC5D73'],
              series: [
                {
                  name: '非敏感',
                  type: 'line',
                  // stack: '总量',
                  data: result.positive,
                  smooth: true,
                  areaStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: '#238fff'
                        },
                        {
                          offset: 1,
                          color: '#fff'
                        }
                      ])
                    }
                  }
                },
                {
                  name: '敏感',
                  type: 'line',
                  // stack: '总量',
                  data: result.negative,
                  smooth: true,
                  areaStyle: {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: '#ff4f65'
                        },
                        {
                          offset: 1,
                          color: '#fff'
                        }
                      ])
                    }
                  }
                }
              ]
            }
            _this.emptyChart.emotiontrend = _.size(result.positive) < 1 ? true : false
            let element = window.document.getElementById('emotiontrend')
            let unitMap = { hour: '小时', day: '日', month: '月' }
            this.filterData.emotiontrend[0].value = {
              label: unitMap[result.interval_unit],
              value: result.interval_unit
            }
            if (!element || _this.emptyChart.emotiontrend) return false // 切换时直接break
            _this.$nextTick(() => {
              var myChart = globalCreateChart(element, option)
              myChart.resize()
              myChart.off('click')
              window.addEventListener('resize', () => {
                myChart.resize()
              })
              myChart.on('click', function(e) {
                let sensitive = e.seriesName == '非敏感' ? false : true
                let name = e.name
                let month = name.split('-')[0]
                let dateSpan = _this.params.span.split('~')
                let baseMinDate = new Date(window.$moment(dateSpan[0]).valueOf())
                let baseMaxDate = new Date(window.$moment(dateSpan[1]).valueOf())
                let year =
                  month < baseMinDate.getMonth() + 1
                    ? baseMinDate.getFullYear() + 1
                    : baseMinDate.getFullYear()
                let date = new Date(window.$moment(`${year}-${name}`).valueOf())

                let begin = globalFormatDate(date)
                switch (result.interval_unit) {
                  case 'hour':
                    date.setHours(date.getHours() + 1)
                    break
                  case 'day':
                    date.setHours(23)
                    date.setMinutes(59)
                    date.setSeconds(59)
                    // 最大区间
                    date = date.getTime() > baseMaxDate ? baseMaxDate : date
                    break
                  case 'month':
                    let days = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
                    // 最小区间
                    begin =
                      baseMinDate.getTime() > date.getTime() ? globalFormatDate(baseMinDate) : begin
                    date.setDate(days)
                    date.setHours(23)
                    date.setMinutes(59)
                    date.setSeconds(59)
                    // 最大区间
                    date = date.getTime() > baseMaxDate ? baseMaxDate : date
                    break
                }
                let end = globalFormatDate(date)
                let map = {
                  key: 'emotiontrend',
                  value: { date: `${begin}~${end}`, sensitive: sensitive }
                }
                _this.list(map)
              })
            })
          }
        })
        .catch(res => {
          if (resolve) resolve('emotiontrend')
          _this.$message.error('获取情感声量走势图错误')
        })
    },
    mediadistrict(resolve) {
      var _this = this
      this.loading.mediadistrict = true
      let params = JSON.parse(JSON.stringify(this.params))
      mediadistrictReq(params)
        .then(res => {
          this.loading.mediadistrict = false
          if (resolve) resolve('mediadistrict')
          if (res.data.state) {
            var data = res.data.data
            var kMaps = {}
            var total = 0
            let keys = []
            // 初始化
            this.statistics = JSON.parse(JSON.stringify(data)).map(obj => {
              total += parseInt(obj.value)
              return { media: obj.name, num: obj.value }
            }) // 统计数据
            this.chartData.mediaCount = []
            _(this.medias).forEach(media => {
              _this.chartData.mediaCount.push({ name: media, value: 0 })
            })
            this.chartData.mediadistrict = []
            this.emptyChart.mediadistrict = _.size(data) < 1 ? true : false
            this.emptyChart.top10media = this.emptyChart.mediadistrict
            let element = window.document.getElementById('mediadistrict')
            if (!element || this.emptyChart.mediadistrict) return false // 切换时直接break
            data = _.orderBy(data, ['value'], ['desc'])
            this.chartData.mediadistrict = data
            this.top10media(data[0].name)
            let mediaCount = []
            // 信息总量统计
            _(this.medias).forEach(media => {
              let index = _.findIndex(data, item => {
                return item.name == media
              })
              let row =
                index === -1
                  ? { name: media, value: 0 }
                  : { name: data[index].name, value: data[index].value }
              //total += parseInt(row.value)
              mediaCount.push(row)
            })
            this.chartData.mediaCount = mediaCount
            data = data.map(function(item) {
              var rate = (item.value / total) * 100
              rate = rate.toFixed(2).toString()
              kMaps[item.name] = rate + '% '
              keys.push(item.name)
              item.itemStyle = { color: globalMediaColor(item.name) }
              return item
            })

            let option = {
              tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b} : {c} ({d}%)'
              },
              legend: {
                orient: 'vertical',
                left: '10px',
                bottom: '10px',
                data: keys,
                icon: 'circle',
                itemWidth: 10,
                itemHeight: 10,
                itemGap: 5,
                formatter: function(name) {
                  return name + ' ' + kMaps[name]
                },
                show: false
              },
              series: [
                {
                  name: '来源分布',
                  type: 'pie',
                  radius: ['40%', '50%'],
                  center: ['40%', '50%'],
                  data: data,
                  avoidLabelOverlap: true,
                  label: {
                    // show: true,
                    // position: 'inside',
                    formatter: function(params) {
                      return params.name + ' ' + kMaps[params.name]
                    }
                  },
                  emphasis: {
                    label: {
                      show: true
                    }
                  },
                  itemStyle: {
                    emphasis: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  }
                }
              ]
            }
            _this.$nextTick(() => {
              var myChart = globalCreateChart(element, option)
              myChart.resize()
              myChart.off('click')
              window.addEventListener('resize', () => {
                myChart.resize()
              })
              myChart.on('click', function(e) {
                let media = e.name
                let map = { key: 'mediadistrict', value: { media: media } }
                _this.list(map)
              })
            })
          }
        })
        .catch(() => {
          if (resolve) resolve('mediadistrict')
          _this.$message.error('获取地区声量分布图错误')
        })
    },
    top10media(media) {
      var _this = this
      let params = JSON.parse(JSON.stringify(this.params))
      params.media = media
      this.loading.top10media = true
      top10mediaReq(params)
        .then(res => {
          this.loading.top10media = false
          if (res.data.state) {
            this.chartData.top10mediaName = media
            let result = res.data.data
            this.emptyChart.top10media = _.size(result) < 1 ? true : false
            this.chartData.top10media = _.size(result) > 10 ? _.chunk(result, 10)[0] : result
          }
        })
        .catch(res => {
          _this.$message.error('获取top10媒体失败')
        })
    },
    sensitive(resolve) {
      var _this = this
      this.loading.sensitive = true
      let params = JSON.parse(JSON.stringify(this.params))
      sensitiveReq(params)
        .then(res => {
          _this.loading.sensitive = false
          if (resolve) resolve('sensitive')
          if (res.data.state) {
            var result = res.data.data
            let option = {
              tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c}({d}%)'
              },
              toolbox: {
                show: true,
                feature: {},
                right: '3%',
                top: '10px'
              },
              legend: {
                data: ['敏感', '非敏感'],
                top: '10px',
                icon: 'circle',
                itemWidth: 10,
                itemHeight: 10,
                itemGap: 20
              },
              color: ['#FC5D73', '#555DFE'],
              series: [
                {
                  name: '情感分布',
                  type: 'pie',
                  center: ['50%', '45%'],
                  radius: ['40%', '50%'],
                  padding: [5, 0],
                  avoidLabelOverlap: true,
                  label: {
                    formatter: function(params) {
                      return `${params.name} ${params.percent}%`
                    }
                  },
                  emphasis: {
                    label: {
                      show: true
                    }
                  },
                  data: [
                    { name: '敏感', value: result.negative_count },
                    { name: '非敏感', value: result.positive_count }
                  ]
                }
              ]
            }
            _this.emptyChart.sensitive =
              Array.prototype.isPrototypeOf(result) && _.size(result) < 1 ? true : false
            let element = window.document.getElementById('sensitive')
            if (!element || _this.emptyChart.sensitive) return false // 切换时直接break
            _this.$nextTick(() => {
              var myChart = globalCreateChart(element, option)
              myChart.resize()
              myChart.off('click')
              myChart.on('click', function(e) {
                let sensitive = e.name == '非敏感' ? false : true
                let map = { key: 'sensitive', value: { sensitive: sensitive } }
                _this.list(map)
              })
              window.addEventListener('resize', () => {
                myChart.resize()
              })
            })
          }
        })
        .catch(res => {
          if (resolve) resolve('sensitive')
          _this.$message.error('获取敏感信息占比图错误')
        })
    },
    hotkeys(resolve) {
      const _this = this
      const colors = ['#555DFE', '#4E89FE', '#8CB6FF', '#A5A5A5']
      this.loading.hotkeys = true
      let params = JSON.parse(JSON.stringify(this.params))
      hotkeysReq(params)
        .then(res => {
          this.loading.hotkeys = false
          if (resolve) resolve('hotkeys')
          if (res.data.state) {
            var data = []
            var hotkey = []
            _(res.data.data).forEach((item, i) => {
              let index = Math.floor(Math.random() * colors.length)
              data.push({ name: item['keyword'], value: item['num'], textStyle: {
                  color: colors[index]
                } })
              if (i < 10) {
                hotkey.push({ sort: item['sort'], num: item['num'], keyword: item['keyword'] })
              }
            })
            _this.chartData.hotkeys = hotkey
            let option = {
              tooltip: {
                trigger: 'item'
              },
              toolbox: {
                feature: {},
                right: '3%',
                top: '10px'
              },
              series: [
                {
                  name: '信息数',
                  type: 'wordCloud',
                  shape: 'circle', // circle cardioid diamond triangle-forward triangle
                  left: 'center',
                  top: '0',
                  width: '80%',
                  height: '90%',
                  right: null,
                  bottom: null,
                  sizeRange: [12, 48],
                  rotationRange: [0, 0],
                  rotationStep: 90,
                  hape: 'pentagon',
                  gridSize: 15,
                  drawOutOfBound: false,
                  textStyle: {
                    normal: {
                      fontFamily: 'sans-serif'
                    },
                    emphasis: {
                      shadowBlur: 10,
                      shadowColor: '#d2d2d2'
                    }
                  },
                  data: data
                }
              ]
            }
            var maskImage = new Image()
            //重点：云彩图片的base64码
            maskImage.src = require('@/assets/images/common/ciyun.jpg')
            this.emptyChart.hotkeys = _.size(hotkey) < 1 ? true : false
            let element = window.document.getElementById('hotkeys')
            if (!element || this.emptyChart.hotkeys) return false // 切换时直接break

            // const wd = _.size(data) / 100 * 70
            // const wwd = wd < 50 ? 50 : wd
            element.style.margin = '0 auto'
            if (window.innerWidth >= 2000) {
              element.style.width = `70%`
            } else {
              element.style.width = `100%`
            }

            this.hotKeyData = data
            maskImage.onload = function() {
              _this.$nextTick(() => {
                option.series[0].maskImage = maskImage
                var myChart = globalCreateChart(element, option)
                myChart.resize()
                myChart.off('click')
                window.addEventListener('resize', () => {
                  if (window.innerWidth >= 2000) {
                    element.style.width = `70%`
                  } else {
                    element.style.width = `100%`
                  }
                  myChart.resize()
                })
                myChart.on('click', function(e) {
                  let name = e.name
                  let map = { key: 'hotkeys', value: { keyword: name } }
                  _this.list(map)
                })
              })
            }
          } else {
            _this.$message.error('加载关键词云错误')
          }
        })
        .catch(res => {
          if (resolve) resolve('hotkeys')
          _this.$message.error('加载关键词云错误')
        })
    },
    ascendkey(resolve) {
      let _this = this
      this.loading.ascendkey = true
      let params = JSON.parse(JSON.stringify(this.params))
      ascendkeyReq(params)
        .then(res => {
          this.loading.ascendkey = false
          if (resolve) resolve('ascendkey')
          if (res.data.state) {
            this.chartData.ascendkey = res.data.data.map(item => {
              if (parseInt(res.data.data[0].rise) === 0) {
                item.percent = 0
              } else {
                item.percent = parseInt(((item.rise / res.data.data[0].rise) * 100).toFixed(0))
              }

              return item
            })

            this.emptyChart.ascendkey = _.size(this.chartData.ascendkey) < 1 ? true : false
          }
        })
        .catch(res => {
          if (resolve) resolve('ascendkey')
          _this.$message.error('加载飙升关键词失败')
        })
    },
    viocedistrict(resolve) {
      var _this = this
      let params = JSON.parse(JSON.stringify(this.params))
      let geoColor = params.emotion == 'negative' ? '#f75749' : '#0081e7'
      this.loading.viocedistrict = true
      viocedistrictReq(params)
        .then(res => {
          _this.loading.viocedistrict = false
          if (resolve) resolve('viocedistrict')
          if (res.data.state) {
            var result = res.data.data
            var data = []
            _.remove(result, row => {
              return row.name == '其他' || row.name == ''
            })
            _this.emptyChart.viocedistrict = _.size(result) < 1 ? true : false
            let element = window.document.getElementById('viocedistrict')
            if (!element || _this.emptyChart.viocedistrict) return false // 切换时直接break
            result = _.orderBy(result, ['value'], ['desc'])
            let max = result[0].value
            this.chartData.viocedistrict = result
            _(result).forEach((item, i) => {
              if (window.provinceGeoCoord.hasOwnProperty(item.name)) {
                data.push({
                  name: item.name,
                  value: window.provinceGeoCoord[item.name].concat(item.value)
                })
              }
            })
            let option = {
              tooltip: {
                trigger: 'item',
                formatter: function(params) {
                  return params.data.name + '： ' + params.data.value[2] + '条'
                }
              },
              geo: {
                map: 'china',
                label: {
                  emphasis: {
                    show: false
                  }
                },
                roam: false,
                itemStyle: {
                  normal: {
                    areaColor: '#c1ceff',
                    borderColor: '#fff'
                  },
                  emphasis: {
                    areaColor: '#c1ceff'
                  }
                }
              },
              series: [
                {
                  name: '地域信息数',
                  data: data,
                  type: 'effectScatter',
                  coordinateSystem: 'geo',
                  symbolSize: function(val) {
                    return (val[2] / max) * 10 < 3 ? 3 : (val[2] / max) * 10
                  },
                  showEffectOn: 'render',
                  rippleEffect: {
                    brushType: 'stroke'
                  },
                  hoverAnimation: true,
                  itemStyle: {
                    normal: {
                      color: geoColor,
                      shadowBlur: 10,
                      shadowColor: '#333'
                    }
                  },
                  zlevel: 1
                }
              ]
            }
            _this.$nextTick(() => {
              var myChart = globalCreateChart(element, option)
              myChart.resize()
              myChart.off('click')
              myChart.on('click', function(o) {
                _(result).forEach(item => {
                  if (item.name == o.name && item.value >= 1) {
                    _this.list({ key: 'viocedistrict', value: { province: item.name } })
                    return false
                  }
                })
              })
              window.addEventListener('resize', () => {
                myChart.resize()
              })
            })
          } else {
            _this.$message.error(res.data.error)
          }
        })
        .catch(res => {
          if (resolve) resolve('viocedistrict')
          _this.$message.error('加载地域分布错误')
        })
    },
    frequency(resolve) {
      this.loading.frequency = true
      let params = JSON.parse(JSON.stringify(this.params))

      frequencyReq(params)
        .then(res => {
          this.loading.frequency = false
          if (resolve) resolve('frequency')
          if (res.data.state) {
            let result = res.data.data
            let posData = []
            let negData = []
            let posNum = 0
            let negNum = 0
            _(result).forEach(item => {
              if (item.emotion === 'positive') {
                posNum += item.num
                posData.push({
                  name: item.keyword.substr(0, 7),
                  value: item.num,
                  emotion: item.emotion
                })
              } else if (item.emotion === 'negative') {
                negNum += item.num
                negData.push({
                  name: item.keyword.substr(0, 7),
                  value: item.num,
                  emotion: item.emotion
                })
              }
            })
            this.emptyChart.frequency = _.size(result) < 1 ? true : false

            if (posNum >= negNum) {
              this.chartData.frequency = _.concat(posData, negData)
            } else {
              this.chartData.frequency = _.concat(negData, posData)
            }
          } else {
            this.$message.error(res.data.error)
          }
        })
        .catch(e => {
          window.console.error(e)
          if (resolve) resolve('frequency')
        })
    },
    list(map) {
      const params = JSON.parse(JSON.stringify(this.params))
      switch (map.key) {
        case 'voicetrend':
          params['span'] = map.value.date
          if (map.value.media != '全部') params['media'] = map.value.media
          break
        case 'emotiontrend':
          params['span'] = map.value.date
          params['emotion'] = map.value.sensitive ? 'negative' : 'positive'
          break
        case 'frequency':
          if (map.value.emotion == 'positive') {
            params['pos_word'] = map.value.keyword
          } else {
            params['neg_word'] = map.value.keyword
          }
          params['highlight'] = map.value.keyword
          break
        case 'sensitive':
          params['emotion'] = map.value.sensitive ? 'negative' : 'positive'
          break
        case 'hotkeys':
          params['keyword'] = map.value.keyword
          params['highlight'] = map.value.keyword
          break
        case 'ascendkey':
          params['keyword'] = map.value.keyword
          params['highlight'] = map.value.keyword
          break
        case 'top10media':
          if (
            this.chartData.top10mediaName === '微信' ||
            this.chartData.top10mediaName === '微博'
          ) {
            params['author'] = map.value.source_name
          } else {
            params['source_name'] = map.value.source_name
          }
          params['media'] = this.chartData.top10mediaName
          break
        case 'mediadistrict':
          params['media'] = map.value.media
          break
        case 'viocedistrict':
          params['province'] = map.value.province
          break
      }

      this.$refs.list.requestListData(`${window.service.api}/message/search`, params)
    }
  }
}
</script>
<style scoped>
.yq-statistics .echarts_empty,
.yq-statistics .mod-echarts-cont {
  height: 340px;
}
/*选择方案*/
.bd-t-l-r {
  border-top-left-radius: 0;
}
.sjfw {
  margin: 0px 6px;
  font-size: 12px;
}
.gjsx {
  width: 80px;
  height: 30px;
}
.xxfa_form {
  padding: 20px 30px;
  margin: 20px 30px 0;
  background: #f2f2f2;
}
.el-form-item--small.el-form-item {
  margin: 0;
}
.el-range-editor--small.el-input__inner {
  width: 300px;
  padding: 3px 5px;
}
.el-date-editor >>> .el-range-input {
  width: 132px;
}
.el-date-editor >>> .el-range-separator {
  padding: 0;
  width: 12px;
}
.el-range-editor--small >>> .el-range__close-icon {
  display: none;
}
.xxfa_form .w-320 {
  width: 320px;
}
.xxfa_form .w-365 {
  width: 365px;
}
.xxfa_form .input_group span,
.xxfa_form .yhh .el-radio__label {
  padding: 0 10px;
  font-size: 12px;
}
/*信息总量*/
.xxzl_num {
  padding: 30px 30px 0;
}
.xxzl_num >>> .el-button {
  margin-bottom: 10px;
  margin-right: 10px;
  height: 28px;
  padding: 0 10px;
  line-height: 26px;
}
/*信息声量走势*/
.volume_trend .jzxx {
  position: absolute;
  top: 40px;
  left: 20%;
  z-index: 20;
  width: 60%;
}
/**/
.hotkeys .common_list {
  margin-top: 20px;
  font-size: 12px;
  width: 110px;
  float: left;
  position: absolute;
}
.common_list .num {
  margin-top: 8px;
}
.lh-30 li {
  line-height: 30px;
  height: 30px;
}
.hotkeys li a {
  width: 75px;
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.hotkeys_right {
  float: left;
  width: 60%;
}
/* #hotkeys {overflow: hidden;float:left;margin-bottom: 30px;height: 340px;width: 100%;} */
/*飙升关键词*/
.surge-keywords >>> .el-card__body {
  padding: 0 20px;
}
/* .surge-keywords .num{background: none;margin-right: 8px;color: #EA3343;margin-top: 4px;font-size: 14px;}
.surge-keywords .echarts{width:calc(100% - 130px);display: flex;justify-content: center;align-items: center;}
.surge-keywords .bs-cont{height:300px;background-image:url(../../../assets/images/yq/biaosheng.png);background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;position: relative;width: 300px;}
.surge-keywords .bs-cont span{overflow: hidden;white-space: nowrap;text-overflow:ellipsis;width:80px;position: absolute;}
.surge-keywords .bs-cont span:first-child,.surge-keywords .bs-cont span:nth-child(2),.surge-keywords .bs-cont span:nth-child(3){font-weight:600;color: #EA3343;}
.surge-keywords .bs-cont span:first-child,.surge-keywords .bs-cont span:nth-child(2),.surge-keywords .bs-cont span:nth-child(3),.surge-keywords .bs-cont span:nth-child(4){text-align: right;}
.surge-keywords .bs-cont span:nth-child(5),.surge-keywords .bs-cont span:nth-child(6){text-align: center;}
.surge-keywords .bs-cont span:nth-child(4),.surge-keywords .bs-cont span:nth-child(5),.surge-keywords .bs-cont span:nth-child(6){color:#FFB146;}
.surge-keywords .bs-cont span:nth-child(7),.surge-keywords .bs-cont span:nth-child(8),.surge-keywords .bs-cont span:nth-child(9),.surge-keywords .bs-cont span:nth-child(10){color:#4E89FE;text-align: left;}
.surge-keywords .bs-cont span:first-child{left:53px;top:20px;}
.surge-keywords .bs-cont span:nth-child(2){left:-32px;top:80px;}
.surge-keywords .bs-cont span:nth-child(3){left: -52px;top: 142px;}
.surge-keywords .bs-cont span:nth-child(4){left:-36px;top:214px;}
.surge-keywords .bs-cont span:nth-child(5){left:78px;top:260px;}
.surge-keywords .bs-cont span:nth-child(6){left:148px;top:255px;}
.surge-keywords .bs-cont span:nth-child(7){left:240px;top:203px;}
.surge-keywords .bs-cont span:nth-child(8){left:272px;top:143px;}
.surge-keywords .bs-cont span:nth-child(9){left:242px;top:81px;}
.surge-keywords .bs-cont span:nth-child(10){left:195px;top:33px;} */
.surge-keywords .mod-list li {
  height: 33px;
  line-height: 33px;
}
.surge-keywords .mod-list-title1 {
  width: 18%;
  text-align: right;
}
.surge-keywords >>> .el-progress {
  width: 62%;
  margin-top: 12px;
}
.surge-keywords .tag {
  width: 15%;
  margin-left: 10px;
  text-align: left;
}
.surge-keywords .icon-cipin {
  position: absolute;
  right: 30px;
  top: 5px;
}
.surge-keywords .icon-citiao {
  position: absolute;
  right: 60px;
  top: 5px;
}
/* 热门关键词 */
.hot-keywords .icon-cipin {
  position: absolute;
  right: 30px;
  top: 5px;
}
.hot-keywords .icon-ciyun {
  position: absolute;
  right: 60px;
  top: 5px;
}
.hot-keywords .table-list,
.surge-keywords .table-list {
  height: 311px;
}
/*地区声量分布*/
.viocedistrict .content {
  position: absolute;
  right: 50px;
  bottom: 10%;
  z-index: 2001;
  background: rgba(198, 207, 255, 0.2);
  border-radius: 2px;
  width: 124px;
  height: 162px;
}
.viocedistrict .content h3 {
  width: 96px;
  height: 26px;
  line-height: 26px;
  font-weight: 600;
  text-align: center;
  overflow: hidden;
  margin: 15px auto 10px;
  background-image: url(../../../assets/images/common/viocedistrict_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  font-size: 14px;
}
.viocedistrict li {
  padding: 0 10px;
  line-height: 30px;
  line-height: 30px;
}
.viocedistrict .num {
  margin-right: 10px;
  margin-top: 6px;
}
.viocedistrict li a {
  width: 78px;
  float: left;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*高频情感词汇*/
.frequency .zs {
  position: absolute;
  left: calc(50% - 60px);
  top: 45px;
  z-index: 30;
}
.frequency .zs li {
  line-height: 30px;
  font-size: 12px;
  display: inline-block;
  width: 60px;
}
.frequency .zs .ico {
  width: 10px;
  height: 10px;
  float: left;
  overflow: hidden;
  border-radius: 50%;
  background: #fc5d73;
  margin: 10px 5px;
}
.frequency .zs .bg_ea3 {
  background: #555dfe;
}
.top10media-container {
  font-size: 12px;
  width: 160px;
}
.top10media-echarts-box {
  width: 80px;
  overflow: hidden;
}
.item-1 {
  opacity: 0.3;
  filter: Alpha(opacity=30);
}
.item-2 {
  opacity: 0.4;
  filter: Alpha(opacity=40);
}
.item-3 {
  opacity: 0.5;
  filter: Alpha(opacity=50);
}
.item-4 {
  opacity: 0.6;
  filter: Alpha(opacity=60);
}
.item-5 {
  opacity: 0.7;
  filter: Alpha(opacity=70);
}
.item-6 {
  opacity: 0.8;
  filter: Alpha(opacity=80);
}
.item-7 {
  opacity: 0.9;
  filter: Alpha(opacity=90);
}
.item-8 {
  opacity: 0.9;
  filter: Alpha(opacity=90);
}
.item-9 {
  opacity: 1;
  filter: Alpha(opacity=100);
}
.item-10 {
  opacity: 1;
  filter: Alpha(opacity=100);
}
.top10media-echarts-box > li {
  color: rgba(255, 255, 255, 1);
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.top10media-box-right {
  width: 80px;
}
.top10media-box-right > li {
  background: none;
  padding: 0 10px;
  color: rgba(0, 0, 0, 1);
  opacity: 1;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.top10media-container {
  width: 160px;
  height: 320px;
  position: absolute;
  top: 45px;
  right: 5%;
}
.lh-28 li {
  line-height: 28px;
  height: 28px;
}
.btn_cont {
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.top10media-container .left,
.top10media-container .right {
  width: 16px;
  height: 16px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  float: left;
  margin-top: 8px;
  cursor: pointer;
}
.top10media-container .left {
  background-image: url(../../../assets/images/common/icon/left_btn.svg);
  margin-left: 10px;
}
.top10media-container .right {
  background-image: url(../../../assets/images/common/icon/right_btn.svg);
  margin-right: 10px;
}
.top10media-container .tag {
  float: left;
  width: calc(100% - 52px);
  text-align: center;
  font-size: 12px;
}
@media screen and (max-width: 1381px) {
  /* .top10media-container{display: none;} */
  .top10media-container {
    width: 140px;
    right: 0;
    font-size: 10px;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  .top10media-container .top10media-echarts-box {
    width: 60px;
  }
  .viocedistrict .content {
    display: none;
  }
  .hotkeys_right,
  #mediadistrict {
    width: 100%;
  }
  .yq-statistics .echarts_empty,
  .yq-statistics .mod-echarts-cont {
    height: 300px;
  }
}
.el-form-item.mar-t-5 {
  margin-top: 5px;
}
.keysword-list {
  width: 440px;
  margin: 30px auto;
}
.keysword-list li {
  width: 90px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  font-size: 12px;
  display: inline-block;
  border-radius: 8px;
  margin: 20px 10px 0; /*overflow: hidden;white-space: nowrap;text-overflow:ellipsis;*/
  position: relative;
  cursor: pointer;
}
.keysword-list .negative {
  background: rgba(252, 93, 115, 0.1);
  color: #fc5d73;
}
.keysword-list .positive {
  background: rgba(85, 93, 254, 0.1);
  color: #555dfe;
}
.keysword-list .node-info {
  position: absolute;
  text-align: center;
  width: 100px;
  height: 55px;
  overflow: hidden;
  border-radius: 5px;
  background: #fff;
  font-size: 12px;
  top: 40px;
  left: 28px;
  z-index: 10;
  color: #414141;
  box-shadow: 0px 2px 2px 0px rgba(188, 188, 193, 0.17);
  display: none;
}
.keysword-list li:hover .node-info {
  display: block;
}
.keysword-list .node-info .tit {
  height: 24px;
  line-height: 24px;
  background: #ececec;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  float: left;
}
.keysword-list .node-info > div {
  height: 31px;
  line-height: 31px;
}
.keysword-list .node-info .ico {
  width: 10px;
  height: 10px;
  float: left;
  border-radius: 50%;
  margin: 10px 5px;
}
.keysword-list .node-info .fm {
  background: #fc5d73;
}
.keysword-list .node-info .zm {
  background: #555dfe;
}
.keysword-list .node-info > div span:nth-child(2) {
  float: left;
}
</style>
