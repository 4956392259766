<template>
  <div class="list-table">
    <!-- 无数据 -->
    <div class="empty hide">
      <div>
        <img src="@/assets/images/common/empty/img_xssj.svg" alt="暂无数据" width="400" class="img" type="image/svg+xml" />
        <p>暂无数据</p>
      </div>
    </div>
    <!--@end 无数据 -->
    <!-- 有数据 -->
    <section>
      <div class="flex xxlb_tit">
        <div class="f-l">
          <el-button class="f-l mar-r-20" @click="checkbox = true" v-if="!checkbox">批量选取</el-button>
          <el-button class="f-l mar-r-20" @click="cancelSelected" v-else>取消选取</el-button>
          <ul class="mar-t-5" v-if="checkbox">
            <li>
              <el-checkbox v-model="checkedAll" @change="allChecked">全选当页</el-checkbox>
            </li>
            <li class="cur-p pos-r">
              <span @click="multiFavorite = !multiFavorite">
                <i :class="{'iconfont': true, 'icon-shoucang': true, 'selected': multiFavorite}"></i>
                <var>收藏信息</var>
              </span>
              <favorite-dialog :ids="checkedData" @done="multiFavorite = false" v-if="multiFavorite" />
            </li>
            <li class="cur-p hide">
              <i class="iconfont icon-pingbi"></i><var>屏蔽信息</var>
            </li>
          </ul>
          <span class="export_infor cur-p mar-r-20" @click="exportData" v-loading="exportLoading">
            <i class="iconfont icon-daochu"></i><var>导出信息</var>
          </span>
          <span class="generate_briefing cur-p" @click="generateBrief()" v-if="buildBrief && hasBriefMode && !checkbox">
            <i class="iconfont icon-shengchengjianbao" />生成简报
          </span>
        </div>
        <div class="flex-1 t-c">
          <el-pagination v-if="total > 0" class="flex-1" layout="prev, slot, next" :current-page="currentPage" :page-size="currentPageSize" :total="total" @current-change="handleCurrentChange">
            <template v-slot>
              <span style="margin-right: 0;" class="el-pagination__total">
                {{ currentPage }}/{{ Math.max(Math.ceil(total / currentPageSize), 1) }}
              </span>
            </template>
          </el-pagination>
        </div>
        <div class="f-r" v-if="['yqList', 'source', 'repository', 'military', 'search-info', 'search-source', 'search-policy'].indexOf(component) !== -1" @keyup="resultSearch">
          <el-input
              v-model="filter.value"
              :placeholder="filter.field === 'source_name' ? `来源名称` : `在结果中搜索，只支持单个词组`"
              class="search f-r w-280" />
          <el-select v-model="filter.field" placeholder="全文" class="w-100 mar-r-10" @change="fieldChange">
            <el-option v-for="(item, index) in listOptions" :key="index" :label="item.label" :value="item.value" />

            <el-option label="来源" value="source_name" v-if="['yqList', 'search-info', 'search-source', 'search-policy'].indexOf(component) !== -1" />
          </el-select>
        </div>
      </div>
      <el-table :data="data" border v-loading="loading" class="w-100 mar-t-20">
        <el-table-column label="标题">
          <template slot-scope="scope">
            <el-row class="pad-lr-10">
              <el-col :span="1">
                <el-checkbox v-model="scope.row.checked" v-if="checkbox" @change="rowChecked(scope.row)" />
              </el-col>
              <el-col :span="23">
                <div class="item">
                  <h3 class="w-100 ov-h">
                    <el-button type="primary" class="fmg" v-if="scope.row.emotion === 'positive'">非敏感</el-button>
                    <el-button type="primary" class="mg" v-else-if="scope.row.emotion === 'negative'">敏感</el-button>
                    <span class="icon-yuanfabiaoqian" v-if="scope.row.category == '微博' && scope.row.isforward == 0">原</span>
                    <span class="icon-zhuanfabiaoqian" v-if="scope.row.category == '微博' && scope.row.isforward == 1">转</span>
                    <a class="title cur-p" v-html="scope.row.title" @click="showDetail(scope.row)" :title="scope.row.title"></a>
                    <el-button plain class="yb" @click="showCorrect(scope.row)" v-if="component === 'yqList' && !showMarkFb(scope.row)">纠错
                    </el-button>
                    <el-button plain class="yb" v-if="showMarkFb(scope.row)">已纠错
                    </el-button>
                  </h3>
                  <p class="summary" v-html="scope.row.description"></p>
                  <el-row class="lh-30">
                    <el-col :span="18">
                      <p class="info">
                        <span>媒体类型：{{ scope.row.category }}</span>
                        <span>信源地区：{{ msgArea(scope.row) }}</span>
                        <span v-if="scope.row.hasOwnProperty('aboutWord')">
                          涉及词：{{ scope.row.aboutWord.length >= 1 ? scope.row.aboutWord.join('、') : '--' }}
                        </span>
                        <span v-if="scope.row.hasOwnProperty('like') && scope.row.like != 0">
                          点赞量：{{ scope.row.like }}
                        </span>
                        <span v-if="scope.row.hasOwnProperty('forward')  && scope.row.forward != 0">
                          转发量：{{ scope.row.forward }}
                        </span>
                        <span v-if="scope.row.hasOwnProperty('pv')  && scope.row.pv != 0">
                          浏览量：{{ scope.row.pv }}
                        </span>
                      </p>
                    </el-col>
                    <el-col :span="6">
                      <ul class="ico-list">
                        <li>
                          <el-tooltip class="item" effect="dark" content="复制信息" placement="bottom">
                            <i :class="{'iconfont': true, 'icon-fuzhi': true, 'pos-r': true, 'selected': scope.row.clipboard}" @click="scope.row.clipboard = !scope.row.clipboard" />
                          </el-tooltip>
                          <ul class="copy-dropdown" v-show="scope.row.clipboard">
                            <li class="selected clipboard" :data-clipboard-text="scope.row.source_url" @click="copyData(scope.row, true)">拷贝地址</li>
                            <li class="clipboard" :data-clipboard-text="scope.row.copyText" @click="copyData(scope.row)">一键复制</li>
                          </ul>
                        </li>
                        <li>
                          <el-tooltip class="item" effect="dark" content="屏蔽信源" placement="bottom"><i :class="{iconfont: true, 'icon-pingbixinyuan': true, 'selected': scope.row.shield}" @click="shieldSource(scope.row)"></i></el-tooltip>
                        </li>
                        <li v-show="params.hasOwnProperty('plan_id')" v-if="!scope.row.markLoading">
                          <el-tooltip class="item" effect="dark" content="屏蔽信息" placement="bottom">
                            <i :class="{'iconfont': true, 'icon-pingbi': true, 'selected': scope.row.mark}" @click="docMarkAction(scope.row)"></i>
                          </el-tooltip>
                        </li>
                        <li class="mar-t-5" v-if="scope.row.markLoading">
                          <i class="iconfont icon-Loading Rotation"></i>
                        </li>
                        <li>
                          <el-tooltip class="item" effect="dark" content="加入收藏夹" placement="bottom">
                            <span class="cur-p">
                              <i :class="{'iconfont': true, 'icon-shoucang': true, 'pos-r': true, 'selected': scope.row.rowJoinMaterial.visible}" @click="scope.row.rowJoinMaterial.visible = !scope.row.rowJoinMaterial.visible"></i>
                            </span></el-tooltip>
                          <favorite-dialog :ids="scope.row.rowkey" @done="scope.row.rowJoinMaterial.visible = false" v-if="scope.row.rowJoinMaterial.visible" />
                        </li>
                        <li>
                          <el-tooltip class="item" effect="dark" content="查看原文" placement="bottom">
                            <i class="iconfont icon-lianjie" @click="target(scope.row.source_url)"></i>
                          </el-tooltip>
                        </li>
                      </ul>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column prop="similar" label="相似文章数" width="80">
          <template slot-scope="scope">
            <p class="t-c cur-p" @click="simhashList(scope.row)">{{ scope.row.similar_num ? scope.row.similar_num : 1 }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="source" label="来源" width="80">
          <template slot-scope="scope">
            <p class="t-c line-four">{{ scope.row.category === '微信' ? scope.row.author : scope.row.source_name }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="author" label="作者" width="80">
          <template slot-scope="scope">
            <p class="t-c">{{ scope.row.author.length >= 1 ? scope.row.author : '--' }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="time" label="时间" width="90" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.published }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-bottom el-row mar-t-20">
        <el-pagination @size-change="handleSizeChange"
          :hide-on-single-page="singPage" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 50, 100]" :page-size="currentPageSize" layout="slot, sizes, prev, pager, next, jumper" :total="total">
          <template v-slot>
            <span class="el-pager-customer-slot">
              {{ realTotal > 5000 ? `共${realTotal}条，显示前 5000 条` : `共${realTotal}条` }}
            </span>
          </template>
        </el-pagination>
      </div>
      <!--@end 分页 -->
    </section>
    <!--@end 有数据 -->
    <correct ref="correct" @succ="getList(true)" />
    <article-detail ref="detail" />
    <article-list ref="list" />
    <confirm ref="confirmShield" confirm="屏蔽" title="屏蔽信源" @must="mustShield" class="confirmShield single-information" />
    <export-dialog ref="export" @must="mustExport" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { formatMsgArea, getAuthToken, handleDownload, isHotData } from '@/utils/helpers'
import favoriteDialog from '@components/common/favorite-dialog.vue'
import articleDetail from '@components/common/article-detail.vue'
import articleList from '@components/common/article-list.vue'
import ExportDialog from '@components/common/export-dialog.vue'
import correct from './correct'
import { docMark, cancelDocMark, docCount } from '@/api/message'
import { shieldSource, dropShieldSource } from '@/api/source'
import Clipboard from 'clipboard'
import confirm from '@components/common/confirm'
const { _ } = window
export default {
  name: 'common-list',
  props: {
    buildBrief: {
      type: Boolean,
      default: false
    },
    simhashApi: {
      default: null
    }
  },
  data() {
    return {
      api: '',
      params: {},
      sourceField: null, // 源信息匹配字段
      checkbox: false,
      data: [],
      currentPage: 1,
      currentPageSize: 20,
      total: 0,
      realTotal: 0,
      statistics: {},
      checkedData: {},
      checkedAll: false,
      multiFavorite: false,
      exportLoading: false,
      loading: false,
      loadCount: false,
      component: '',
      listOptions: [
        { value: 'content', label: '全文' },
        { value: 'title', label: '标题' },
        { value: 'author', label: '作者' }
      ],
      filter: {
        value: '',
        field: 'content'
      },singPage: true
    }
  },
  computed: {
    ...mapGetters(['medias', 'materials', 'user', 'hasBriefMode'])
  },
  components: {
    correct,
    favoriteDialog,
    articleDetail,
    articleList,
    confirm,
    ExportDialog
  },
  destroyed() {},
  mounted() {
  },
  methods: {
    shieldSource(row) {
      if (row.shield) {
        this.sourceRejected(row)
        return false
      }
      const textBox = [
        {
          type: 'error',
          text: `屏蔽后您将不会收到“${row.source_name}${
            row.author ? ' —— ' + row.author : ''
          }”发布的任何信息，确定屏蔽该信源？`
        }
      ]
      this.$refs.confirmShield.show(textBox, row)
    },
    sourceRejected(row) {
      const params = {
        category: row.category,
        username: row.username,
        site: '',
        author: row.author,
        host: row.host,
        source_name: row.source_name,
        userid: row.userid
      }
      dropShieldSource(params)
        .then(res => {
          if (res.data.state) {
            const author = row.author ? `—${row.author}` : ''
            this.$message.success(`已取消屏蔽${row.source_name}${author}`)
            row.shield = false
          } else {
            this.$message.error(res.data.error)
          }
        })
        .catch(e => {
          window.console.error(e)
        })
    },
    mustShield(data) {
      const params = {
        category: data.category,
        username: data.username,
        site: '',
        author: data.author,
        host: data.host,
        source_name: data.source_name,
        userid: data.userid
      }
      shieldSource(params)
        .then(res => {
          if (res.data.state) {
            data.shield = true
            this.$message({
              type: 'success',
              dangerouslyUseHTMLString: true,
              message: this.renderShieldMsg(data)
            })
          } else {
            this.$message.error(res.data.error)
          }
        })
        .catch(e => {
          window.console.error(e)
          this.$message.error('屏蔽信源失败，服务错误')
        })
    },
    renderShieldMsg(row) {
      const author = row.author ? `—${row.author}` : ''
      return (
        <p>
          <span class="cor-67c">
            成功屏蔽“{row.source_name + author}”，您将不会收到该信源发布的信息。
          </span>
          <span
            class="cur-p underline cor-575"
            on-click={() => {
              this.$router.push({ name: 'shield', params: { shield: true } })
            }}
          >
            点击查看
          </span>
        </p>
      )
    },
    fieldChange() {
      const { component } = this
      const sourceField = this.sourceField || 'content'
      if (['yqList', 'source', 'repository', 'military'].indexOf(component) !== -1) {
        Object.assign(this.params, {
          field: sourceField,
          focus: { op: 'and', must: '', not: '' }
        })
      } else if (['search-info', 'search-source', 'search-policy'].indexOf(component) !== -1) {
        Object.assign(this.params, {
          field: sourceField,
          deep_exps: { word: '', source: '' }
        })
      }
      Object.assign(this.params, {
        author: ''
      })
    },
    resultSearch(e) {
      if (e.key === 'Enter') {
        if (this.filter.value.length < 1) {
          this.$message.warning('请输入字符以检索。')
          return false
        }
        this.currentPage = 1
        const { component } = this
        if (['yqList', 'source', 'repository', 'military'].indexOf(component) !== -1) {
          if (['content', 'title'].indexOf(this.filter.field) !== -1) {
            Object.assign(this.params, {
              field: this.filter.field,
              focus: { op: 'and', must: this.filter.value, not: '' }
            })
          } else if (this.filter.field === 'source_name') {
            Object.assign(this.params, {
              source_name: this.filter.value
            })
          }
        } else if (['search-info', 'search-source', 'search-policy'].indexOf(component) !== -1) {
          if (['content', 'title'].indexOf(this.filter.field) !== -1) {
            Object.assign(this.params, {
              field: this.filter.field,
              deep_exps: { word: this.filter.value, source: '' }
            })
          } else if (this.filter.field === 'source_name') {
            Object.assign(this.params, {
              source_name: this.filter.value
            })
          }
        }
        if (this.filter.field === 'author') {
          Object.assign(this.params, {
            author: this.filter.value
          })
        }
        this.getList(this.loadCount)
      }
    },
    loadData(api, params, loadCount = false, component = '') {
      this.params = params
      if (params.hasOwnProperty('field')) {
        // 列表新增搜索条件会更改匹配字段 需要记录原字段
        this.sourceField = params.field
      }
      this.api = api
      this.loadCount = loadCount
      this.component = component
      this.getList(loadCount)
    },
    getList(loadCount = false) {
      const params = JSON.parse(JSON.stringify(this.params))
      params.page = this.currentPage
      params.size = this.currentPageSize
      const _this = this
      this.loading = true
      this.data = []
      if (this.setTimeFlag) {
        clearTimeout(this.setTimeFlag)
      }
      this.setTimeFlag = setTimeout(() => {
        if (this.loading) {
          if (params.hasOwnProperty('span')) {
            try {
              const span = params.span.split('~')
              if (
                isHotData(window.$moment(span[0], 'YYYY-MM-DD HH:mm:ss').valueOf()) &&
                isHotData(window.$moment(span[1], 'YYYY-MM-DD HH:mm:ss').valueOf())
              ) {
                this.msgBuff = this.$message({
                  type: 'warning',
                  message: '加载需要较长时间，请您耐心等待。',
                  showClose: true,
                  duration: 0
                })
              } else {
                this.msgBuff = this.$message({
                  type: 'warning',
                  message: this.renderHtml(),
                  dangerouslyUseHTMLString: true,
                  duration: 0
                })
              }
            } catch (e) {
              this.msgBuff = this.$message({
                type: 'warning',
                message: '加载需要较长时间，请您耐心等待。',
                showClose: true,
                duration: 0
              })
            }
          }
        }
      }, 120000)
      window.$axios
        .get(this.api, { params })
        .then(res => {
          if (this.msgBuff) {
            this.msgBuff.close()
          }
          if (res.data.state) {
            const data = res.data.data
            if (typeof data.page === 'object') {
              this.total = data.page.total >= 5000 ? 5000 : data.page.total
              this.realTotal = data.page.total
            } else {
              this.total = data.total >= 5000 ? 5000 : data.total
              this.realTotal = data.total
            }
            // 判断全选状态
            const dataKey = data.hasOwnProperty('list') ? 'list' : 'data'
            const emotionMap = { negative: '敏感', positive: '非敏感' }
            let checkedAllState = true
            _(data[dataKey]).forEach((item, i) => {
              data[dataKey][i].rowJoinMaterial = { visible: false }
              data[dataKey][i].checked = _this.checkedData[data[dataKey][i].rowkey] ? true : false
              data[dataKey][i].mark =
                data[dataKey][i].hasOwnProperty('uid') &&
                data[dataKey][i].uid.indexOf(_this.user.id) !== -1 // 文档是否被标记
              data[dataKey][i].markLoading = false
              data[dataKey][i].clipboard = false
              data[dataKey][i].shield = false
              const aboutWord = item.hasOwnProperty('aboutWord')
                ? item.aboutWord.length >= 1
                  ? `涉及词：${item.aboutWord.join(',')}`
                  : '涉及词：--'
                : ''
              const title = item.title.replace(/(<em>)|(<\/em>)/gi, "")
              const description = item.description.replace(/(<em>)|(<\/em>)/gi, "")

              data[dataKey][i].copyText = `标题：${title}\r\n\r\n摘要：${description}\r\n\r\n原文链接：${item.source_url}\r\n\r\n发布时间：${
                item.published
              }\r\n\r\n来源：${item.source_name}\r\n\r\n作者：${
                item.author ? item.author : '--'
              }\r\n\r\n情感属性：${emotionMap[item.emotion]}\r\n\r\n${aboutWord}`
              _this.checkedData[data[dataKey][i].rowkey] = data[dataKey][i].checked
              if (!data[dataKey][i].checked) {
                checkedAllState = false
              }
            })
            this.checkedAll = checkedAllState
            this.data = data[dataKey]
            // 返回顶部
            window.scrollTo(0, 0)
            // 通知父组件 加载信息列表完成
            if (loadCount) {
              this.loadDocCount()
            } else {
              const countData = data.hasOwnProperty('statistics') ? data : undefined
              this.$emit('done', countData)
            }
          } else {
            this.$message.error(res.data.error)
            // 通知父组件 加载信息列表完成
            this.$emit('done', undefined)
          }
          this.loading = false
        })
        .catch(err => {
          if (this.msgBuff) {
            this.msgBuff.close()
          }
          if (this.setTimeFlag) {
            clearTimeout(this.setTimeFlag)
          }
          this.loading = false
          if (err.message.indexOf('timeout') !== -1) {
            this.$message({
              message: '信息量过多，建议你减少方案关键词或者缩短查询回溯时间',
              type: 'error',
              duration: 6000
            })
          } else {
            this.$message.error('加载数据失败，服务错误')
          }
          window.console.error(err)
          // 通知父组件 加载信息列表完成
          this.$emit('done', undefined)
        })
    },
    loadDocCount() {
      docCount(this.params)
        .then(res => {
          if (res.data.state) {
            const { data } = res.data
            this.$emit('done', { statistics: data.origin })
          } else {
            this.$message.error(res.data.error)
          }
        })
        .catch(e => {
          window.console.error(e)
        })
    },
    showMarkFb(row) {
      let show = false
      if (this.component === 'yqList' && row.hasOwnProperty('mark_fb')) {
        show = row.mark_fb.indexOf(this.user.id) !== -1
      }
      return show
    },
    showCorrect(row) {
      this.$nextTick(() => {
        this.$refs.correct.show(row.rowkey)
      })
    },
    msgArea(row) {
      return formatMsgArea(row)
    },
    target(url) {
      window.open(url, '_blank')
    },
    cancelSelected() {
      const _this = this
      this.checkbox = false
      this.checkedAll = false
      var selected = {}
      for (let key in this.checkedData) {
        selected[key] = false
      }
      this.checkedData = selected
      _(this.data).forEach((item, i) => {
        _this.data[i].checked = false
      })
    },
    copyData(item, isUrl = false) {
      // data-clipboard-text
      const _this = this
      const clipboard = new Clipboard('.clipboard')
      clipboard.on('success', function() {
        const text = isUrl ? `成功复制链接：${item.source_url}` : '复制成功'
        _this.$message.success(text)
        item.clipboard = false
        clipboard.destroy()
      })
      clipboard.on('error', function() {
        _this.$message.error('复制失败')
      })
    },
    docMarkAction(item) {
      item.markLoading = true
      if (item.mark) {
        // 取消文档标记
        cancelDocMark({ rowkey: item.rowkey })
          .then(res => {
            if (res.data.state) {
              this.$message('信息恢复为精准信息')
              item.mark = false
            } else {
              this.$message.error(res.data.error)
            }
            item.markLoading = false
          })
          .catch(e => {
            item.markLoading = false
            window.console.error(e)
          })
      } else {
        // 进行文档标记
        docMark({ plan_id: this.params.plan_id, rowkey: item.rowkey, msg: '' })
          .then(res => {
            if (res.data.state) {
              this.$message({
                type: 'success',
                dangerouslyUseHTMLString: true,
                message: this.renderMarkMsgHtml()
              })
              item.mark = true
            } else {
              this.$message.error(res.data.error)
            }
            item.markLoading = false
          })
          .catch(e => {
            item.markLoading = false
            window.console.error(e)
          })
      }
    },
    rowChecked(row) {
      this.checkedData[row.rowkey] = row.checked
    },
    allChecked(state) {
      const _this = this
      if (state) {
        this.checkedAll = true
        this.checkbox = true
        _(this.data).forEach((item, i) => {
          _this.checkedData[item.rowkey] = true
          _this.data[i].checked = true
        })
      } else {
        this.checkedAll = false
        _(this.data).forEach((item, i) => {
          _this.checkedData[item.rowkey] = false
          _this.data[i].checked = false
        })
      }
    },
    exportData() {
      this.$nextTick(() => {
        let ids = []
        _(this.checkedData).forEach((v, k) => {
          if (v) ids.push(k)
        })
        if (_.size(ids) >= 1) {
          this.$refs['export'].show(ids, 'custom', ids.length)
        } else {
          const total = this.realTotal >= 10000 ? 10000 : this.realTotal
          if (total <= 0) {
            this.$message.warning('暂无可供导出的数据。')
            return false
          }
          this.$refs['export'].show(0, 'default', total)
        }
      })
    },
    mustExport(args) {
      const { type, data } = args
      if (type === 'custom') {
        this.exportLoading = true
        const formData = new FormData()
        formData.append('rowkeys', data.join(','))
        formData.append('token', getAuthToken())
        if (this.params.hasOwnProperty('plan_id') && this.params.plan_id) {
          formData.append('plan_id', this.params.plan_id)
        }
        handleDownload(
                `${window.service.api}/message/sheet`,
                'POST',
                `${window.$moment().format('YYYY-MM-DD')}.xlsx`,
                formData,
                _.size(data),
                () => {
                  this.exportLoading = false
                }
        )
      } else {
        this.exportLoading = true
        this.$emit('exportData', data)
      }
    },
    showDetail(item) {
      const pid = this.params.hasOwnProperty('plan_id') ? this.params.plan_id : null
      let keywords = []
      if (this.params.hasOwnProperty('focus') && this.params.focus.hasOwnProperty('must')) {
        let focusWords = this.params.focus.must
        if (_.size(focusWords) >= 1) {
          let words = focusWords.split(',')
          _(words).forEach((word, index) => {
            if (keywords.indexOf(word) === -1) keywords.push(word)
          })
        }
      }
      this.$refs.detail.showDetail(item.rowkey, 'list', keywords, pid)
    },
    simhashList(item) {
      const params = JSON.parse(JSON.stringify(this.params))
      if (params.hasOwnProperty('similar') && params.similar !== 'yes') {
        return
      }
      if (params.hasOwnProperty('merge') && params.merge !== 'yes') {
        return
      }
      params.simhash = item.similar_sim ? item.similar_sim.join(',') : item.simhash
      params.merge = 'no'
      const api = this.simhashApi ? this.simhashApi : `${window.service.api}/message/search`
      this.$refs['list'].requestListData(api, params)
    },
    generateBrief() {
      this.$emit('generateBrief')
    },
    renderMarkMsgHtml() {
      return (
        <p>
          <span class="cor-67c">信息已被屏蔽成功。</span>
          <span
            class="cur-p underline cor-606"
            on-click={() => {
              this.$router.push({ name: 'shield', params: {} })
            }}
          >
            点击查看
          </span>
        </p>
      )
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    },
    handleSizeChange(val) {
      this.currentPageSize = val
      this.currentPage = 1
      this.getList()
    }
  }
}
</script>
<style scoped>
.list-table .xxlb_tit {
  font-size: 12px;
  color: #606266;
}
.list-table .xxlb_tit ul {
  float: left;
  margin-top: 8px;
}
.list-table .xxlb_tit li {
  margin-right: 20px;
  float: left;
}
.list-table .xxlb_tit .export_infor {
  margin-top: 8px;
  float: left;
}
.list-table .xxlb_tit .iconfont {
  margin-right: 5px;
  float: left;
}
.list-table .xxlb_tit li span,
.xxlb .xxlb_tit var {
  float: left;
}
.list-table .flex-1.el-pagination {
  flex: 1;
  text-align: center;
}
.list-table .generate_briefing {
  margin-top: 8px;
  float: right;
  margin-right: 10px;
}
.list-table .xxlb_tit >>> .el-checkbox__input {
  float: left;
  margin-top: 2px;
}
.list-table .xxlb_tit >>> .el-checkbox__inner,
.list-table .xxlb_tit >>> .el-checkbox__label {
  float: left;
}
.list-table .xxlb_tit >>> .search .el-input__inner {
  width: 280px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid rgba(210, 210, 210, 1);
}
.list-table .xxlb_tit >>> .w-100 {
  width: 100px;
}
.list-table >>> .el-table--small th {
  padding: 0;
  font-size: 14px;
  color: #414141;
  background: #f3f5fc;
}
.list-table >>> .el-table th > .cell {
  padding: 0;
  text-align: center;
  font-weight: 600;
  line-height: 45px;
}
.list-table >>> .title {
  float: left;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 235px);
  padding-right: 10px;
  font-size: 14px;
}
.list-table >>> .title em {
  font-weight: 600;
}
.list-table .summary {
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin: 10px 0 5px;
}
.list-table .info span {
  padding-right: 20px;
  color: #707070;
}
.list-table .ico-list > li {
  float: right;
  margin-left: 10px;
}
.list-table >>> .el-table td .cell,
.list-table >>> .el-table td .t-c {
  color: #707070;
  overflow: visible;
}
.list-table >>> .el-table,
.list-table >>> .el-table__body-wrapper {
  overflow: visible;
}
/* 拷贝下拉列表 */
.copy-dropdown {
  position: absolute;
  right: -10px;
  top: 30px;
  z-index: 10;
  text-align: center;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(228, 231, 237, 1);
  padding: 5px 0;
  width: 80px;
  border-radius: 4px;
}
.copy-dropdown li {
  line-height: 30px;
  height: 30px;
  font-size: 12px;
  color: #606266;
  cursor: pointer;
}
.list-table {
}
</style>
