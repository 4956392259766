<template>
  <!-- 系统通知 -->
  <el-dialog
    class="xttz"
    :visible.sync="visible"
    center
    @closed="close"
    :close-on-click-modal="false"
  >
    <div class="cont t-c">
      <vue-scroll>
        <ul>
          <li>1.“信息导出”功能优化，可自定义导出信息数量；</li>
          <li>2.“新增账号”支持自定义密码功能；</li>
          <li>3.“方案大屏”页面改版，各个模块可点击查看详情；</li>
          <li>4.“简报制作”功能改版，流程式制作方式，支持发送简报至联系人；</li>
          <li>5.“全网智搜”模块新增“全网热门信息”列表；</li>
          <li>6.<span style="padding-left:10px;">系统BUG修复。</span></li>
        </ul>
      </vue-scroll>
    </div>
    <div class="flex t-c mar-t-5">
      <span>系统版本号：V2.1.0</span>
      <span>发布时间：2021-06-08 19:00:00</span>
    </div>
  </el-dialog>
  <!--@end 系统通知 -->
</template>
<script>
export default {
  name: 'system-notify',
  data() {
    return {
      visible: false
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    close() {
      this.$emit('closed');
    }
  }
};
</script>
<style scoped>
/* 系统通知 */
.xttz >>> .el-dialog {
  width: 600px;
  height: 500px;
  background: none;
  background-image: url(../../assets/images/common/xttz-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  box-shadow: none;
}
.xttz >>> .el-dialog__headerbtn {
  top: 130px;
}
.xttz >>> .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}
.xttz .cont {
  margin: 204px 0 0;
  padding: 0 40px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 194px;
  color: #414141;
}
.xttz >>> .el-dialog__body {
  padding: 0;
}
.xttz li {
  line-height: 30px;
  font-size: 15px;
  text-align: left;
}
.xttz .flex {
  border-top: 1px solid #f2f2f2;
  line-height: 65px;
  font-size: 16px;
  color: #414141;
}
.xttz .flex span {
  display: inline-block;
  padding: 0 28px;
}
.xttz .flex span:first-child {
  border-right: 1px solid #f2f2f2;
}
</style>
