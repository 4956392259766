<template>
  <div>
    <el-dialog
      :visible.sync="listDialog.visible"
      class="common-big-dialog mod-list-dialog relat-info"
      :close-on-click-modal="false"
    >
      <div class="mod-title" slot="title">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>{{ eventName }}</el-breadcrumb-item>
          <el-breadcrumb-item>事件详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div v-loading="listDialog.loading" class="listDialogVisible h-100">
        <div class="scroll-area">
          <!-- <vue-scroll> -->
            <!-- 关联实体 -->
            <div v-if="entitys.length >= 1" class="related_entities pb-20">
              <div class="box-shadow mar-t-20"></div>
              <div class="mod-title mar-t-20">关联实体</div>
              <ul>
                <li
                  :class="randomColorClass()"
                  v-for="(entity, index) in entitys"
                  :key="index"
                >
                  {{ entity }}
                </li>
              </ul>
            </div>
            <!--@end 关联实体 -->
            <el-row class="relat_infor">
              <el-card class="box-card common-xxlb ov-v mar-t-10">
                <div class="mod-title ov-v">
                  <span class="mar-t-10"
                    >信息列表 /
                    {{
                      listData.phonyTotal
                        ? listData.phonyTotal
                        : listData.page.total
                    }}条</span
                  >
                  <div class="f-r mar-t-5">
                    <el-select
                      v-model="listData.order"
                      @change="orderChange"
                      class="w-140 f-r"
                    >
                      <el-option
                        value="importance"
                        label="重要度排序"
                      ></el-option>
                      <el-option value="default" label="相关度排序"></el-option>
                      <el-option value="desc" label="发布时间降序"></el-option>
                      <el-option value="asc" label="发布时间升序"></el-option>
                      <el-option
                        label="智能排序"
                        value="intelligence"
                      ></el-option>
                    </el-select>
                    <el-button
                      icon="iconfont icon-xiazai"
                      class="dc-excel bd-n"
                      v-loading="exportLoading"
                      @click="exportData()"
                      >导出excel</el-button
                    >
                  </div>
                </div>
              </el-card>
            </el-row>
            <ul>
              <li
                class="item"
                v-for="(data, index) in listData.list"
                :key="index"
              >
                <el-row>
                  <el-col :span="20">
                    <h3>
                      <el-button
                        type="primary"
                        class="mg"
                        v-if="data.emotion == 'negative'"
                        >敏感</el-button
                      >
                      <el-button type="primary" class="fmg" v-else
                        >非敏感</el-button
                      >
                      <span
                        class="iconfont icon-yuanfabiaoqian"
                        v-if="data.category == '微博' && data.isforward == 0"
                        >原</span
                      >
                      <span
                        class="iconfont icon-zhuanfabiaoqian"
                        v-if="data.category == '微博' && data.isforward == 1"
                        >转</span
                      >
                      <a
                        class="title"
                        @click="showDetail(data.rowkey)"
                        v-html="data.title"
                      ></a>
                    </h3>
                  </el-col>
                  <el-col :span="4">
                    <ul>
                      <li class="hide">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="屏蔽信息"
                          placement="bottom"
                        >
                          <i class="iconfont icon-pingbi selected"></i>
                        </el-tooltip>
                      </li>
                      <li>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="收藏信息"
                          placement="bottom"
                        >
                          <span
                            @click="
                              data.joinMaterial.visible = !data.joinMaterial
                                .visible
                            "
                          >
                            <i
                              class="iconfont icon-shoucang selected"
                              v-if="data.joinMaterial.visible"
                            ></i>
                            <i class="iconfont icon-shoucang" v-else></i>
                          </span>
                        </el-tooltip>
                        <favorite-dialog
                          :ids="data.rowkey"
                          v-if="data.joinMaterial.visible"
                          @done="data.joinMaterial.visible = false"
                        />
                      </li>
                      <li class="hide">
                        <el-tooltip
                          content="信息详情"
                          placement="bottom"
                          effect="dark"
                          :open-delay="500"
                        >
                          <a
                            @click="showDetail(data.rowkey)"
                            class="iconfont icon-xiangqing hover"
                          ></a>
                        </el-tooltip>
                      </li>
                      <li>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="查看原文"
                          placement="bottom"
                        >
                          <i
                            class="iconfont icon-lianjie"
                            @click="target(data.source_url)"
                          ></i>
                        </el-tooltip>
                      </li>
                    </ul>
                  </el-col>
                </el-row>
                <p class="summary" v-html="data.description"></p>
                <p class="info">
                  <span>媒体类型：{{ data.category }}</span>
                  <span
                    >来源：<var
                      class="source_name"
                      v-bind:title="
                        data.category === '微信'
                          ? data.author
                          : data.source_name
                      "
                    >
                      {{
                        data.category === '微信'
                          ? data.author
                          : data.source_name.length > 5
                          ? data.source_name.substr(0, 5) + '...'
                          : data.source_name
                      }}
                    </var>
                  </span>
                  <span
                    >作者：{{
                      data.author.length >= 1 ? data.author : '--'
                    }}</span
                  >
                  <span>信息地区：{{ msgArea(data) }}</span>

                  <span>发布时间：{{ data.published }}</span>
                </p>
              </li>
            </ul>
          <!-- </vue-scroll> -->
          <el-row class="button-group hide">
            <el-button
              round
              type="primary"
              @click="loadMore()"
              v-loading="listDialog.moreLoading"
              :disabled="listDialog.loadMoreDisabled"
              >查看更多</el-button
            >
          </el-row>
        </div>
      </div>
    </el-dialog>
    <article-detail
      ref="detail"
      :params="listData.params"
      :materials="materialData"
    />
    <generate-brief-dialog ref="input" v-on:submit="indexBuildBrief" />
  </div>
</template>
<script>
import articleDetail from '@components/common/article-detail.vue';
import generateBriefDialog from '@components/common/generate-brief.vue';
import favoriteDialog from '@components/common/favorite-dialog.vue';
import { lists as materialList, add_item } from '@/api/material';
import { eventBriefing } from '@/api/message';
import { formatMsgArea, handleDownload } from '@/utils/helpers';

export default {
  name: 'dialog-relat-infor',
  props: ['materials'],
  data() {
    return {
      listDialog: {
        visible: false,
        loading: false,
        loadMoreDisabled: false,
        moreLoading: false
      },
      listData: {
        list: [],
        page: { current: 0, size: 0, total: 0 },
        order: 'importance',
        params: {},
        api: null,
        phonyTotal: null
      },
      handleData: false,
      materialData: [],
      entitys: [],
      eventName: '',
      exportLoading: false
    };
  },
  watch: {
    materials: function(data) {
      this.materialData = data;
    }
  },
  mounted() {},
  components: {
    articleDetail,
    generateBriefDialog,
    favoriteDialog
  },
  methods: {
    target(url) {
      window.open(url, '_blank');
    },
    requestListData(api, args = null, phonyTotal = null) {
      var params = args;
      if (params) {
        const { event_id, name } = params;
        if (name) {
          this.eventName = name;
        }
        this.listData.phonyTotal = phonyTotal; // 子组件传参数据总量
        this.listData.params = { event_id };
        this.listData.list = [];
        this.listData.api = api;
        this.listData.page.current = 0;
        this.listDialog.loading = true;
        this.listDialog.visible = true;
        this.listDialog.loadMoreDisabled = false;
      } else {
        params = this.listData.params;
      }
      let page = parseInt(this.listData.page.current) + 1;
      params['page'] = page;
      params['order'] = this.listData.order;
      window.$axios
        .get(api, { params: params })
        .then(res => {
          if (res.data.state) {
            const { data, objs } = res.data.data;
            this.entitys = objs;
            if (_.size(data) < 1) {
              this.$message.warning('暂无更多');
              this.listDialog.loadMoreDisabled = true;
            }
            let result = data.map(item => {
              item.joinMaterial = { visible: false };
              return item;
            });
            this.listData.list = this.listData.list.concat(result);
            this.listData.page.total = _.size(result);
            this.listDialog.moreLoading = false;
            this.listDialog.loading = false;
            if (!this.materials) this.loadMaterial();
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(() => {
          this.$message.error('加载数据列表错误');
        });
    },
    msgArea(row) {
      return formatMsgArea(row);
    },
    randomColorClass() {
      let color = ['bd-e2b', 'bd-628', 'bd-c37', 'bd-6e7'];
      let index = _.random(0, _.size(color));
      return color[index];
    },
    showInput() {
      this.$refs.input.show();
    },
    indexBuildBrief(submitObj) {
      const { name, type } = submitObj;
      const { event_id } = this.listData.params;
      if (_.size(name) < 1 || _.size(name) > 10) {
        this.$message.warning('简报名称1~10个字符');
        return false;
      }
      let params = { name, type, event_id };
      this.handleData = false;
      Object.assign(params, submitObj);
      eventBriefing(params)
        .then(res => {
          this.$refs.input.loading = false;
          if (res.data.state) {
            this.$message.success('简报生成中，跳转至简报查看');
            this.$refs.input.visible = false;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(res => {
          this.$refs.input.loading = false;
          this.$message.error('生成简报错误，服务错误');
        });
    },
    exportData() {
      if (this.exportLoading) {
        return false;
      }
      this.handleData = false;
      const { event_id } = this.listData.params;

      handleDownload(
        `${window.service.api}/message/event_excel?event_id=${event_id}`,
        'GET',
        `${window.$moment().format('YYYY-MM-DD')}.xlsx`,
        null,
        this.listData.page.total,
        () => {
          this.exportLoading = false;
        }
      );
    },
    orderChange() {
      this.listData.page.current = 0;
      this.requestListData(this.listData.api, this.listData.params);
    },
    loadMore() {
      this.listDialog.moreLoading = true;
      this.requestListData(this.listData.api);
    },
    addRowMaterialAction(material, id) {
      if (!material.selectedId) {
        this.$message.warning('请选择素材库');
        return false;
      }
      material.loading = true;
      add_item({
        id: material.selectedId,
        ids: id
      })
        .then(res => {
          if (res.data.state) {
            this.$message.success('添加成功');
          } else {
            this.$message.error(res.data.error);
          }
          material.loading = false;
          material.visible = false;
        })
        .catch(res => {
          this.$message.error('添加素材失败，服务错误');
        });
    },
    showDetail(rowkey) {
      let pid = this.listData.params.plan_id
        ? this.listData.params.plan_id
        : null;
      this.$refs.detail.showDetail(rowkey, 'article-list', null, pid);
    },
    loadMaterial() {
      materialList()
        .then(res => {
          if (res.data.state) {
            var result = res.data.data;
            this.materialData = result;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(res => {
          this.$message.error('加载收藏夹失败，服务错误');
        });
    }
  }
};
</script>
<style scoped>
.mod-list-dialog >>> .el-dialog__header {
  padding-right: 50px;
}
.mod-list-dialog .dc-excel {
  float: right;
  font-size: 12px;
  margin-right: 10px;
  margin-top: 8px;
}
.mod-list-dialog .dc-excel:hover {
  background: none;
}
.mod-list-dialog >>> .icon-xiazai {
  margin-right: 5px;
  float: left;
}
.el-card {
  border: none;
}
.mod-list-dialog >>> .el-breadcrumb__item:first-child .el-breadcrumb__inner {
  max-width: 580px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}
/* 列表内容区块 */
.relat_infor >>> .el-card__body {
  padding: 10px 0;
}
.relat_infor .mod-title {
  border: none;
  padding: 0;
  height: 32px;
  width: calc(100% - 20px);
  overflow: hidden;
}
.relat_infor .mod-title span:first-child {
  float: left;
  border-left: 3px solid #555dfe;
  padding-left: 15px;
}
.mod-list-dialog li.item {
  padding: 20px 20px 10px 0;
}
.mod-list-dialog li.item:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
.item h3 {
  line-height: 24px;
}
.item .title {
  font-weight: 600;
  font-size: 14px;
  width: calc(100% - 200px);
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  text-overflow: ellipsis;
  float: left;
}
.item li {
  float: right;
  margin-left: 10px;
}
.item .summary {
  font-size: 12px;
  line-height: 20px;
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #414141;
  margin-top: 13px;
}
.item .info {
  line-height: 36px;
  font-size: 12px;
  color: #707070;
}
.item .info span {
  margin-right: 20px;
}
.relat-info .scroll-area {
  height:calc(100% - 10px);
}
/* 关联实体 */
/* .related_entities{padding:20px 0;} */
.related_entities li {
  margin-top: 10px;
  margin-left: 20px;
  font-size: 12px;
  height: 30px;
  line-height: 27px;
  display: inline-block;
  border-style: solid;
  border-width: 1px;
  padding: 0 15px;
  border-radius: 3px;
  font-weight: 400;
}
.bd-e2b {
  border-color: #e2b395;
  color: #e2b395;
}
.bd-628 {
  border-color: #6280d7;
  color: #6280d7;
}
.bd-c37 {
  border-color: #c3736f;
  color: #c3736f;
}
.bd-6e7 {
  border-color: #6e727e;
  color: #6e727e;
}
</style>
