<template>
  <el-container>
    <!-- 新建方案 -->
    <el-drawer
      :title="state === 'add' ? '新建方案' : `编辑方案 > 关键词设置`"
      :visible.sync="drawer"
      direction="rtl"
      :wrapperClosable="false"
      :close-on-press-escape="false"
      :before-close="handleClose"
      class="fa-drawer">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="普通模式" name="js">
          <div class="fa-box fa-height">
            <vue-scroll :ops="{bar: {keepShow: true}}">
              <div class="pr-10">
                <p class="permissions-tooltip" v-show="!user.isadmin && user.id !== plan.createdby && state !== 'add'">
                  <i class="el-icon-warning"></i>该方案由其他账户创建，您没有权限修改。
                </p>
                <p class="flex space-between">
                  <span>方案名称</span>
                  <span>
                    <i class="iconfont icon-zhiding mar-r-10 hide" @click="toToping(plan)" v-if="state === 'edit'" />
                    <i class="iconfont icon-shanchu hide" @click="delPlan()" v-if="state === 'edit'" />
                  </span>
                </p>
                <el-input
                  type="text"
                  placeholder="请设置方案名称"
                  v-model="name"
                  maxlength="10"
                  show-word-limit
                  class="w-200">
                </el-input>
                <div class="title flex space-between mar-t-20">
                  <h4>关键词设置</h4>
                  <span class="character" v-if="getLowLength() < 480">
                    <var>{{ getLowLength() }}</var>/{{ user.plan_words_limit }}
                  </span>
                  <span class="character" v-else-if="getLowLength() >= 480 && getLowLength() <= user.plan_words_limit">
                    <var class="cor-e6a">{{ getLowLength() }}</var>/{{ user.plan_words_limit }}
                  </span>
                  <span class="character" v-else-if="getLowLength() > user.plan_words_limit">
                    <var class="cor-fc5">{{ getLowLength() }}</var>/{{ user.plan_words_limit }}
                  </span>
                </div>
                <p class="lh-20 mar-t-20">包含所有下列关键词</p>
                  <!-- 单字符提示 -->
                <p class="lh-20">  <span class="monocase" v-if="invalidLowSingleWord('must')">
                    <i class="iconfont icon-guanbi"></i>关键词至少为两个字，请修改。
                  </span></p>
                    <!-- 重复字符 -->
                <p class="lh-20 mar-b-10">  <span class="repeat-character" v-if="invalidLowDoubleWord('must')">
                    <i class="iconfont icon-tishi"></i>您的方案中有重复的关键词，请核对是否需要修改
                  </span></p>
                <div class="box">
                  <el-tag
                    :key="index"
                    v-for="(word, index) in low_exps.must"
                    closable
                    :disable-transitions="false"
                    :type="invalidLowWord(word, 'must', index) || word.length === 1 ? 'danger' : ''"
                    @close="removeWord('must', index)">
                    {{ word }}
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="must.visible"
                    v-model="must.value"
                    ref="mustInput"
                    size="small"
                    @keyup.enter.native="handleConfirm('must')"
                    @blur="handleConfirm('must')">
                  </el-input>
                  <el-button v-else class="button-new-tag" size="small" @click="showInput('must')">+ 关键词</el-button>
                </div>
                <p class="mar-t-10 lh-20">包含至少一个下列关键词</p>
                  <!-- 单字符提示 -->
                <p class="lh-20"><span class="monocase" v-if="invalidLowSingleWord('should')">
                  <i class="iconfont icon-guanbi"></i>关键词至少为两个字，请修改。
                </span></p>
                  <!-- 重复字符 -->
                <p class="lh-20 mar-b-10"><span class="repeat-character" v-if="invalidLowDoubleWord('should')">
                  <i class="iconfont icon-tishi"></i>您的方案中有重复的关键词，请核对是否需要修改
                </span></p>
                <div class="box">
                  <el-tag
                    :key="index"
                    v-for="(word, index) in low_exps.should"
                    closable
                    :type="invalidLowWord(word, 'should', index) || word.length === 1 ? 'danger' : ''"
                    :disable-transitions="false"
                    @close="removeWord('should', index)">
                    {{ word }}
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="should.visible"
                    v-model="should.value"
                    ref="shouldInput"
                    size="small"
                    @keyup.enter.native="handleConfirm('should')"
                    @blur="handleConfirm('should')"
                  >
                  </el-input>
                  <el-button v-else class="button-new-tag" size="small" @click="showInput('should')">+ 关键词</el-button>
                </div>
                <p class="mar-t-20 lh-20">排除所有下列关键词</p>
                  <!-- 单字符提示 -->
                <p class="lh-20">  <span class="monocase" v-if="invalidLowSingleWord('not')">
                    <i class="iconfont icon-guanbi"></i>关键词至少为两个字，请修改。
                  </span></p>
                    <!-- 重复字符 -->
                  <p class="lh-20 mar-b-10"><span class="repeat-character" v-if="invalidLowDoubleWord('not')">
                    <i class="iconfont icon-tishi"></i>您的方案中有重复的关键词，请核对是否需要修改
                  </span></p>
                <div class="box">
                  <el-tag
                    :key="index"
                    v-for="(word, index) in low_exps.not"
                    closable
                    :disable-transitions="false"
                    :type="invalidLowWord(word, 'not', index) || word.length === 1 ? 'danger' : ''"
                    @close="removeWord('not', index)">
                    {{ word }}
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="not.visible"
                    v-model="not.value"
                    ref="notInput"
                    size="small"
                    @keyup.enter.native="handleConfirm('not')"
                    @blur="handleConfirm('not')"
                  >
                  </el-input>
                  <el-button v-else class="button-new-tag" size="small" @click="showInput('not')">+ 关键词</el-button>
                </div>
                <div class="mar-t-10">
                  <el-checkbox v-model="publiced">此方案对其它账号可见</el-checkbox>
                </div>
                <div class="button-group">
                  <el-button
                    :type="canSave('js') ? 'primary' : ''"
                    round
                    @click="save()"
                    v-loading="loading"
                    :disabled="loading || !canSave('js')">
                    {{ state === 'edit' ? '保存修改' : '创建方案' }}
                  </el-button>
                  <el-button round @click="cancel">{{ state === 'edit' ? '放弃修改' : '取消创建' }}</el-button>
                </div>
              </div>
            </vue-scroll>
          </div>
        </el-tab-pane>
        <el-tab-pane label="专家模式" name="zj">
          <div class="fa-box fa-height">
            <vue-scroll :ops="{bar: {keepShow: true}}">
              <div class="pr-10">
                <p class="permissions-tooltip" v-show="!user.isadmin && user.id !== plan.createdby && state !== 'add'">
                  <i class="el-icon-warning"></i>该方案由其他账户创建，您没有权限修改。
                </p>
                <p class="flex space-between">
                  <span>方案名称</span>
                  <span>
                  <i class="iconfont icon-zhiding mar-r-10 hide" @click="toToping(plan)" v-if="state === 'edit'" />
                  <i class="iconfont icon-shanchu hide" @click="delPlan()" v-if="state === 'edit'" />
                  </span>
                </p>
                <el-input
                  type="text"
                  placeholder="请设置方案名称"
                  v-model="name"
                  maxlength="10"
                  show-word-limit
                  class="w-200">
                </el-input>
                <p class="mar-t-20 lh-20">
                  <span class="b">关键词</span></p>
                  <!-- 单字符提示 -->
                  <p class="lh-20"><span class="monocase" v-if="invalidHighSingleWord()">
                    <i class="iconfont icon-guanbi"></i>关键词至少为两个字，请修改。
                  </span></p>
                    <!-- 重复字符 -->
                  <p class="lh-20 mar-b-10"><span class="repeat-character" v-if="invalidHighDoubleWord()">
                    <i class="iconfont icon-tishi"></i>您的方案中有重复的关键词，请核对是否需要修改
                  </span></p>

                <medium-editor
                        v-model="high_exps_html"
                        did="plan-primary-high-exps"
                        ref="editor"
                        @change="changeHighExps" />
                <div class="mar-t-10 fh-an">
                  <el-button @click="virtualEnter('+')">＋</el-button>
                  <el-button @click="virtualEnter('|')">|</el-button>
                  <el-button @click="virtualEnter('-')">－</el-button>
                  <el-button @click="virtualEnter('(')">(</el-button>
                  <el-button @click="virtualEnter(')')" class="mar-r-10">)</el-button>
                  <span class="cur-p tishi" @click="helpNote = !helpNote">怎样使用这些运算符号？</span>
                </div>
                <dl class="mar-t-10" v-show="helpNote">
                  <dt>什么情况下使用“+”？</dt>
                  <dd>“+”表示“且”。假如想了解上海天气相关的舆情信息，则输入“上海+天气”，意思是信息中需要同时包含“上海”和“天气”两个关键词。</dd>
                  <dt>什么情况下使用“|”？</dt>
                  <dd>“|”表示“或”。假如想了解上海或北京或广州相关的舆情信息，则输入“上海|北京|广州”，意思是信息中只需要包含“上海”“北京”“广州”中的其中任意一个关键词即可。</dd>
                  <dt>什么情况下使用“-”？</dt>
                  <dd>“-”表示排除。假如想了解上海天气相关的舆情信息，但不想看到空调维修相关的广告，则输入“上海+天气-空调维修”，则不会出现包含“空调维修”这个关键词的信息。</dd>
                  <dt>什么情况下使用“()”？</dt>
                  <dd>
                    括号内的关键词被组成一个整体。假如想了解上海或北京或广州等地和天气有关的舆情信息，则输入“(上海|北京|广州)+天气”，意思是信息中包含“上海”“北京”“广州”这组关键词中的其中任意一个关键词，且同时包含“天气”这个关键词。
                  </dd>
                </dl>
                <div class="mar-t-10">
                  <el-checkbox v-model="publiced">此方案对其它账号可见</el-checkbox>
                </div>
                <div class="button-group">
                  <el-button
                    :type="canSave('zj') ? 'primary' : ''"
                    round
                    @click="save()"
                    v-loading="loading"
                    :disabled="loading || !canSave('zj')">
                    {{ state === 'edit' ? '保存修改' : '创建方案' }}
                  </el-button>
                  <el-button round @click="cancel">{{ state === 'edit' ? '放弃修改' : '取消创建' }}</el-button>
                </div>
              </div>
            </vue-scroll>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-drawer>
    <confirm ref="confirmDel" title="删除方案" confirm="删除" @must="confirmDel" />
    <confirm ref="confirmOut" title="离开此页面" confirm="离开" @must="confirmOut" class="single-information"/>

    <!-- 方案已达上限 -->
    <el-dialog
      :visible.sync="limitDialog.visible"
      class="Dialog_style2 kyzh">
      <el-row class="w-100 dp_box" :close-on-click-modal="false">
        <el-col :span="13">
          <div class="img">可用方案数达上限</div>
        </el-col>
        <el-col :span="11">
          <p>您购买的<strong>{{bundle.bundle_name}}</strong></p>
          <p class="fw-b">可用方案数已达上限：<span class="red">{{ bundle.plans.used }}个</span></p>
          <p class="mar-t-10">请删除方案后再新增，</p>
          <p>或联系客服人员</p>
        </el-col>
      </el-row>
    </el-dialog>
    <!--@end 方案已达上限 -->
  </el-container>
</template>
<script>
  import confirm from '@components/common/confirm.vue';
  import { create, update, toping, delPlan as delPlanReq } from "@/api/plan";
  import mediumeditor from './mediumeditor';
  import {mapGetters} from 'vuex';
  const _ = window._;
  export default {
    name: 'plan-drawer',
    components: {
      confirm,
      'medium-editor': mediumeditor
    },
    data() {
      return {
        limitDialog: {visible: false},
        activeName: 'js',
        drawer: false,
        name: '',
        high_exps: "",
        high_exps_html: "",
        low_exps: {
          should: [],
          must: [],
          not: []
        },
        should: {visible: false, value: ''},
        must: {visible: false, value: ''},
        not: {visible: false, value: ''},
        publiced: false,
        plan: {},
        state: 'add',
        loading: false,
        originPlan: "",
        helpNote: false,
        newPlanSucc: false,
      }
    },
    computed: {
      ...mapGetters([
        'plans',
        'bundle',
        'user'
      ]),
      bundle() {
        return this.$store.state.user.bundle
      }
    },
    watch: {
      'activeName': function (v) {
      },
      'drawer': function (v) {
        const body = window.document.getElementsByTagName('body')[0]
        body.style.overflowY = v ? 'hidden' : 'scroll'
      }
    },
    destroyed() {
      window.document.getElementsByTagName('body')[0].style.overflowY = 'scroll'
    },
    mounted() {
      window.console.log(this.user)
    },
    methods: {
      add(type = 'js') {
        this.drawer = true;
        this.state = 'add';
        this.activeName = type;
        this.name = '';
        this.high_exps = "";
        this.high_exps_html = "";
        this.low_exps = {should: [], must: [], not: []};
        this.should = {visible: false, value: ''};
        this.must = {visible: false, value: ''};
        this.not = {visible: false, value: ''};
        this.publiced = false;
        this.originPlan = JSON.stringify(this.calculation());
      },
      edited(plan) {
        const splan = JSON.parse(JSON.stringify(plan));
        this.plan = splan;
        this.state = 'edit';
        this.drawer = true;
        this.activeName = splan.high_exps.length >= 1 ? 'zj' : 'js';
        this.name = splan.name;
        this.changeHighExps(splan.high_exps);
        this.publiced = splan.public === "close" ? false : true;
        if (this.activeName === 'js') {
          const exps = typeof splan.low_exps === 'string' ? JSON.parse(splan.low_exps) : splan.low_exps;
          this.low_exps.should = exps.hasOwnProperty('should') ? exps.should : [];
          this.low_exps.should = this.low_exps.should.filter((w) => {
            return w.length >= 1;
          });
          this.low_exps.must = typeof exps.must === 'string' ? exps.must.split(",").filter((w) => {
            return w.length >= 1;
          }) : exps.must;
          this.low_exps.not = typeof exps.not === 'string' ? exps.not.split(",").filter((w) => {
            return w.length >= 1;
          }) : exps.not;
        } else {
          this.low_exps = {should: [], must: [], not: []};
        }
        this.originPlan = JSON.stringify(this.calculation());
      },
      invalidLowSingleWord(type)
      {
        const exps = JSON.parse(JSON.stringify(this.low_exps))

        const singleWords = exps[type].filter((word) => {
          return word.length === 1
        })

        return window._.size(singleWords) >= 1
      },
      invalidLowDoubleWord(type)
      {
        const exps = JSON.parse(JSON.stringify(this.low_exps))
        let invalid = false

        window._(exps[type]).forEach((word, index) => {
          if (this.invalidLowWord(word, type, index)) {
            invalid = true
            return invalid
          }
        })

        return invalid
      },
      invalidLowWord(word, type, index)
      {
        const exps = JSON.parse(JSON.stringify(this.low_exps))

        if (['should', 'must', 'not'].indexOf(type) === -1) {
          return true
        }

        exps[type].splice(index, 1)

        if (exps.should.indexOf(word) !== -1) {
          return true
        }

        if (exps.must.indexOf(word) !== -1) {
          return true
        }

        if (exps.not.indexOf(word) !== -1) {
          return true
        }

        return false
      },
      invalidHighSingleWord ()
      {
        const data = this.high_exps.split(/([^\+\-\|\|\(\)]+)([\+\-\|\|\(\)]+)/)
        let textData = data.filter((text) => {
          return text.length >= 1
        })

        const regx = /[\+\-\|\|\(\)]+/
        let invalid = false

        window._(textData).forEach((text) => {
          if (!regx.test(text) && text.length === 1) {
            invalid = true
            return invalid
          }
        })

        return invalid
      },
      invalidHighDoubleWord ()
      {
        const data = this.high_exps.split(/([^\+\-\|\|\(\)]+)([\+\-\|\|\(\)]+)/)
        let textData = data.filter((text) => {
          return text.length >= 1
        })

        const regx = /[\+\-\|\|\(\)]+/
        let repeatArr = []
        textData.map((text, index) => {
          if (!regx.test(text)) {
            const cpTextData = JSON.parse(JSON.stringify(textData))
            cpTextData.splice(index, 1)

            const i = _.findIndex(cpTextData, (v) => {
              return v.indexOf(text) !== -1
            })

            if (i !== -1 && repeatArr.indexOf(text) === -1 && text.length > 1) {
              repeatArr.push(text)
            }
          }
        })

        return repeatArr.length >= 1
      },
      changeHighExps (v)
      {
        this.high_exps = v
        const data = v.split(/([^\+\-\|\|\(\)]+)([\+\-\|\|\(\)]+)/)
        let textData = data.filter((text) => {
          return text.length >= 1
        })

        const regx = /[\+\-\|\|\(\)]+/
        let repeatArr = []
        textData.map((text, index) => {
          if (!regx.test(text)) {
            const cpTextData = JSON.parse(JSON.stringify(textData))
            cpTextData.splice(index, 1)

            const i = _.findIndex(cpTextData, (v) => {
              return v.indexOf(text) !== -1
            })

            if (i !== -1 && repeatArr.indexOf(text) === -1 && text.length > 1) {
              repeatArr.push(text)
            }
          }
        })

        repeatArr.map((text) => {
          textData = textData.map((s_text) => {
            return s_text.replace(text, `<span style="color: #ff0000;">${text}</span>`)
          })
        })

        textData = textData.map((text) => {
          if (!regx.test(text) && text.length === 1) {
            return `<span style="color: #ff0000;">${text}</span>`
          }
          return text
        })


        this.$nextTick(() => {
          this.high_exps_html = textData.join('')
        })
      },
      calculation(verify = false) {
        const {activeName, name, high_exps, publiced, low_exps, state, plan} = this
        if (name.length < 1 || name.length > 10) {
          if (verify) {
            this.$message.warning('方案名称1~10个字符长度')
            return false
          }
        }
        if (activeName === 'js') {
          if (low_exps.should.length < 1 && low_exps.must.length < 1 && low_exps.not.length < 1) {
            if (verify) {
              this.$message.warning('请填写方案关键词, 不能为空')
              return false
            }
          }


          if (
                this.invalidLowSingleWord('should') ||
                this.invalidLowSingleWord('must') ||
                this.invalidLowSingleWord('not')
          )
          {
            if (verify) {
              this.$message.error('关键词至少为两个字，请修改。')
            }

            return false
          }


          this.high_exps = "";
        } else {
          if (high_exps.length < 1) {
            if (verify) {
              this.$message.warning('请填写方案关键词, 不能为空')
            }
            return false
          }

          if (high_exps.length > this.user.plan_words_limit) {
            if (verify) {
              this.$message.error('关键词字数超过最大值，请减少关键词字数。')
            }
            return false
          }


          if (this.invalidHighSingleWord()) {
            if (verify) {
              this.$message.error('关键词至少为两个字，请修改。')
            }

            return false
          }

          this.low_exps = {
            should: [],
            must: [],
            not: []
          };
        }
        const data = {
          name,
          high_exps: this.high_exps,
          low_exps: this.low_exps,
          public: publiced ? 'open' : 'close'
        };
        if (state === 'edit') data.plan_id = plan.pid;
        return data;
      },
      canSave(type) {
        if (type === 'zj') {
          if (this.state === 'edit') {
            return (this.name.length >= 1) && (this.user.isadmin || this.user.id === this.plan.createdby)
          }

          return this.name.length >= 1
        } else if (type === 'js') {
          const length = this.low_exps.should.join('').length + this.low_exps.must.join('').length + this.low_exps.not.join('').length
          if (this.state === 'edit') {
            return (this.name.length >= 1 && length > 1 && length <= 500) && (this.user.isadmin || this.user.id === this.plan.createdby)
          }

          return this.name.length >= 1 && length > 1 && length <= 500
        }
      },
      save() {
        const {state} = this;
        const data = this.calculation(true);
        if (!data) return false;
        this.loading = true;
        if (state === 'edit') {
          update(data).then(res => {
            if (res.data.state) {
              this.$message.success('保存成功');
              this.$store.dispatch('planList').then(() => {
                this.$emit('updateDone', data)
              });
              this.drawer = false;
            } else {
              this.$message.error(res.data.error);
            }
            this.loading = false;
          }).catch(err => {
            this.loading = false;
            window.console.error(err)
          })
        } else {
          create(data).then(res => {
            if (res.data.state) {
              this.$message.success('创建成功')
              this.$store.dispatch('planList').then(() => {
                const pid = res.data.message
                this.$emit('createDone', pid)
              })
              this.drawer = false
              this.newPlanSucc = true
            } else if (res.data.error == '套餐方案已经用完!') {
              this.limitDialog.visible = true
              this.drawer = false;
            } else {
              this.$message.error(res.data.error);
            }
            this.loading = false;
          }).catch(err => {
            this.loading = false;
            window.console.error(err)
          })
        }
      },
      delPlan() {
        let textBox = [
          { type: "error", text: "您将不能再收到此方案的预警信息" },
          { type: "success", text: "使用此方案数据生成历史报告将会被保留" }
        ];
        this.$refs.confirmDel.show(textBox, {plan_id: this.plan.pid});
      },
      confirmDel(data) {
        delPlanReq(data).then(res => {
          if (res.data.state) {
            this.$message.success('删除方案成功');
            this.$store.dispatch('planList');
            // 方案套餐数量
            this.bundle.plans.remain = this.bundle.plans.remain + 1;
            this.$store.dispatch("storeBundle", this.bundle);
            this.drawer = false;
          } else {
            this.$message.error(res.data.error);
          }
        }).catch(err => {
          window.console.error(err);
        })
      },
      toToping(plan) {
        this.$message(`“${plan.name}”方案置顶中`)
        let topPlan = _.maxBy(this.plans, (o) => {
          return o.top;
        });
        toping({"id": plan.pid, "top": topPlan.top + 1}).then(res => {
          if (res.data.state) {
            this.$message.success("置顶成功");
            this.$store.dispatch('planList');
          } else {
            this.$message.error(res.data.error);
          }
        }).catch(err => {
          window.console.error(err);
        })
      },
      handleClose(done) {
        if (JSON.stringify(this.calculation()) !== this.originPlan) {
          this.$refs.confirmOut.show([{type: 'error', text: '若离开此页面，系统将不会保存您未保存的修改。'}]);
        } else {
          done();
        }
      },
      cancel() {
        if (JSON.stringify(this.calculation()) !== this.originPlan) {
          this.$refs.confirmOut.show([{type: 'error', text: '若离开此页面，系统将不会保存您未保存的修改。'}]);
        } else {
          this.confirmOut();
        }
      },
      confirmOut() {
        this.drawer = false;
      },
      showInput(key) {
        if (this.low_exps[key].length >= 30) {
          this.$message.warning('关键词组数不得超过30组');
          return false;
        }
        this[key].visible = true;
        this.$nextTick(() => {
          const ref = `${key}Input`;
          this.$refs[ref].$refs.input.focus();
        });
      },
      handleConfirm(key) {
        let inputValue = this[key].value;
        if (inputValue) {
          this.low_exps[key].push(inputValue);
        }
        this[key].visible = false;
        this[key].value = '';
      },
      removeWord(key, index) {
        this.low_exps[key].splice(index, 1);
      },
      virtualEnter(key) {
        let element = window.document.getElementById('plan-primary-high-exps');
        let pos = this.$refs['editor'].getCursortPosition();
        let prefix = this.high_exps.substr(0, pos);
        let suffix = this.high_exps.substr(pos);
        this.high_exps = prefix + key + suffix;
        this.changeHighExps(this.high_exps);
      },
      getLowLength() {
        const { low_exps } = this
        const length = low_exps.should.join('').length + low_exps.must.join('').length + low_exps.not.join('').length

        return length
      },
      handleClick(tab, event) {
      }
    }
  }
</script>
<style scoped>
/* 新建方案 */
.fa-drawer >>> .el-drawer{width:600px !important;}
.fa-drawer >>> .el-drawer__header{padding-left:50px;font-size:14px;color: #414141;padding-right:50px;margin-bottom: 12px;font-weight: 600;}
.el-drawer p {font-size: 12px;line-height: 40px;color: #414141;font-weight: 600;}
.el-drawer p.lh-20{line-height: 20px;}
.fa-drawer >>> .el-drawer__body{height:calc(100% - 47px);display:flex;}
.fa-drawer >>> .el-tabs--top .el-tabs__item{padding: 0;margin-right:40px;}
.box {padding:10px;min-height: 98px;line-height: 27px;overflow: hidden;border: 1px solid #DCDFE6;border-radius: 4px;}
.input-new-tag {max-width: 120px;}
.fh-an >>> .el-button {padding: 0;width: 30px;height: 30px;text-align: center;line-height: 30px;}
.fa-drawer dl {background: #F9FAFE;border-radius: 4px;padding: 10px;line-height: 24px;}
.fa-drawer dt {font-size: 14px;color: #414141;}
.fa-drawer dd {font-size: 12px;color: #575757;}
.fa-drawer .button-group {margin-top: 40px;text-align: left;}
.fa-drawer .form-box {padding: 0 50px;}
.fa-drawer >>> .el-tabs__header{margin:0 50px;}
.fa-drawer >>> .el-tabs__nav-wrap::after{background-color:#F2F2F2;}
.fa-drawer >>> .el-tabs__active-bar{display: none;}
.fa-drawer >>> .el-tabs__content{padding:20px 50px 30px 50px;overflow:visible;height:90%;box-shadow:none;}
.fa-height{height: calc(100% - 47px);}
.tishi{font-size: 12px;color: #707070;}
.fa-drawer >>> .el-tab-pane{height:calc(100% - 47px);}
.fa-drawer >>> .el-tabs--top{width:100%;}
.fa-drawer .button-group >>> .el-button{font-size: 14px;}
/* 2.0.5迭代 2020年6月18日13:47:17 */
.fa-drawer .title{line-height:20px;}
.fa-drawer .title h4{font-size: 14px;font-weight: 600;}
.fa-drawer .character{font-size: 12px;color: #575757;}
.fa-drawer .character var{color: #575757;}
.fa-drawer .character .cor-e6a{color: #e6a23c;}
.fa-drawer .character .cor-fc5{color: #fc5d73;}
/* 单字符及重复字符提示 */
.fa-drawer .monocase,.fa-drawer .monocase .iconfont{color:#FC5D73;font-weight: normal;}
.fa-drawer .monocase .iconfont,.fa-drawer .repeat-character .iconfont{margin-right:5px;font-size: 14px;margin-left: 0;}
.fa-drawer .repeat-character,.fa-drawer .repeat-character .iconfont{color:#e6a23c;font-weight: normal;}
.box >>> .el-tag.error{border:1px solid rgba(252,93,115,0.1);background:rgba(252,93,115,0.2);color:#FC5D73;}
.box >>> .el-tag.error .el-tag__close{color:#FC5D73;}
.fa-drawer >>> .el-textarea .character{position: absolute;right: 5px;bottom: 5px;}
.plan-primary-high-exps >>> .el-input__inner{height:160px;overflow:auto;}
p.permissions-tooltip{height:40px;background:rgba(253,246,236,1);border-radius:4px;padding:0 20px;color:#E6A23C;}
p.permissions-tooltip >>> .el-icon-warning{font-size: 16px;color:#E6A23C;margin-right: 10px;margin-top:12px;float: left;}
</style>
