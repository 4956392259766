<template>
  <div class="el-container">
    <el-dialog
      :visible.sync="visible"
      class="Dialog_style1 generate_briefing" :close-on-click-modal="false">
      <div slot="title" class="mod-title">生成简报</div>
      <div class="h-730">
        <vue-scroll>
          <div class="cont">
            <el-row :gutter="20" class="mar-t-30">
              <el-col :span="5" :offset="2">简报名称
              </el-col>
              <el-col :span="17">
                <el-input class="w-200"
                  placeholder="请输入报告名称"
                  maxlength="10"
                  v-model="submitObj.name"
                  show-word-limit>
                </el-input>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="5" :offset="2">副标题
              </el-col>
              <el-col :span="17" style="line-height: normal;" class="lh-30 sub_title mar-t-10">
                <el-input type="text" placeholder="（非必填）请输入副标题" v-model="submitObj.subtitle" maxlength="30" show-word-limit class="w-430"></el-input>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="theme_colors">
              <el-col :span="5" :offset="2">主题颜色
              </el-col>
              <el-col :span="17" class="mar-t-10">
                <div style="line-height: normal">
                  <div v-for="item in themeList"
                       :key="item.label">
                    <el-radio v-model="submitObj.theme_color" :label="item.label">{{ item.name }}
                    </el-radio>
                    <span :style="{ background: item.color }" class="item-theme-preview"></span>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="5" :offset="2">简报模版
              </el-col>
              <el-col :span="17" class="mar-t-10">
                <el-form>
                  <ul>
                    <li>
                      <el-radio label="classic" v-model="submitObj.type">经典统计模版
                      </el-radio>
                      <a href="/brief/example/classic" target="_blank" class="iconfont icon-chakan"></a>
                    </li>
                    <li>
                      <el-radio label="enhance" v-model="submitObj.type">重点信息模版
                      </el-radio>
                      <a href="/brief/example/advanced" target="_blank" class="iconfont icon-chakan"></a>
                    </li>
                    <li :class="{jy: !showDaily}" v-show="!hideDaily">
                      <el-radio :disabled="!showDaily" label="daily_common" v-model="submitObj.type">日报常用模版
                      </el-radio>
                      <a href="/brief/example/common" target="_blank" class="iconfont icon-chakan"></a>
                      <span
                              :class="{tips: true, 'col-fc5': submitObj.type === 'daily_common'}"
                              v-show="!showDaily">要使用该模板，请将分析时间调整为24小时</span>
                    </li>
                    <li :class="{jy: !showDaily}" v-show="!hideDaily">
                      <el-radio :disabled="!showDaily" label="daily_mix" v-model="submitObj.type">日报混合模版
                      </el-radio>
                      <a href="/brief/example/mix" target="_blank" class="iconfont icon-chakan"></a>
                      <span
                              :class="{tips: true, 'col-fc5': submitObj.type === 'daily_mix'}"
                              v-show="!showDaily">要使用该模板，请将分析时间调整为24小时</span>
                    </li>
                    <li class="abstract">
                      <el-radio label="abstract" v-model="submitObj.type">信息摘要模板</el-radio>
                      <a href="/autobriefing/day/abstract" target="_blank" class="iconfont icon-chakan mar-r-20"></a>
                      <span class="tips" v-show="submitObj.type === 'abstract'">最多为您摘取前100条重要信息</span>
                    </li>
                  </ul>
                </el-form>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="submitObj.type === 'daily_mix' && showDaily">
              <el-col :span="5" :offset="2">
                <el-button plain class="gjsz" @click="setting.open = !setting.open">高级设置</el-button>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-show="setting.open && submitObj.type === 'daily_mix' && showDaily">
              <div class="gjsz_cont">
                <el-form label-width="70px">
                  <el-form-item label="维度分析">
                    <div class="wdfx_cont">
                      <div class="item flex" v-for="(dimension, index) in setting.dimensions" :key="index">
                        <el-input v-model="dimension.name" type="text" placeholder="维度一" maxlength="6" show-word-limit class="w-120 mar-r-10"></el-input>
                        <div>
                          <div class="ov">
                            <el-input :id="`brief_dimension${index}`" type="textarea" :autosize="{minRows:1}" class="textarea plan-primary f-l" v-model="dimension.exps" placeholder='关键词请用"+"、"-"、"("、")"隔开'
                            ></el-input>
                            <i class="iconfont icon-guanbi f-l" @click="removeItem('dimension', index)"
                               v-show="setting.dimensions.length > 1"></i>
                          </div>
                          <div class="fh_btn_group mar-t-5">
                            <el-button @click="virtualEnter('+', `brief_dimension${index}`, dimension)">＋</el-button>
                            <el-button @click="virtualEnter('|', `brief_dimension${index}`, dimension)">|</el-button>
                            <el-button @click="virtualEnter('-', `brief_dimension${index}`, dimension)">－</el-button>
                            <el-button @click="virtualEnter('(', `brief_dimension${index}`, dimension)">(</el-button>
                            <el-button @click="virtualEnter(')', `brief_dimension${index}`, dimension)">)</el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="clear"></div>
                    <el-button plain class="bd_n circle-plus" icon="iconfont icon-zengjia" @click="addItem('dimension')">新增维度
                    </el-button>
                  </el-form-item>
                  <el-form-item label="竞品分析" class="jpfx mar-t-10">
                    <div class="wdfx_cont">
                      <div class="item" v-for="(id, index) in setting.plan_ids" :key="index">
                        <el-select v-model="setting.plan_ids[index]" placeholder="请选择竞品方案">
                          <el-option :disabled="setting.plan_ids.indexOf(plan.pid) !== -1" :label="plan.name" :value="plan.pid" v-for="(plan, index) in plans" :key="index">
                          </el-option>
                        </el-select>
                        <i class="iconfont icon-guanbi" @click="removeItem('plan', index)" v-show="setting.plan_ids.length > 1"></i>
                      </div>
                    </div>
                    <el-button plain class="bd_n circle-plus" icon="iconfont icon-zengjia" @click="addItem('plan')">
                      新增竞品方案
                    </el-button>
                  </el-form-item>
                </el-form>
              </div>
            </el-row>
            <div class="button-group">
              <el-button
                      :class="{jy: disabledGenerate}"
                      type="primary"
                      v-loading="loading"
                      :disabled="loading || disabledGenerate"
                      round
                      @click="inputDialogSubmit()">生成</el-button>
              <el-button round @click="visible = false">取消</el-button>
            </div>
          </div>
        </vue-scroll>
      </div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false"
            :visible.sync="limitDialog.visible"
            class="Dialog_style2 kyzh">
      <el-row class="w-100 dp_box">
        <el-col :span="13">
          <div class="img">未购买此模板</div>
        </el-col>
        <el-col :span="11">
          <p class="mar-t-20">您购买的<strong>{{bundle.bundle_name}}</strong></p>
          <p class="mar-t-10">您没有开通该模版，</p>
          <p>购买请联系相关人员</p>
        </el-col>
      </el-row>
    </el-dialog>

  </div>
</template>
<script type="application/javascript">
  import {themeList} from '@/utils/constants'
  import { getMenuForId } from '@/utils/helpers'
  import {mapGetters} from 'vuex'
  export default {
    name: 'dialog-generate-brief',
    props: [],
    data() {
      return {
        themeList,
        limitDialog: {
          visible: false
        },
        // 提交参数
        submitObj: {
          theme_color: themeList[0].label,
          subtitle: '',
          name: '',
          type: 'classic',
        },
        setting: {
          open: true,
          plan_ids: [''],
          dimensions: [{name: '维度一', exps: ''}]
        },
        numberMap: ["一", "二", "三", "四", "五", "六"],
        visible: false,
        showDaily: true,
        hideDaily: false,
        disabledGenerate: false,
        loading: false,
        briefRight: {
          abstract: false,
          classic: false,
          daily_common: false,
          daily_mix: false,
          enhance: false
        }
      }
    },
    computed: {
      ...mapGetters([
        'plans',
        'menus',
        'bundle'
      ])
    },
    watch: {
      'submitObj.type': function (type) {
        if (!this.briefRight[type]) {
          this.disabledGenerate = true
          this.limitDialog.visible = true
        } else {
          this.disabledGenerate = false
        }
      }
    },
    mounted() {
      // id 6为简报制作
      const brief = getMenuForId(this.menus, 6)
      if (brief !== undefined) {
        Object.assign(this.briefRight, brief.submodule.briefReport)

        if (!this.briefRight[this.submitObj.type]) {
          this.disabledGenerate = true
          this.limitDialog.visible = true
        }
      }
    },
    methods: {
      show(disableDaily = true, hideDaily = false) {
        Object.assign(this.submitObj, {
          name: '',
          theme_color: themeList[0].label,
          subtitle: '',
          type: 'classic',
        });
        this.setting = {
          open: true,
          plan_ids: [''],
          dimensions: [{name: '维度一', exps: ''}]
        }
        this.showDaily = disableDaily
        this.hideDaily = hideDaily
        this.visible = true
        this.loading = false
      },
      validForm() {
        const _this = this;
        const {dimensions, plan_ids} = this.setting;
        if (this.submitObj.name.length < 1) {
          this.$message.warning('请填写报告名称');
          return true;
        }
        if (this.submitObj.type !== 'daily_mix') return false;
        var alreadyDimensionName = "";
        for (let i = 0; i < dimensions.length; i++) {
          dimensions[i].name = dimensions[i].name.trim();
          dimensions[i].exps = dimensions[i].exps.trim();
          if (dimensions[i].name.length < 1) {
            _this.$message.warning("维度名称不能为空");
            return true;
          }
          if (alreadyDimensionName === dimensions[i].name) {
            _this.$message.warning('维度名称"' + alreadyDimensionName + '"不能重名或为空');
            return true;
          }
          alreadyDimensionName = dimensions[i].name;
          if (dimensions[i].exps.length === 0) {
            // _this.$message.warning('请填写或删除维度"' + dimensions[i].name + '"(关键词不能为空)');
            // return true;
          }
          if (dimensions[i].exps.length > 100) {
            _this.$message.warning(dimensions[i].name + '，（长度不能超过100个字符）');
            return true;
          }
        }
        const pids = plan_ids.filter((id) => {
          return id;
        });
        if (_.size(pids) < 1) {
          // _this.$message.warning('请选择竞品方案');
          // return true;
        }
        return false;
      },
      virtualEnter(key, id, dimension) {
        let element = window.document.getElementById(id);
        let pos = element.selectionStart;
        let prefix = dimension.exps.substr(0, pos);
        let suffix = dimension.exps.substr(pos);
        dimension.exps = `${prefix}${key}${suffix}`;
        // 设置光标位置
        if (element.setSelectionRange) {
          //兼容火狐,谷歌
          setTimeout(function () {
            element.setSelectionRange(pos + 1, pos + 1);
            element.focus();
          }, 0);
        } else if (element.createTextRange) {
          //兼容IE
          let rng = element.createTextRange();
          rng.move("character", pos + 1);
          rng.select();
        }
      },
      calculationDefaultName(name) {
        var names = this.setting.dimensions.map(function (item) {
          return item.name
        })
        if (names.indexOf(name) !== -1) {
          for (let i in this.numberMap) {
            let newName = `维度${this.numberMap[i]}`
            if (names.indexOf(newName) === -1) return newName
          }
        }
        return name
      },
      addItem(type) {
        switch (type) {
          case 'dimension':
            if (this.setting.dimensions.length >= 6) {
              this.$message.warning("最多只能添加6组竞品维度")
              return false
            }
            var i = this.setting.dimensions.length
            var name = this.calculationDefaultName(`维度${this.numberMap[i]}`)
            this.setting.dimensions = this.setting.dimensions.concat([{name: name, exps: ''}])
            break;
          case 'plan':
            if (this.setting.plan_ids.length >= 6) {
              this.$message.warning("最多只能添加6组竞品方案")
              return false
            }
            this.setting.plan_ids = this.setting.plan_ids.concat([''])
            break;
        }
      },
      removeItem(type, index) {
        switch (type) {
          case 'dimension':
            if (_.size(this.setting.dimensions) === 1) {
              this.$message.warning('维度不能为空,最少一个')
              return false
            }
            this.setting.dimensions.splice(index, 1)
            break;
          case 'plan':
            if (_.size(this.setting.plan_ids) === 1) {
              this.$message.warning('竞品不能为空，最少一个')
              return false
            }
            this.setting.plan_ids.splice(index, 1)
            break;
        }
      },
      inputDialogSubmit() {
        const {name, theme_color, type, subtitle} = this.submitObj;
        const res = this.validForm();
        if (res) return false;
        const obj = {
          name,
          theme_color,
          type,
          subtitle
        };
        if (type === 'daily_mix') {
          obj.dimensions = this.setting.dimensions.filter((dimension) => {
            return dimension.exps.length >= 1;
          });
          obj.dimensions = JSON.stringify(obj.dimensions);
          obj.vs_plans = this.setting.plan_ids.filter((id) => {
            return id;
          });
          obj.vs_plans = JSON.stringify(obj.vs_plans);
        }
        this.loading = true
        this.$emit('submit', obj)
      }
    },
  };
</script>
<style lang="scss" scoped>
  .item-big-input {
    display: inline-block;
    width: 360px;
    vertical-align: top;
    /deep/ .el-button:focus, .el-button:hover {
      border: 1px solid #DCDFE6;
      background: #FFF;
    }
    .item-btn {
      margin-top: 10px;
      .el-button--small,
      .el-button--small.is-round {
        padding: 4px 15px;
      }
      button {
        color: #a1a1a1;
        width: 100px;
      }
      .is-acitve {
        border: 1px solid #2a67b3;
        color: #2a67b3;
      }
    }
  }
  .item-theme-preview {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 2px;
  }
</style>
<style scoped>
.generate_briefing >>> .el-dialog__title {float: left;}
.generate_briefing .el-row {width: 100%;}
.w-200 {width: 200px;}
.generate_briefing .cont {display: block;}
.generate_briefing li {width: 100%;line-height: 30px;height: 30px;overflow: hidden;font-weight: 400;}
.generate_briefing .button-group {margin-top: 20px;}
.Dialog_style1.generate_briefing >>> .el-dialog {width: 720px;height: 800px;font-weight:400;}
.Dialog_style1.generate_briefing >>> .el-input--suffix .el-input__inner, .Dialog_style1.generate_briefing >>> .el-textarea .el-textarea__inner {font-weight:400;}
.Dialog_style1.generate_briefing >>> .el-textarea {width:calc(100% - 35px);vertical-align: top;position: relative;}
.Dialog_style1.generate_briefing >>> .el-textarea .el-textarea__inner {width: 340px;line-height: 27px;padding: 2px 10px;}
.Dialog_style1.generate_briefing >>> .el-radio .el-radio__label {line-height: 30px;}
.gjsz {margin-left: -10px;}
.gjsz_cont {padding: 20px 30px;background:#F9F9F9;margin-left: 30px;margin-right: 10px;border-radius: 4px;}
.gjsz_cont >>> .el-form-item--small.el-form-item {margin-bottom: 0;}
.fh_btn_group .el-button--small {padding: 0;width: 24px;height: 24px;text-align: center;line-height: 20px;font-size: 14px;float: left;margin-right: 5px;}
.fh_btn_group .el-button + .el-button {margin-right: 5px;}
.bd_n.circle-plus {border: none;padding-left: 0;line-height: 18px;display: flex;background: none;}
.circle-plus >>> .iconfont icon-zengjia {font-size: 20px;line-height: 18px;}
.circle-plus span {display: inline-block;}
.wdfx_cont .item:not(:last-child), .wdfx_cont .item:not(:last-child) .fh_btn_group .el-button--small {margin-bottom: 10px;}
.wdfx_cont .item:only-child, .wdfx_cont .item:only-child .fh_btn_group .el-button--small {margin-bottom: 0;}
.h-730 {height: 730px;}
/* .w-120 >>> .el-input__suffix {top: -15px;} */
.generate_briefing .jy .tip,.generate_briefing .abstract .tips{color:#888888;display:inline-block;}
.col-fc5 {color: #FC5D73 !important;}
.gjsz_cont >>> .el-input__count{height: 32px;}
</style>
