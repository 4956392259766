<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      class="common-big-dialog common-list-dialog"
      :close-on-click-modal="false"
    >
      <div slot="title" class="mod-title ov-h">
        <el-breadcrumb class="f-l" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :title="name" class="cur-p">{{
            name
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>事件时序</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="event-sequence listDialogVisible h-100" v-loading="loading">
        <div class="scroll-area">
          <!-- <vue-scroll> -->
            <!-- 事件信息走势 -->
            <el-row>
              <el-card class="box-card">
                <div class="mod-title">事件信息走势</div>
                <div class="echarts_empty hide">
                  <img
                    src="@/assets/images/common/empty/echarts_empty.svg"
                    alt="暂无数据"
                    height="120"
                    type="image/svg+xml"
                  />
                </div>
                <div id="trend" style="width: 100%;height:400px;"></div>
              </el-card>
            </el-row>
            <!--@end 事件信息走势 -->
            <!-- 事件时序图 -->
            <el-row>
              <el-card class="box-card event-sequence-map ov-v">
                <div class="mod-title"><span>事件时序图</span></div>
                <ul class="mar-t-20">
                  <li class="item" v-for="(item, index) in data" :key="index">
                    <div class="item-cont">
                      <time>{{ item.time }}</time>
                      <img
                        src="@/assets/images/common/icon/red-dot.svg"
                        width="14"
                        alt=""
                      />
                      <img
                        src="@/assets/images/common/icon/blue-dot.svg"
                        width="14"
                        alt=""
                        class="hide"
                      />
                      <!-- <i class="iconfont icon-shijian"></i> -->
                      <div class="pos-r f-l">
                        <a class="title cur-p" @click="showDetail(index)">{{
                          item.name
                        }}</a>
                        <div class="sj_tooltip" v-show="item.detail">
                          <div class="cont">
                            <strong>{{ item.time }}</strong>
                            <ul class="sj_list">
                              <li
                                class="cur-p"
                                v-for="(article, sub_index) in msgs[index + 1]"
                                :key="sub_index"
                                v-if="sub_index <= 2"
                                @click="openLink(article.source_url_s)"
                              >
                                {{ article.title }}
                              </li>
                              <li v-if="msgs[index + 1].length < 1">
                                暂无数据
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="clear"></div>
                    <span class="bd">|</span>
                  </li>
                </ul>
                <div class="clear"></div>
                <div class="mar-t-30"></div>
              </el-card>
            </el-row>
            <!--@end 事件时序图 -->
          <!-- </vue-scroll> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { eventSequenceData } from '@/api/message';
import { globalGetEcharts, globalCreateChart } from '@/utils/helpers';
export default {
  data() {
    return {
      visible: false,
      loading: false,
      data: [],
      msgs: [],
      name: ''
    };
  },
  mounted() {
    this.$nextTick(() => {
      const _this = this;
      window.$('#app-body').click(function(e) {
        if (e.target.className !== 'title') {
          _this.data = _this.data.map(item => {
            item.detail = false;
            return item;
          });
        }
      });
    });
  },
  methods: {
    show(params, name) {
      this.visible = true;
      this.loading = true;
      this.name = name;
      eventSequenceData(params)
        .then(res => {
          if (res.data.state) {
            const { data } = res.data;
            this.msgs = data.map.msgs;
            this.data = data.data.map(item => {
              item.detail = false;
              return item;
            });
            this.$nextTick(() => {
              this.createChart(data.map, 'trend');
            });
            this.loading = false;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(err => {
          console.error(err);
          this.$message.error('获取事件时序失败，服务错误');
        });
    },
    showDetail(index) {
      this.data = this.data.map(item => {
        item.detail = false;
        return item;
      });
      this.data = this.data.map((item, i) => {
        item.detail = index === i ? true : false;
        return item;
      });
    },
    openLink(link) {
      window.open(link, '_blank');
    },
    createChart(map, id) {
      const { keys, data, msgs } = map;
      let echarts = globalGetEcharts();
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: ['事件信息走势'],
          top: '15px',
          itemWidth: 10,
          itemHeight: 10,
          itemGap: 20,
          icon: 'circle'
        },
        toolbox: {
          feature: {
            magicType: { type: ['line', 'bar'] }
          },
          right: '24px',
          top: '10px',
          show: false
        },
        grid: {
          left: '3%',
          right: '4%',
          top: '40px',
          width: '90%',
          bottom: '40px',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: keys
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          }
        ],
        color: ['#238fff', '#ff4f65'],
        series: [
          {
            name: '事件信息走势',
            type: 'line',
            // stack: '总量',
            data: data,
            smooth: true,
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#238fff'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            }
          }
        ]
      };
      let element = window.document.getElementById(id);
      if (!element) return false; // 切换时直接break
      var myChart = globalCreateChart(element, option);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
      myChart.resize();
    }
  }
};
</script>
<style scoped>
.event-sequence .echarts_empty {
  height: 250px;
}
.event-sequence >>> .el-card {
  margin: 20px 20px 0;
  width: calc(100% - 40px);
  border: none;
}
.common-list-dialog
  .mod-title
  >>> .el-breadcrumb__item:first-child
  .el-breadcrumb__inner {
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}
/* 事件时序图 */
.event-sequence-map {
  padding-bottom: 30px;
}
.event-sequence-map li .item-cont {
  line-height: 30px;
  font-size: 12px;
  margin: 0 30px;
}
.event-sequence-map li time,
.event-sequence-map li .icon-dingshi,
.event-sequence-map li a {
  float: left;
}
.event-sequence-map li img {
  margin: 8px 10px 0;
  float: left;
}
.event-sequence-map li .title {
  width:450px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.event-sequence-map li .bd {
  margin-left: 164px;
  color: #707070;
}
.event-sequence-map li:last-child .bd {
  display: none;
}
/* 模仿提示 */
.sj_tooltip {
  position: absolute;
  left: 0;
  z-index: 11;
  bottom: 36px;
}
.sj_tooltip .cont:after {
  content: '';
  position: absolute;
  border-style: solid;
  display: block;
  width: 0;
  bottom: -8px;
  left: 30px;
  border-width: 8px 8px 0;
  border-color: rgba(0, 0, 0, 0.5) transparent;
}
.sj_tooltip .cont {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  color: #fff;
  border-radius: 4px;
  left: 11px;
  z-index: 11;
  top: 11px;
  height: 104px;
  overflow: hidden;
}
.sj_tooltip strong {
  font-size: 14px;
  color: #fff;
}
.sj_tooltip li {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 360px;
  line-height: 18px;
  color: #fff;
}
.event_name {
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.event-sequence .scroll-area {
  height:calc(100% - 10px)
}
</style>
