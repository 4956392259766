<template>
  <el-container>
    <div class="empty" v-if="!loading && data.length < 1">
      <div>
        <img src="@/assets/images/common/empty/img_xssj.svg" alt="暂无数据" width="400" class="img" type="image/svg+xml" />
        <p>暂无数据</p>
      </div>
    </div>
    <div class="mod-list lh-45" v-loading="loading" v-else>
      <ul>
        <li>
          <h3>
            <span class="num-tit">序号</span>
            <span class="mod-list-title2">事件</span>
            <span class="tag">信息占比</span>
            <span class="tag">平均重要度</span>
            <span class="tag">事件时序</span>
            <span class="tag">疑似源头</span>
          </h3>
        </li>
        <li v-for="(item, index) in data" :key="index">
          <span class="num bg-555" v-if="index == 0">1</span>
          <span class="num bg-689" v-else-if="index == 1">2</span>
          <span class="num bg-8ea" v-else-if="index == 2">3</span>
          <span class="num" v-else-if="index >= 3">{{ index + 1 }}</span>
          <a class="mod-list-title2" :title="item.name" @click="showRelat(item.event_id, item.name)">
            <span>{{item.name}}</span>
            <i class="iconfont icon-minganbiaoqian hide"></i>
          </a>
          <span class="tag">{{ item.percent }}%</span>
          <span class="tag">{{ item.influence }}</span>
          <span class="tag">
            <i class="iconfont icon-shixuyuce" @click="showEvent(item)"></i>
          </span>
          <span class="tag">
            <i class="iconfont icon-yisiyuantou" @click="showSource(item.event_id, item.name)"></i>
          </span>
        </li>
      </ul>
    </div>
    <relat-infor ref="infor" />
    <event-sequence ref="event" />
    <suspected-source ref="source" />
  </el-container>
</template>
<script>
  import suspectedSource from "@components/common/suspected-source.vue";
  import eventSequence from "@components/common/event-sequence";
  import { eventParse } from "@/api/message";
  import relatInfor from "@components/common/relat-infor";
  export default {
    name: 'yqxx-analysis',
    components: {
      suspectedSource,
      eventSequence,
      relatInfor
    },
    data() {
      return {
        params: {},
        data: [],
        loading: false
      }
    },
    methods: {
      loadData(params) {
        this.params = params;
        this.getData();
      },
      getData() {
        const {plan_id, span, focus} = this.params;
        this.loading = true;
        eventParse({plan_id, span, focus}).then(res => {
          if (res.data.state) {
            const {data} = res.data;
            this.data = data;
            this.loading = false;
          } else {
            this.$message.error(res.data.error)
          }
          // 通知父组件加载完成
          this.$emit('done', undefined);
        }).catch(() => {
          this.$message.error('分析失败，服务错误');
          // 通知父组件加载完成
          this.$emit('done', undefined);
        })
      },
      showEvent(item) {
        const {span} = this.params;
        this.$refs.event.show({event_id: item.event_id, span}, item.name);
      },
      showSource(eid, name) {
        this.$refs.source.requestListData(`${window.service.api}/message/event_source`, {event_id: eid, name: name, span: this.params.span});
      },
      showRelat(eid, name) {
        this.$refs.infor.requestListData(`${window.service.api}/message/event_info`, { event_id: eid, name });
      }
    }
  }
</script>
<style scoped>
</style>
