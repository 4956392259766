<template>
  <el-container>
    <el-aside class="management">
      <el-autocomplete class="el-input" v-model="checkedPlan" :fetch-suggestions="querySearch" placeholder="请输入内容" suffix-icon="el-icon-search" @select="handleSelect">
        <template slot-scope="{ item }">
          <span class="addr">{{ item.name }}</span>
        </template>
      </el-autocomplete>
      <div class="management-height">
        <vue-scroll>
          <div class="mar-t-10" v-loading="planLoading">
            <div v-for="(group, index) in planList" :key="index">
              <div :class="{'plan-classify': true, 'flex': true, 'space-between': true, 'cur-p': true, 'selected': existsGroup(group)}" @click="group.fold = !group.fold">
                <span class="flex-1">{{ group.username }}</span>
                <i class="el-icon-arrow-right" v-show="group.fold" />
                <i class="el-icon-arrow-down" v-show="!group.fold" />
              </div>
              <ul class="list" v-show="!group.fold">
                <li v-for="(plan, index) in group.plans" :key="index" :class="where.plan_id === plan.pid ? 'active' : ''">
                  <var class="bd"></var>
                  <span class="plan-name" @click="selectedPlan(plan)">{{ plan.name }}</span>
                  <dl>
                    <dd>
                      <el-dropdown trigger="click">
                        <span class="el-dropdown-link">
                          <i class="iconfont icon-gengduoda"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item><span @click="mapping(plan.pid,plan.name)" class="pad-lr-10">方案大屏</span></el-dropdown-item>
                          <el-dropdown-item><span @click="topPlan(plan)" class="pad-lr-10">置顶方案</span></el-dropdown-item>
                          <el-dropdown-item><span @click="delPlan(plan)" class="pad-lr-10">删除方案</span></el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </dd>
                    <dd>
                      <i class="iconfont icon-bianji" @click="editedPlan(plan)"></i>
                    </dd>
                  </dl>
                  <!-- <i class="iconfont icon-bianji hide" @click="editedPlan(plan)"></i> -->
                </li>
              </ul>
            </div>
          </div>
          <!-- 没有找到相应方案 -->
          <div v-show="planList.length < 1" class="zsy-wh-100">
            <div class="myjg-box flex">
              <div class="myjg">
                <img src="@/assets/images/common/empty/sswjg.svg" alt="没有找到相应方案" width="70">
                <p class="mar-t-10" v-if="checkedPlan.length >= 1">没有找到该方案～</p>
                <p class="mar-t-10" v-else>目前还没有方案，<br />快点击下方按钮新建一个吧～</p>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
      <div class="fix-button">
        <div class="button-group">
          <el-button type="primary" plain icon="iconfont icon-jia" @click="addPlan">新建方案</el-button>
          <el-button round icon="iconfont icon-yulan" @click="planOverview">方案总览</el-button>
        </div>
      </div>
    </el-aside>
    <div class="yj_cont">
      <el-card class="box-card caution-title">
        <div class="mod-title" v-if="currentPlan">
          <span>{{ currentPlan.name }}</span>
          <var :class="{'cor-blue': showCautionIcon(currentPlan.pid), 'cur-p': true}" @click="editdCaution(currentPlan)">
            <i class="iconfont icon-yujing"></i>预警
          </var>
        </div>
      </el-card>
      <!-- 表单 -->
      <el-card class="box-card yq-form-box mar-t-20 ov-v demo-form-inline">
        <el-form ref="form" :model="where" label-width="80px">
          <el-form-item label="分析时间">
              <el-radio-group v-model="where.time_type" @change="timeTypeChange">
                <el-radio label="td">今天</el-radio>
                <el-radio label="24h">24小时</el-radio>
                <el-radio label="3day">3天</el-radio>
                <el-radio label="7day">7天</el-radio>
                <el-radio label="custom">自定义</el-radio>
                <el-date-picker
                        v-show="where.time_type === 'custom'"
                        v-model="where.dateSpan"
                        @change="dateSpanChange"
                        :clearable="false"
                        :disabled="loading"
                        :picker-options="{disabledDate: dateRange}"
                        :editable="false"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="['00:00:00', '23:59:59']"
                        align="right" class="mar-r-30">
              </el-date-picker>
            </el-radio-group>
            <span @click="highFilter = !highFilter" class="f-r more-option">{{highFilter?"收起":"更多选项"}}<i class="el-icon--right mar-l-10" :class="highFilter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></span>
          </el-form-item>
        </el-form>
        <el-form ref="form" :model="where" label-width="80px" v-if="highFilter" style="margin-top:10px;">
          <el-form-item label="信息等级" class="demo-form-inline w-50">
            <el-radio-group v-model="where.msg_level">
              <el-radio label="all">全部</el-radio>
              <el-radio label="normal">精准信息</el-radio>
              <el-radio label="garbage">噪音信息</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="排序方式" class="demo-form-inline w-50">
            <el-select v-model="where.order">
              <el-option :label="opt.label" :value="opt.value" v-for="(opt, index) in orderOptions" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="相似合并" class="demo-form-inline w-50">
            <el-radio-group v-model="where.similar">
              <el-radio label="no">不合并</el-radio>
              <el-radio label="yes">合并</el-radio>
            </el-radio-group>
            <el-tooltip class="item" effect="dark" content="提供时间跨度为31天及以内的相似合并" placement="bottom">
              <i class="iconfont icon-tishi" style="position: relative;left: -15px;"></i>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="情感" class="demo-form-inline w-50">
            <el-radio v-model="where.emotion" label="all">全部</el-radio>
            <el-radio v-model="where.emotion" label="negative">敏感</el-radio>
            <el-radio v-model="where.emotion" label="positive">非敏感</el-radio>
          </el-form-item>
          <el-form-item label="信息匹配" class="demo-form-inline w-50">
            <el-radio-group v-model="where.field">
              <el-radio label="content">全文匹配</el-radio>
              <el-radio label="title">标题匹配</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="定向信源" class="dxxy demo-form-inline w-50">
            <el-radio-group v-model="where.source_focus_type">
              <el-radio label="all">全部</el-radio>
              <el-radio label="include">定向监测</el-radio>
              <div :class="{'w-120': true, 'mar-r-20': true, 'pos-r': true, 'f-l': true, selected: sourceFocus.include.show}" v-show="where.source_focus_type === 'include'">
                <el-input
                        class="w-120"
                        placeholder="请选择"
                        suffix-icon="el-icon-arrow-down"
                        :readonly="true"
                        v-model="sourceFocus.include.name"
                        @focus="sourceFocus.include.show = true"
                        @blur="sourceBlur('include')" />
                <div class="dxxy-cont" v-if="sourceFocus.include.show">
                  <ul>
                    <li v-for="(item, index) in sourceIncludeList" :key="index" class="selected" @click="selectedSource(item, 'include')">
                      <span>{{ item.label }}</span>
                      <i class="iconfont icon-bianji cur-p" @click="editSourcePlan(item.value)"></i>
                    </li>
                  </ul>
                  <p class="add" @click="createSource('include')">
                    <i class="iconfont icon-zengjia f-l"></i>
                    <span>新增信源方案</span>
                  </p>
                </div>
              </div>

              <el-radio label="exclude">定向排除</el-radio>
              <div :class="{'w-120': true, 'mar-r-20': true, 'pos-r': true, 'f-l': true, selected: sourceFocus.exclude.show}" v-show="where.source_focus_type === 'exclude'">
                <el-input class="w-120" placeholder="请选择" suffix-icon="el-icon-arrow-down" :readonly="true" v-model="sourceFocus.exclude.name" @focus="sourceFocus.exclude.show = true" @blur="sourceBlur('exclude')">
                </el-input>
                <div class="dxxy-cont" v-if="sourceFocus.exclude.show">
                  <ul>
                    <li v-for="(item, index) in sourceExcludeList" :key="index" class="selected" @click="selectedSource(item, 'exclude')">
                      <span>{{ item.label }}</span>
                      <i class="iconfont icon-bianji cur-p" @click="editSourcePlan(item.value)"></i>
                    </li>
                  </ul>
                  <p class="add" @click="createSource('exclude')">
                    <i class="iconfont icon-zengjia f-l"></i>
                    <span>新增信源方案</span>
                  </p>
                </div>
              </div>


            </el-radio-group>
          </el-form-item>
          <el-form-item label="信息地区" class="demo-form-inline w-50">
            <el-radio-group v-model="where.area" class="mar-r-10">
              <el-radio label="all">全部</el-radio>
              <el-radio label="cn">境内</el-radio>
              <el-radio label="other">境外</el-radio>
              <el-radio label="customer">自定义</el-radio>
            </el-radio-group>
            <el-cascader
                v-model="customerArea"
                :options="provinceOptions"
                @change="handleCityChange"
                v-if="where.area === 'customer'" />
          </el-form-item>
          <el-form-item label="语言" class="demo-form-inline w-50">
            <el-radio-group v-model="where.lang">
              <el-radio label="all">全部</el-radio>
              <el-radio label="zh">中文</el-radio>
              <el-radio label="en">其他</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="微博筛选">
            <span class="mar-r-10 fz-12">微博类型</span>
            <el-select v-model="where.weibo_forward" placeholder="活动区域" class="mar-r-20">
              <el-option label="不限" value="all"></el-option>
              <el-option label="转发" value="yes"></el-option>
              <el-option label="原创" value="no"></el-option>
            </el-select>
            <span class="mar-r-10 fz-12">微博认证</span>
            <el-select v-model="where.weibo_usertype" placeholder="活动区域">
              <el-option label="不限" value="all"></el-option>
              <el-option label="普通用户" value="普通用户"></el-option>
              <el-option label="黄V" value="黄V"></el-option>
              <el-option label="金V" value="金V"></el-option>
              <el-option label="蓝V" value="蓝V"></el-option>
              <el-option label="达人" value="达人"></el-option>
              <el-option label="微博女郎" value="微博女郎"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="hr mar-t-20">
          <span class="line"></span>
        </div>
        <div class="mar-t-20 flex mtlx">
          <span class="tag-name mar-r-20">媒体类型</span>
          <div class="flex flex-1">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkedMediaAll" @change="handleCheckAllMedia" class="mar-r-20">
              {{ mediaFormat('全部') }}
            </el-checkbox>
            <el-checkbox-group v-model="where.media" @change="handleCheckedMediaChange">
              <el-checkbox v-for="(media, index) in medias" :label="media" :key="index">
                {{ mediaFormat(media) }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="button-group">
          <el-button type="primary" round @click="doQuery()" v-loading="loading" :disabled="loading">
            查询
          </el-button>
          <el-button round @click="updateCondition" v-loading="conditionLoading">
            保存设定
          </el-button>
        </div>
      </el-card>
      <!--@end 表单 -->
      <el-card class="box-card mar-t-20 ov-v">
        <el-tabs v-model="activeName" @tab-click="switchTab">
          <el-tab-pane label="信息列表" name="list">
            <xx-list ref="list" v-if="routerAlive.list" :orderOptions="orderOptions" :buildBrief="true" @exportData="exportData" @generateBrief="generateBrief()" @done="loadDataDone" />
          </el-tab-pane>
          <el-tab-pane label="可视化分析" name="statistics">
            <yq-statistics ref="statistics" v-if="routerAlive.statistics" @done="loadDataDone" />
          </el-tab-pane>
          <el-tab-pane label="事件分析" name="analysis">
            <yq-analysis ref="analysis" v-if="routerAlive.analysis" @done="loadDataDone" />
          </el-tab-pane>
        </el-tabs>
      </el-card>
      <!-- 保存简报设定 -->
      <el-dialog :visible.sync="setupBrief.dialogVisible" class="Dialog_style1 save_briefing_settings" :close-on-click-modal="false">
        <div slot="title" class="mod-title">保存简报设定</div>
        <div class="cont">
          <el-input v-model="setupBrief.name" class="w-280" placeholder="请输入简报设定方案名称" maxlength="10" show-word-limit>
          </el-input>
        </div>
        <div class="button-group">
          <el-button type="primary" round v-loading="setupBrief.loading" @click="handleSetupBrief">确定</el-button>
          <el-button round @click="setupBrief.dialogVisible = false">取消</el-button>
        </div>
      </el-dialog>
      <!--!@end 保存简报设定 -->

      <plan-drawer ref="planDrawer" @updateDone="updatePlanDone" @createDone="init" />
      <caution-drawer ref="cautionDrawer" @done="loadCautionList()" />
      <generate-brief-dialog ref="brief" v-on:submit="buildBrief" />
    </div>
    <p class="jszc_text" v-if="version === 'shwj'">技术支持：上海索思数据科技有限公司 </p>
    <!--!@start 删除方案 -->
    <confirm ref="confirmDel" title="删除方案" confirm="删除" @must="confirmDel" />
    <!--!@end 删除方案 -->
    <before-query ref="beforeQuery" @query="loadData" />
    <beginner-guidance ref="beginner" @createPlan="addPlan" />
    <system-notify ref="notify" @closed="notifyClose" />
  </el-container>
</template>
<script>
import yqAnalysis from '@components/yq/tabs/analysis.vue'
import yqStatistics from '@components/yq/tabs/statistics.vue'
import xxList from '@components/common/list.vue'
import planDrawer from '@components/common/plan-drawer.vue'
import cautionDrawer from '@components/common/caution-drawer.vue'
import generateBriefDialog from '@components/common/generate-brief.vue'
import beforeQuery from '@components/common/before-query.vue'
import confirm from '@components/common/confirm.vue'
import systemNotify from '@components/common/system-notify.vue'
import { mapGetters } from 'vuex'
import { saveSetup } from '@/api/briefing'
import { warningList } from '@/api/caution'
import { toping, delPlan as delPlanReq, getGroupPlans, condition } from '@/api/plan'
import { orderOptions, pickerOptions, provinceOptions } from '@/utils/constants'
import { generateBriefing as generateBriefingReq } from '@/api/message'
import {
  dateRangeDisabled,
  sourceFocusId,
  globalFormatDate,
  getAuthToken,
  queryString,
  numberFormat,
  handleDownload
} from '@/utils/helpers'
import beginnerGuidance from '@components/common/beginner-guidance.vue'
const _ = window._
export default {
  name: 'yqxx',
  components: {
    yqAnalysis,
    yqStatistics,
    xxList,
    confirm,
    planDrawer,
    cautionDrawer,
    beforeQuery,
    generateBriefDialog,
    beginnerGuidance,
    systemNotify
  },
  computed: {
    ...mapGetters([
      'user',
      'bundle',
      'plans',
      'medias',
      'province',
      'yqFilter',
      'sourceExcludeList', // 定向信源 定向监测列表
      'sourceIncludeList', // 定向信源 定向排除列表
      'orientationSourceList'
    ])
  },
  data() {
    return {
      version: process.env.VUE_APP_VERSION,
      activeName: 'list',
      checkedMediaAll: true,
      isIndeterminate: false,
      limitDialogVisible: false,
      pickerOptions: pickerOptions,
      provinceOptions,
      checkedPlan: '',
      highFilter: false,
      loading: false,
      restaurants: [],
      sourceFocus: {
        include: {
          show: false,
          name: ''
        },
        exclude: {
          show: false,
          name: ''
        },
      },
      customerArea: [], // 自定义区域
      where: {
        plan_id: '',
        order: orderOptions[0].value,
        emotion: 'all',
        lang: 'all',
        media: [],
        area: 'all',
        province: '北京',
        city: '',
        focus: { op: 'and', must: '', not: '' },
        field: 'content',
        similar: 'no',
        dateSpan: '',
        dateSpanString: '',
        weibo_forward: 'all',
        weibo_usertype: 'all',
        author: '',
        msg_level: 'normal',
        time_type: '24h',
        source_focus_type: 'all',
        source_focus_include_id: '', // 定向监测排除 ID,
        source_focus_exclude_id: ''
      },
      routerAlive: { list: true, statistics: true, analysis: true },
      params: {},
      orderOptions: orderOptions,
      setupBrief: { dialogVisible: false, name: '', loading: false },
      cautionList: [],
      cautionListLoading: true,
      statistics: null,
      timeoutFlag: null,
      planList: [], // 展开方案列表
      currentPlan: null,
      planLoading: false,
      conditionLoading: false
    }
  },
  watch: {
    'where.plan_id': function(id) {
      // 变更方案的source设置
      this.planChange(id)
    },
    plans: {
      handler: function() {
        this.groupPlans()
        this.loadCautionList()
      },
      deep: true
    }
  },
  destroyed() {
    if (this.timeoutFlag) {
      clearTimeout(this.timeoutFlag)
    }
  },
  mounted() {
    this.where.media = this.medias
    this.loadCautionList()
    this.$store.dispatch('getSourceList').then(() => {
      this.init()
    })

    this.$nextTick(() => {
      if (this.user.firstLogin) {
        this.$refs.notify.show()
      }

      if (this.$route.params.hasOwnProperty('createPlan') && this.$route.params.createPlan) {
        const type = this.$route.params.hasOwnProperty('type') ? this.$route.params.type : 'js'
        this.addPlan(type)
      }
    })
  },
  methods: {
    handleCityChange(v) {
      if (v && v.length >= 2) {
        Object.assign(this.where, {
          province: v[0],
          city: v[0] !== v[1] ? v[1] : ''
        })
      }
    },
    notifyClose() {
      if (this.user.firstLogin && this.bundle.plans.remain > 0) {
        this.$refs.beginner.show()
      }
    },
    groupPlans() {
      this.planLoading = true
      getGroupPlans({ enable: 'enable', type: 'expr' })
        .then(res => {
          if (res.data.state) {
            const { data } = res.data
            const list = data.map(item => {
              item.fold = false
              return item
            })
            this.planList = list
            this.restaurants = list
            this.planLoading = false
          } else {
            this.$message.error(res.data.error)
          }
        })
        .catch(e => {
          this.$message.error('加载方案失败，服务错误')
          window.console.error(e)
        })
    },
    init(plan_id = undefined) {
      if (_.size(this.plans) < 1) {
        this.$message.warning('当前用户没有方案')
        return false
      }

      let where = JSON.parse(JSON.stringify(this.where))

      if (plan_id === undefined) {
        // 选中地址栏plan_id
        const queryId =
          this.$route.query.hasOwnProperty('id') && this.$route.query.id
            ? parseInt(this.$route.query.id)
            : null
        var cachePid = this.yqFilter.plan_id
        var pid = null

        if (!cachePid) {
          cachePid = queryId
        }

        if (cachePid) {
          _(this.plans).forEach(plan => {
            if (plan.pid == cachePid) {
              pid = cachePid
              return false
            }
          })
        }

        const selfPlan = this.plans.filter(plan => {
          return plan.createdby === this.user.id
        })
        const defaultPlan = selfPlan.length >= 1 ? selfPlan[0] : this.plans[0]
        where.plan_id = pid ? pid : defaultPlan.pid
      } else {
        where.plan_id = plan_id
      }

      if (this.yqFilter.span) {
        const span = this.yqFilter.span.split('~')
        where.dateSpan = span
        where.dateSpanString = `${span[0]}~${span[1]}`
        where.time_type = 'custom'
      } else {
        // 默认24小时
        const span = this.timeTypeChange('24h')
        where.time_type = '24h'
        where.dateSpan = span
        where.dateSpanString = `${span[0]}~${span[1]}`

        this.$store.commit('SET_YQ_FILTER', {
          key: 'span',
          value: `${span[0]}~${span[1]}`
        })
      }

      this.where = where
      this.groupPlans()
    },
    timeTypeChange(type) {
      if (['td', '24h', '3day', '7day'].indexOf(type) === -1) {
        return false
      }

      this.where.time_type = type
      const start = new Date()
      const end = new Date()
      let date
      switch (type) {
        case 'td':
          const y = start.getFullYear()
          const m = start.getMonth() + 1
          const d = start.getDate()
          date = [`${y}-${m}-${d} 00:00:00`, window.$moment(end.getTime()).format('YYYY-MM-DD HH:mm:ss')]
          break
        case '24h':
          start.setTime(start.getTime() - 3600 * 1000 * 24)
          date = [window.$moment(start.getTime()).format('YYYY-MM-DD HH:mm:ss'), window.$moment(end.getTime()).format('YYYY-MM-DD HH:mm:ss')]
          break
        case '3day':
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 3)
          date = [window.$moment(start.getTime()).format('YYYY-MM-DD HH:mm:ss'), window.$moment(end.getTime()).format('YYYY-MM-DD HH:mm:ss')]
          break
        case '7day':
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          date = [window.$moment(start.getTime()).format('YYYY-MM-DD HH:mm:ss'), window.$moment(end.getTime()).format('YYYY-MM-DD HH:mm:ss')]
          break
      }
      this.where.dateSpan = date
      this.dateSpanChange(date)

      return  date
    },
    updateCondition() {
      const where = JSON.parse(JSON.stringify(this.where))
      delete where.plan_id
      delete where.dateSpan
      delete where.dateSpanString
      where.source_focus = sourceFocusId(where)

      this.conditionLoading = true
      condition({ plan_id: this.where.plan_id, list_condition: JSON.stringify(where) })
        .then(res => {
          if (res.data.state) {
            this.$message.success('保存成功')
          } else {
            this.$message.error(res.data.error)
          }
          this.conditionLoading = false
        })
        .catch(e => {
          window.console.error(e)
          this.conditionLoading = false
          this.$message.error('保存失败，服务错误')
        })
    },
    selectedPlan(plan) {
      if (this.loading) {
        this.$message.warning('请等待当前数据加载完成...')
        return false
      }
      window.open(`/yq?id=${plan.pid}`, '_blank')
    },
    createSource(type) {
      if (['include', 'exclude'].indexOf(type) === -1) {
        return false
      }

      this.$router.push({ name: `orientation-${type}` })
    },
    sourceBlur(type) {
      if (['include', 'exclude'].indexOf(type) === -1) {
        return false
      }

      setTimeout(() => {
        this.sourceFocus[type].show = false
      }, 200)
    },
    editSourcePlan(id) {
      const plan = _.find(this.orientationSourceList, { id: id })

      if (plan !== undefined) {
        this.$router.push({ name: 'orientation-update', params: { data: JSON.stringify(plan) } })
      }
    },
    selectedSource(source, type) {
      if (['include', 'exclude'].indexOf(type) === -1) {
        return false
      }

      if (type === 'include') {
        this.where.source_focus_include_id = source.value
        this.sourceFocus.include.name = source.label
      } else {
        this.where.source_focus_exclude_id = source.value
        this.sourceFocus.exclude.name = source.label
      }
    },
    updatePlanDone(plan) {
      this.$refs.list.currentPage = 1
      if (plan.plan_id === this.currentPlan.pid) {
        this.currentPlan = _.find(this.plans, item => {
          return plan.plan_id === item.pid
        })
        this.loadData()
      } else {
        this.init(plan.plan_id)
      }
    },
    planChange(id) {
      const _this = this
      _(this.plans).forEach(item => {
        if (item.pid == id) {
          // 初始化筛选条件为保存条件
          let source_focus = null
          if (item.list_condition) {
            const condition = JSON.parse(item.list_condition)
            Object.assign(_this.where, condition)
            if (condition.source_focus) {
              source_focus = condition.source_focus
            } else {
              source_focus = item.source_focus
            }
          } else {
            source_focus = item.source_focus
          }

          if (source_focus) {
            let exclude = _.find(_this.sourceExcludeList, { value: source_focus })
            let include = _.find(_this.sourceIncludeList, { value: source_focus })

            if (exclude !== undefined) {
              _this.where.source_focus_type = 'exclude'
              _this.where.source_focus_include_id = ''
              _this.where.source_focus_exclude_id = source_focus
              _this.sourceFocus.exclude.name = exclude.label
            } else if (include !== undefined) {
              _this.where.source_focus_type = 'include'
              _this.where.source_focus_include_id = source_focus
              _this.where.source_focus_exclude_id = ''
              _this.sourceFocus.include.name = include.label
            }
          } else {
            // 初始化定向信源选择
            _this.where.source_focus_type = 'all'
            _this.where.source_focus_include_id = ''
            _this.where.source_focus_exclude_id = ''
            _this.sourceFocus.include.name = ''
            _this.sourceFocus.exclude.name = ''
          }
          _this.$store.commit('SET_YQ_FILTER', { key: 'plan_id', value: id })
          _this.currentPlan = item
          window.document.title = item.name
          this.loadData()
          return false
        }
      })
    },
    calculationParams() {
      const {
        plan_id,
        emotion,
        order,
        lang,
        media,
        area,
        province,
        city,
        focus,
        field,
        similar,
        dateSpanString,
        weibo_forward,
        weibo_usertype,
        source_focus_type,
        author,
        msg_level
      } = this.where
      if (media.length < 1) {
        this.$message.warning('媒体类型不能为空，请选择媒体类型')
        return false
      }

      if (dateSpanString.length < 1) {
        this.$message.warning('分析时间不能为空')
        return false
      }

      if (source_focus_type !== 'all' && sourceFocusId(this.where) === null) {
        this.$message.warning('请先选择定向方案，可在定向监测管理中设置新的定向方案')
        return false
      }

      const params = {
        plan_id,
        emotion,
        order,
        lang,
        media: media.join(','),
        focus,
        field,
        similar,
        span: dateSpanString,
        weibo_forward,
        weibo_usertype,
        author,
        msg_level,
        city
      }
      if (sourceFocusId(this.where)) params.source_focus = sourceFocusId(this.where)

      const span = dateSpanString.split('~')
      const end = new Date(span[1]).getTime()
      const begin = new Date(span[0]).getTime()
      const days = Math.ceil((end - begin) / (86400 * 1000))

      if (days > 31 && similar !== 'no') {
        // 31日阀值 超过禁止合并查询
        params.similar = 'no'
        this.where.similar = 'no'
        this.$message.warning('仅提供时间跨度为31天及以内的相似合并')
        return false
      }

      params.area = area === 'customer' ? province : area
      this.params = params
      return params
    },
    loadCautionList() {
      this.cautionListLoading = true
      warningList({ enable: 'all', type: 'expr' })
        .then(res => {
          if (res.data.state) {
            this.cautionList = res.data.data
            this.cautionListLoading = false
          } else {
            this.$message.error(res.data.error)
          }
        })
        .catch(err => {
          this.$message.error(err)
        })
    },
    showCautionIcon(pid) {
      const plan = _.find(this.cautionList, o => {
        return o.plan_id === pid
      })
      if (plan === undefined) return false

      return plan.notify ? true : false
    },
    doQuery() {
      const res = this.calculationParams()
      if (!res) {
        return false
      }

      this.$refs['beforeQuery'].check(this.params.span)
    },
    loadData() {
      const res = this.calculationParams()
      if (!res) {
        return false
      }
      const { params, activeName } = this
      const index = activeName
      this.routerAlive[index] = false

      this.$nextTick(() => {
        this.routerAlive[index] = true

        this.loading = true
        this.$nextTick(() => {
          if (index === 'list') {
            const args = JSON.parse(JSON.stringify(params))
            args.merge = args.similar === 'no' ? 'all' : 'yes'
            delete args.similar
            this.$refs[index].loadData(`${window.service.api}/message/docs`, args, true, 'yqList')
            return false
          }
          this.$refs[index].loadData(params)
        })
      })
    },
    loadDataDone(data, params = {}) {
      if (data !== undefined) {
        this.statistics = data.statistics
      }
      Object.assign(this.params, params)
      this.loading = false
    },
    mediaFormat(media) {
      if (!this.statistics) {
        return `${media}(0)`
      }

      if (media === '全部') {
        let total = _.sumBy(this.statistics, 'num')
        return `全部(${numberFormat(total)})`
      }

      let row = _.find(this.statistics, o => {
        return o.media === media
      })
      let rowTotal = row === undefined ? 0 : numberFormat(row.num)

      return `${media}(${rowTotal})`
    },
    addPlan(type = 'js') {
      type = ['js', 'zj'].indexOf(type) === -1 ? 'js' : type
      this.$refs.planDrawer.newPlanSucc = false
      this.$refs.planDrawer.add(type)
    },
    delPlan(plan) {
      let textBox = [
        { type: 'error', text: '您将不能再收到此方案的预警信息' },
        { type: 'success', text: '使用此方案数据生成历史报告将会被保留' }
      ]
      this.$refs.confirmDel.show(textBox, { plan_id: plan.pid })
    },
    confirmDel(data) {
      delPlanReq(data)
        .then(res => {
          if (res.data.state) {
            this.$message.success('删除方案成功')
            if (this.where.plan_id == data.plan_id) {
              this.yqFilter.plan_id = null
              this.$store.dispatch('planList').then(() => {
                this.init()
              })
            }
            this.$store.dispatch('planList')
          } else {
            this.$message.error(res.data.error)
          }
        })
        .catch(err => {
          window.console.error(err)
        })
    },
    topPlan(plan) {
      // this.$message(`“${plan.name}”方案置顶中`)
      let topP = _.maxBy(this.plans, o => {
        return o.top
      })
      toping({ id: plan.pid, top: topP.top + 1 })
        .then(res => {
          if (res.data.state) {
            this.$message.success('置顶成功')
            this.$store.dispatch('planList')
          } else {
            this.$message.error(res.data.error)
          }
        })
        .catch(err => {
          window.console.error(err)
        })
    },
    editedPlan(plan) {
      this.$refs.planDrawer.edited(plan)
    },
    editdCaution(plan) {
      const { pid } = plan

      if (this.cautionListLoading) {
        this.$message.warning('请等待预警列表加载完毕...')
        return false
      }

      const cautionPlan = _.find(this.cautionList, plan => {
        return plan.plan_id === pid
      })
      if (cautionPlan === undefined) {
        this.$message.warning('找不到该方案预警')
        return false
      }

      this.$router.push({ name: 'caution', params: { pid } })
      // this.$refs.cautionDrawer.show(cautionPlan);
    },
    // 保存至简报设定
    handleSetupBrief() {
      const {
        area,
        author,
        dateSpanString,
        emotion,
        field,
        focus,
        lang,
        media,
        plan_id,
        province,
        source_focus_type,
        source_focus_include_id,
        msg_level,
        source_focus_exclude_id,
        weibo_forward,
        weibo_usertype
      } = this.where
      const obj = {
        name: this.setupBrief.name,
        content: {
          plan_id,
          span_type: 'static',
          span_value: dateSpanString,
          emotion,
          media: media.join(','),
          focus,
          field,
          area_cn: area,
          area_customer: area === 'customer' ? province : '',
          weibo_forward,
          weibo_usertype,
          lang,
          author,
          msg_level
        }
      }
      if (source_focus_type === 'include' && source_focus_include_id) {
        obj.content.source_focus = source_focus_include_id
      } else if (source_focus_type === 'exclude' && source_focus_exclude_id) {
        obj.content.source_focus = source_focus_exclude_id
      }
      if (this.setupBrief.name.trim().length < 1 || this.setupBrief.name.trim().length > 10) {
        this.$message.warning('简报设定名称1~10个字符')
        return false
      }
      this.setupBrief.loading = true
      saveSetup(obj).then(res => {
        const { state, error } = res.data
        if (state) {
          this.$message({
            type: 'success',
            message: '保存成功，稍后请到简报设定列表中查看'
          })
          this.setupBrief.dialogVisible = false
          this.setupBrief.loading = false
          this.setupBrief.name = ''
        } else {
          this.$message({
            message: error || '未知异常，请重试！',
            type: 'error'
          })
        }
      })
    },
    generateBrief() {
      const span = this.params.span.split('~')
      const begin = new Date(window.$moment(span[0]).valueOf())
      const end = new Date(window.$moment(span[1]).valueOf())
      const diff = end.getTime() - begin.getTime()
      const show = Math.abs(diff - 86400000) <= 1000

      this.$refs.brief.show(show)
    },
    buildBrief(data) {
      const { name, type } = data
      const params = JSON.parse(JSON.stringify(this.params))
      params.name = name
      params.op = 'briefing'
      params.type = type

      if (type === 'daily_mix' || type === 'daily_common') {
        const span = params.span.split('~')
        const begin = new Date(window.$moment(span[0]).valueOf())
        const end = new Date(window.$moment(span[1]).valueOf())
        const diff = end.getTime() - begin.getTime()
        if (diff > 86400000) {
          this.$message.warning('日报时间范围不能大于一天')
          return false
        }
      }

      Object.assign(params, data)
      generateBriefingReq(params)
        .then(res => {
          this.$refs.brief.loading = false
          if (res.data.state) {
            this.$message.success('简报生成中，跳转至简报查看')
            this.$refs.brief.visible = false
            this.timeoutFlag = setTimeout(() => {
              this.$router.push({ name: 'brief', params: { toHistory: true } })
            }, 2000)
          } else {
            this.$message.error(res.data.error)
          }
        })
        .catch(err => {
          this.$refs.brief.loading = false
          this.$message.error('生成简报错误，服务错误')
        })
    },
    exportData(total) {
      const params = JSON.parse(JSON.stringify(this.params))
      params.op = 'down'
      params.token = getAuthToken()
      params.focus = JSON.stringify(params.focus)
      params.size = total
      const query = decodeURIComponent(queryString(params, true))

      handleDownload(
        `${window.service.api}/message/excel${query}`,
        'GET',
        `${window.$moment().format('YYYY-MM-DD')}.xlsx`,
        null,
        total,
        () => {
          this.$refs['list'].exportLoading = false
        }
      )
    },
    mapping(pid, name) {
      // window.open(`http://v.yuqing.co/plan-screen?plan_id=${pid}`, '_blank')
      window.open(`/plan-screen?plan_id=${pid}&plan_name=${name}`, '_blank')
    },
    planOverview() {
      this.$router.push({ name: 'plan_overview' })
    },
    handleCheckAllMedia(val) {
      this.where.media = val ? this.medias : []
      this.isIndeterminate = false
    },
    handleCheckedMediaChange(value) {
      let checkedCount = value.length
      this.checkedMediaAll = checkedCount === this.medias.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.medias.length
    },
    switchTab() {
      this.routerAlive = { list: false, statistics: false, analysis: false }
      this.loadData()
    },
    handleSelect(item) {
      let plan = _.find(this.plans, plan => {
        return plan.pid === item.value
      })
      if (plan !== undefined) {
        this.where.plan_id = plan.pid
        this.checkedPlan = plan.name
        this.loadData()
      }
    },
    existsGroup(group) {
      const plan = window._.find(group.plans, plan => {
        return plan.pid === this.where.plan_id
      })

      return plan !== undefined
    },
    querySearch(queryString, cb) {
      var restaurants = JSON.parse(JSON.stringify(this.restaurants))
      var results = []
      // 调用 callback 返回建议列表的数据
      if (queryString.length >= 1) {
        let tempres = restaurants.map(group => {
          let plans = group.plans.filter(this.createFilter(queryString))
          if (plans.length >= 1) {
            group.plans = plans

            return group
          }

          return false
        })

        results = tempres.filter(group => {
          return group
        })
      } else {
        results = restaurants
      }

      this.planList = results
      cb([])
    },
    createFilter(queryString) {
      return restaurant => {
        return restaurant.name.toLowerCase().indexOf(queryString.toString().toLowerCase()) !== -1
      }
    },
    dateSpanChange(date) {
      if (date) {
        const diff = Math.round(
          (window.$moment(date[1]).valueOf() - window.$moment(date[0]).valueOf()) /
            (3600 * 1000 * 24)
        )
        if (diff >= 90) {
          // 查询时间超过三个月 默认按照时间倒叙
          this.params.order = 'desc'
        }
        this.where.dateSpanString = `${date[0]}~${date[1]}`
        this.$store.commit('SET_YQ_FILTER', {
          key: 'span',
          value: `${date[0]}~${date[1]}`
        })
      } else {
        this.where.dateSpanString = ''
      }
    },
    dateRange(date) {
      return dateRangeDisabled(date)
    }
  }
}
</script>
<style scoped>
/* 左侧导航 */
.management {
  width: 220px !important;
  background: rgba(249, 250, 254, 1);
  position: fixed;
  left: 0;
  bottom: 0;
  box-shadow: 0px 2px 8px 0px rgba(65, 70, 76, 0.07);
  overflow: hidden;
  top: 60px;
  z-index: 100;
}
.management .el-input {
  width: 190px;
  padding: 15px;
  display: block;
  border-bottom: 1px solid #e5e5e5;
}
.management >>> .el-input--small .el-input__inner {
  border-radius: 15px;
}
.list {
  text-align: left;
  width: 220px;
}
.list li {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  text-align: center;
  color: #333;
  font-weight: 400;
  position: relative;
  width: 100%;
  display: flex;
}
.list .bd {
  width: 4px;
  position: absolute;
  left: 0;
  height: 100%;
  display: none;
}
.list .el-badge {
  display: inline;
}
.list .el-badge__content.is-fixed.is-dot {
  left: 25px;
  top: 10px;
}
/* .list .icon-yujing{position: absolute;left: 15px;top: 0;} */
.list dl dd {
  float: right;
  margin-right: 10px;
  display: none;
}
.list dl .iconfont {
  float: right;
}
.list li:hover dd,
.list .active dd {
  display: block;
}
.list .plan-name {
  width: calc(100% - 40px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  margin-left: 40px;
  text-align: left;
}
.list li:hover,
.list .active {
  cursor: pointer;
}
.list .active .bd {
  display: block;
}
.list .active .plan-name,
.list li:hover .plan-name {
  width: calc(100% - 100px);
}
/*  */
.yj_cont {
  margin-top: 80px;
  margin-left: 240px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: calc(100% - 240px);
  min-width: 1080px;
}
.mtlx .tag-name {
  margin-right: 20px;
  font-weight: 600;
  font-size: 12px;
}
.management-height {
  height: calc(100% - 180px);
}
/* 没有结果 */
.myjg-box {
  height: 100%;
  justify-content: center;
  align-items: center;
}
.myjg p {
  line-height: 24px;
  font-size: 12px;
}
/* 方案分类 2020年6月17日16:32:02 */
.plan-classify {
  padding: 0 25px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  width: 170px;
}
.plan-classify span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 80px);
}
.plan-classify i {
  margin-top: 14px;
}
.caution-title .mod-title {
  margin-bottom: 0;
}
.caution-title .mod-title var {
  position: relative;
  padding-left: 40px;
}
.caution-title .icon-yujing {
  margin-right: 10px;
}
/* 表单 */
.yq-form-box {
  padding: 0 40px;
}
.yq-form-box >>> .el-form-item__label {
  font-weight: 600;
}
.yq-form-box .hr {
  height: 12px;
  position: relative;
  width: 100%;
}
.yq-form-box .hr .line {
  height: 1px;
  background: #ebecf0;
  width: 100%;
  position: absolute;
  left: 0;
  top: 6px;
}
.yq-form-box .hr .iconfont {
  position: absolute;
  left: calc(50% - 5px);
  top: 0;
  z-index: 5;
  padding: 0 5px;
  background: #fff;
  cursor: pointer;
}
.yq-form-box >>> .w-50.demo-form-inline {
  width: 50%;
  display: inline-block;
}
.yq-form-box >>> .el-radio {
  margin-right: 25px;
}
/* 2.0.6迭代 信息筛选*/
.yq-form-box .dxxy .selected >>> .el-icon-arrow-down {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.dxxy >>> .el-radio {
  float: left;
  margin-top: 5px;
}
.dxxy-cont {
  position: absolute;
  left: 0;
  top: 35px;
  z-index: 10;
  width: 180px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
  padding: 6px 0;
}
.dxxy-cont ul {
  max-height: 145px;
  overflow: auto;
}
.dxxy-cont li,
.dxxy-cont .add {
  height: 35px;
  line-height: 35px;
  padding: 0 20px;
  display: flex;
}
.dxxy-cont li span,
.dxxy-cont .add span {
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  cursor: pointer;
}
.dxxy-cont li .icon-bianji {
  display: none;
}
.dxxy-cont li:hover {
  background: rgba(62, 71, 255, 0.1);
}
.dxxy-cont li:hover span {
  color: #3e47ff;
}
.dxxy-cont li:hover .icon-bianji {
  display: block;
}
.dxxy-cont li i {
  font-size: 14px;
  line-height: 35px;
}
.dxxy-cont .add {
  border-top: 1px solid #f2f2f2;
}
.more-option{height: 30px;line-height: 30px;font-size: 12px;border-radius: 4px;border: 1px solid #D2D2D2;background: none;padding: 0 10px;cursor: pointer;color: #606266;}
</style>
