<template>
  <div>
    <el-dialog
      :visible.sync="listDialog.visible"
      class="common-big-dialog common-list-dialog suspected-source"
      :close-on-click-modal="false"
    >
      <div slot="title" class="mod-title">
        <el-breadcrumb class="f-l" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :title="eventName" class="cur-p">{{
            eventName
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>疑似源头</el-breadcrumb-item>
        </el-breadcrumb>
        <div
          class="daochu_excel f-r cur-p"
          @click="exportData()"
          v-loading="exportLoading"
        >
          <i class="iconfont icon-excel"></i>
          <el-tooltip
            class="item"
            effect="dark"
            content="全部信息导出至EXCEL（最多5000条）"
            placement="bottom"
          >
            <span>导出excel</span>
          </el-tooltip>
        </div>
        <form id="download_sheet_suspected" method="post" target="_blank">
          <input
            type="hidden"
            name="rowkeys"
            value=""
            id="download_sheet_rowkeys_suspected"
          />
          <input
            type="hidden"
            name="token"
            value=""
            id="download_sheet_token_suspected"
          />
        </form>
      </div>
      <div v-loading="listDialog.loading" class="listDialogVisible h-100">
        <div class="scroll-area">
        <!-- <vue-scroll> -->
          <ul>
            <li
              v-for="(data, index) in listData.list"
              :key="index"
              class="item"
            >
              <el-row type="flex" justify="space-between">
                <el-col :span="20">
                  <h3>
                    <el-button
                      type="primary"
                      class="mg"
                      v-if="data.emotion == 'negative'"
                      >敏感</el-button
                    >
                    <el-button type="primary" class="fmg" v-else
                      >非敏感</el-button
                    >
                    <span
                      class="icon-yuanfabiaoqian"
                      v-if="data.category == '微博' && data.isforward == 0"
                      >原</span
                    >
                    <span
                      class="icon-zhuanfabiaoqian"
                      v-if="data.category == '微博' && data.isforward == 1"
                      >转</span
                    >
                    <a
                      :title="data.title"
                      class="title"
                      @click="showDetail(data.rowkey)"
                      v-html="data.title"
                    ></a>
                  </h3>
                </el-col>
                <el-col :span="4">
                  <ul>
                    <li class="hide">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="屏蔽信息"
                        placement="bottom"
                      >
                        <i class="iconfont icon-pingbi selected"></i>
                      </el-tooltip>
                    </li>
                    <li class="pos-r">
                      <el-tooltip
                        content="加入收藏夹"
                        placement="bottom"
                        effect="dark"
                        :open-delay="500"
                      >
                        <span
                          class="cur-p"
                          @click="
                            data.joinMaterial.visible = !data.joinMaterial
                              .visible
                          "
                        >
                          <a
                            class="iconfont icon-shoucang selected"
                            v-if="data.joinMaterial.visible"
                          ></a>
                          <a class="iconfont icon-shoucang" v-else></a>
                        </span>
                      </el-tooltip>
                      <favorite-dialog
                        :ids="data.rowkey"
                        v-if="data.joinMaterial.visible"
                        @done="data.joinMaterial.visible = false"
                        :modal="false"
                      />
                    </li>
                    <li>
                      <el-tooltip
                        content="信息详情"
                        placement="bottom"
                        effect="dark"
                        :open-delay="500"
                      >
                        <a
                          @click="showDetail(data.rowkey)"
                          class="iconfont icon-xiangqing hover"
                        ></a>
                      </el-tooltip>
                    </li>
                    <li>
                      <el-tooltip
                        content="跳转原文"
                        placement="bottom"
                        effect="dark"
                        :open-delay="500"
                      >
                        <a
                          :href="data.source_url"
                          target="_blank"
                          class="iconfont icon-lianjie hover"
                          @click="target(data.source_url)"
                        ></a>
                      </el-tooltip>
                    </li>
                  </ul>
                </el-col>
              </el-row>
              <p class="summary" v-html="data.description"></p>
              <p class="info">
                <span>媒体类型：{{ data.category }}</span>
                <span
                  >来源：<var
                    class="source_name"
                    v-bind:title="
                      data.category === '微信' ? data.author : data.source_name
                    "
                  >
                    {{
                      data.category === '微信'
                        ? data.author
                        : data.source_name.length > 5
                        ? data.source_name.substr(0, 5) + '...'
                        : data.source_name
                    }}
                  </var>
                </span>
                <span
                  >作者：{{
                    data.author.length >= 1 ? data.author : '--'
                  }}</span
                >
                <span>信息地区：{{ msgArea(data) }}</span>
                <span>发布时间：{{ data.published }}</span>
              </p>
            </li>
          </ul>
        <!-- </vue-scroll> -->
        </div>
      </div>
    </el-dialog>
    <article-detail
      ref="detail"
      :params="listData.params"
      :materials="materialData"
    />
    <generate-brief-dialog ref="input" v-on:submit="indexBuildBrief" />
  </div>
</template>
<script>
import articleDetail from '@components/common/article-detail.vue';
import generateBriefDialog from '@components/common/generate-brief.vue';
import favoriteDialog from '@components/common/favorite-dialog.vue';
import { lists as materialList, add_item } from '@/api/material';
import { eventBriefing } from '@/api/message';
import { getAuthToken, formatMsgArea, handleDownload } from '@/utils/helpers';

export default {
  name: 'dialog-relat-infor',
  props: ['materials'],
  data() {
    return {
      listDialog: {
        visible: false,
        loading: false,
        loadMoreDisabled: false,
        moreLoading: false
      },
      listData: {
        list: [],
        page: { current: 0, size: 0, total: 0 },
        order: 'importance',
        params: {},
        api: null,
        phonyTotal: null
      },
      handleData: false,
      materialData: [],
      entitys: [],
      exportLoading: false,
      eventName: ''
    };
  },
  watch: {
    materials: function(data) {
      this.materialData = data;
    }
  },
  mounted() {},
  components: {
    articleDetail,
    generateBriefDialog,
    favoriteDialog
  },
  methods: {
    requestListData(api, args = null) {
      var params = args;
      if (params) {
        const { event_id, name } = params;
        this.eventName = name;
        this.listData.params = { event_id };
        this.listData.list = [];
        this.listData.api = api;
        this.listData.page.current = 0;
        this.listDialog.loading = true;
        this.listDialog.visible = true;
        this.listDialog.loadMoreDisabled = false;
      } else {
        params = this.listData.params;
      }
      let page = parseInt(this.listData.page.current) + 1;
      params['page'] = page;
      params['order'] = this.listData.order;
      window.$axios
        .get(api, { params: params })
        .then(res => {
          if (res.data.state) {
            const { data } = res.data;
            if (_.size(data) < 1) {
              this.$message.warning('暂无更多');
              this.listDialog.loadMoreDisabled = true;
            }
            let result = data.map(item => {
              item.joinMaterial = { visible: false };
              return item;
            });
            this.listData.list = this.listData.list.concat(result);
            this.listData.page.total = _.size(result);
            this.listDialog.moreLoading = false;
            this.listDialog.loading = false;
            if (!this.materials) this.loadMaterial();
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(err => {
          window.console.error(err);
          this.$message.error('加载数据列表错误');
        });
    },
    msgArea(row) {
      return formatMsgArea(row);
    },
    randomColorClass() {
      let color = ['bd-e2b', 'bd-628', 'bd-c37', 'bd-6e7'];
      let index = _.random(0, _.size(color));
      return color[index];
    },
    showInput() {
      this.$refs.input.show();
    },
    indexBuildBrief(submitObj) {
      const { name, type } = submitObj;
      const { event_id } = this.listData.params;
      if (_.size(name) < 1 || _.size(name) > 10) {
        this.$message.warning('简报名称1~10个字符');
        return false;
      }
      let params = { name, type, event_id };
      this.handleData = false;
      Object.assign(params, submitObj);
      eventBriefing(params)
        .then(res => {
          this.$refs.input.loading = false;
          if (res.data.state) {
            this.$message.success('生成简报成功，请在历史简报清单中下载');
            this.$refs.input.visible = false;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(res => {
          this.$refs.input.loading = false;
          this.$message.error('生成简报错误，服务错误');
        });
    },
    exportData() {
      if (this.exportLoading) {
        return false;
      }
      if (_.size(this.listData.list) < 1) {
        this.$message.warning('暂无数据');
        return false;
      }
      var ids = [];
      this.listData.list.map(row => {
        ids.push(row.rowkey);
      });
      // var formDom = window.document.getElementById('download_sheet_suspected');
      // formDom.action = `${window.service.api}/message/sheet`;
      // var rowkeys = window.document.getElementById('download_sheet_rowkeys_suspected');
      // rowkeys.value = ids.join(',');
      // var token = window.document.getElementById('download_sheet_token_suspected');
      // token.value = getAuthToken();
      // formDom.submit();

      let data = new FormData();
      data.append('rowkeys', ids.join(','));
      data.append('token', getAuthToken());
      handleDownload(
        `${window.service.api}/message/sheet`,
        'POST',
        `${window.$moment().format('YYYY-MM-DD')}.xlsx`,
        data,
        window._.size(ids),
        () => {
          this.exportLoading = false;
        }
      );
    },
    orderChange() {
      this.listData.page.current = 0;
      this.requestListData(this.listData.api, this.listData.params);
    },
    loadMore() {
      this.listDialog.moreLoading = true;
      this.requestListData(this.listData.api);
    },
    addRowMaterialAction(material, id) {
      if (!material.selectedId) {
        this.$message.warning('请选择收藏夹');
        return false;
      }
      material.loading = true;
      add_item({
        id: material.selectedId,
        ids: id
      })
        .then(res => {
          if (res.data.state) {
            this.$message.success('添加成功');
          } else {
            this.$message.error(res.data.error);
          }
          material.loading = false;
          material.visible = false;
        })
        .catch(res => {
          this.$message.error('添加素材失败，服务错误');
        });
    },
    showDetail(rowkey) {
      let pid = this.listData.params.plan_id
        ? this.listData.params.plan_id
        : null;
      this.$refs.detail.showDetail(rowkey, 'article-list', null, pid);
    },
    loadMaterial() {
      materialList()
        .then(res => {
          if (res.data.state) {
            var result = res.data.data;
            this.materialData = result;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(res => {
          this.$message.error('加载素材库失败，服务错误');
        });
    }
  }
};
</script>
<style scoped="scope">
.suspected-source .mod-title {
  overflow: hidden;
  width: calc(100% - 80px);
}
.suspected-source .mod-title >>> .el-breadcrumb {
  margin-top: 2px;
}
.suspected-source
  .mod-title
  >>> .el-breadcrumb__item:first-child
  .el-breadcrumb__inner {
  max-width: 450px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
}
.suspected-source .mod-title .daochu_excel {
  font-weight: 400;
  font-size: 12px;
}
.suspected-source .icon-excel {
  margin-right: 5px;
}
.suspected-source >>> .el-dialog__body {
  padding: 0 40px;
}
.suspected-source .item {
  padding: 20px 20px 10px 0;
  font-size: 12px;
}
.suspected-source .item:not(:last-child) {
  border-bottom: 1px solid #f2f2f2;
}
.item h3 {
  line-height: 24px;
}
.item .title {
  font-weight: 600;
  font-size: 14px;
  width: calc(100% - 200px);
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  text-overflow: ellipsis;
  float: left;
}
.item li {
  float: right;
  margin-left: 10px;
}
.item .summary {
  font-size: 12px;
  line-height: 24px;
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #414141;
  margin-top: 10px;
}
.item .info {
  line-height: 30px;
  font-size: 12px;
  color: #707070;
}
.item .info span {
  margin-right: 20px;
}
.jrsck_dialog {
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 20;
  width: 220px;
}
.jrsck_dialog >>> .el-card__body {
  padding: 20px;
}
.suspected-source .scroll-area {
  height:calc(100% - 10px);
}
/* .listDialogVisible >>> .__vuescroll,.listDialogVisible >>> .__view,.listDialogVisible >>> .__panel __hidebar{overflow: visible !important;} */
.suspected-source .item .icon-pingbi {
  font-size: 16px;
}
</style>
