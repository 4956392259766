<template>
  <el-container>
    <el-dialog
      class="scjb_Dialog Dialog_style1 check_time"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <div slot="title" class="mod-title">{{ title }}</div>
      <div class="cont">
        <ul>
          <li class="flex">
            <img src="@/assets/images/common/icon/red-dot.svg" width="14" />
            <p>{{ description }}</p>
          </li>
        </ul>
      </div>
      <div class="button-group">
        <el-button type="primary" round @click="query()">{{
          buttonName
        }}</el-button>
        <el-button round @click="cancel()">取消</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
import { isHotData } from '@/utils/helpers';
export default {
  name: 'before-query',
  data() {
    return {
      dialogVisible: false,
      title: '',
      description: '',
      buttonName: ''
    };
  },
  methods: {
    check(span) {
      const date =
        Object.prototype.toString.call(span) === '[object Array]'
          ? span
          : span.split('~');
      const beginDate = new Date(
        window.$moment(date[0], 'YYYY-MM-DD HH:mm:ss').valueOf()
      );
      const endDate = new Date(
        window.$moment(date[1], 'YYYY-MM-DD HH:mm:ss').valueOf()
      );

      if (
        endDate.getTime() - beginDate.getTime() <= 3600 * 1000 * 24 * 7 &&
        isHotData(beginDate.getTime()) &&
        isHotData(endDate.getTime())
      ) {
        this.$emit('query');
      } else {
        if (
          (!isHotData(beginDate.getTime()) &&
            !isHotData(endDate.getTime()) &&
            endDate.getMonth() !== beginDate.getMonth()) ||
          endDate.getMonth() - beginDate.getMonth() >= 2
        ) {
          this.title = '查询时间长';
          this.description =
            '查询时间段过长，加载时间可能超过10分钟。建议您分成几个时间段分别查询。';
          this.buttonName = '仍然查询';

          this.dialogVisible = true;
        } else {
          // this.title = '查询时间长'
          // this.description = '查询时间段长，加载时间可能超过10分钟。请您耐心等待。'
          // this.buttonName = '继续查询'
          //
          // this.dialogVisible = true
          this.$emit('query');
        }
      }
    },
    query() {
      this.dialogVisible = false;
      this.$emit('query');
    },
    cancel() {
      this.dialogVisible = false;
      this.$message({
        type: 'info',
        message: '已取消此次查询'
      });
    }
  }
};
</script>
<style scoped>
.check_time .cont {
  height: 180px;
}
.Dialog_style1 li img {
  margin-top: -18px;
}
.check_time .cont ul {
  padding: 0 95px;
}
.check_time .cont p {
  line-height: 20px;
}
</style>
