<template>
  <div class="pos-r imitation-textarea">
    <div class="editable" contenteditable="false">
      <div :id="did" contenteditable="true" ref="editable" @blur="emitInput" @input="changeLength" @keydown="keyDown"></div>
    </div>
    <span class="character" v-if="length < 480">
      <var>{{ length }}</var>/{{ user.plan_words_limit }}
    </span>
    <span class="character" v-else-if="length >= 480 && length <= user.plan_words_limit">
      <var class="cor-e6a">{{ length }}</var>/{{ user.plan_words_limit }}
    </span>
    <span class="character" v-else-if="length > user.plan_words_limit">
      <var class="cor-fc5">{{ length }}</var>/{{ user.plan_words_limit }}
    </span>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
  name: 'mediumeditor',
  data ()
  {
    return {
      length: 0
    }
  },
  props: {
    value: String,
    options: {
      type: Object,
      default: () => ({})
    },
    did: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.editable.innerHTML = this.value
    })
  },
  beforeDestroy () {
  },
  watch: {
    value (newVal, oldVal) {
      if (newVal !== this.$refs.editable.innerHTML) {
        this.$refs.editable.innerHTML = newVal || ''
        this.$refs.editable.blur()
        this.setCursortPosition()
        this.length = this.$refs.editable.innerText.length
      }
    }
  },
  methods: {
    changeLength ()
    {
      this.length = this.$refs.editable.innerText.length
    },
    emitInput ()
    {
      this.$emit('change', this.$refs.editable.innerText)
    },
    keyDown (e)
    {
      if (e.code === 'Enter') {
        // this.$refs.editable.innerHTML = this.$refs.editable.innerHTML.replace(/<div><br><\/div>/, '')
        window.document.getElementById(this.did).blur()
      }
    },
    setCursortPosition ()
    {
      // 设置光标位置
      let el = window.document.getElementById(this.did);
      el.focus();
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      if(sel.anchorOffset!=0){
        return;
      }
      sel.removeAllRanges();
      sel.addRange(range);
    },
    getCursortPosition ()
    {
      var sel = document.getSelection();
      sel.modify("extend", "backward", "paragraphboundary");
      var pos = sel.toString().length;
      if(sel.anchorNode != undefined) sel.collapseToEnd();

      return pos;
    }
  }
}
</script>
<style scoped>
.editable > div{min-height:150px;line-height:22px;border-radius:4px;border:1px solid rgba(210,210,210,1);height: auto;font-size: 12px;padding:5px;}
.imitation-textarea .character{font-size: 12px;color: #575757;position: absolute;bottom: 5px;right: 5px;background:#fff;}
.imitation-textarea .character var{color: #575757;}
.imitation-textarea .character .cor-e6a{color: #e6a23c;}
.imitation-textarea .character .cor-fc5{color: #fc5d73;}
</style>
