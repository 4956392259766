<template>
  <div>
    <!-- 纠错弹出框 -->
    <el-dialog
      class="Dialog_style1 error-correction"
      :visible.sync="detailDialog.visible"
      :close-on-click-modal="false"
    >
      <div slot="title" class="mod-title">纠错</div>
      <div class="error-correction-content">
        <el-row type="flex" justify="space-between">
          <el-col :span="23">
            <h3>
              <el-button
                type="primary"
                size="mini"
                class="mg"
                v-if="row.emotion === 'negative'"
                >敏感</el-button
              >
              <el-button type="primary" size="mini" class="fmg" v-else
                >非敏感</el-button
              >
              <span
                class="iconfont icon-yuanfabiaoqian"
                v-if="row.category == '微博' && row.isforward == 0"
                >原</span
              >
              <span
                class="iconfont icon-zhuanfabiaoqian"
                v-if="row.category == '微博' && row.isforward == 1"
                >转</span
              >
              <a class="title" v-html="row.title"></a>
              <span
                class="zyxx"
                v-if="['A', 'B', 'C'].indexOf(row.level) === -1"
                >噪音信息</span
              >
              <span class="jzxx" v-else>精准信息</span>
            </h3>
          </el-col>
          <el-col :span="1">
            <ul class="ico-list">
              <li>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="查看原文"
                  placement="bottom"
                >
                  <i
                    class="iconfont icon-lianjie"
                    @click="target(row.source_url)"
                  ></i>
                </el-tooltip>
              </li>
            </ul>
          </el-col>
        </el-row>
        <p class="info">
          <span>媒体类型：{{ row.category }}</span>
          <span>
            来源：
            <var
              class="source_name"
              v-bind:title="
                row.category === '微信' ? row.author : row.source_name
              "
            >
              {{ row.category === '微信' ? row.author : row.source_name }}
            </var>
          </span>
          <span v-if="row.author"
            >作者：{{ row.author.length >= 1 ? row.author : '--' }}</span
          >
          <span>信息地区：{{ msgArea(row) }}</span>
          <span>发布时间：{{ row.published }}</span>
        </p>
        <div class="abstract mar-t-10">
          <div class="abstract-cont" v-html="row.content"></div>
        </div>
        <!-- 问题反馈 -->
        <section class="form-item">
          <h3 class="mod-title">问题反馈</h3>
          <div class="form-item-cont mar-t-10">
            <el-checkbox-group v-model="issueList">
              <el-checkbox
                label="误判为敏感信息"
                class="w-100"
                v-if="row.emotion === 'negative'"
                >误判为敏感信息</el-checkbox
              >
              <el-checkbox
                label="误判为非敏感信息"
                class="w-100"
                v-if="row.emotion === 'positive'"
                >误判为非敏感信息</el-checkbox
              >
              <el-checkbox
                label="误判为噪音信息"
                class="w-100"
                v-if="['A', 'B', 'C'].indexOf(row.level) === -1"
                >误判为噪音信息</el-checkbox
              >
              <el-checkbox
                label="误判为精准信息"
                class="w-100"
                v-else-if="['A', 'B', 'C'].indexOf(row.level) !== -1"
                >误判为精准信息</el-checkbox
              >
              <el-checkbox label="内容抓错" class="w-100">内容抓错</el-checkbox>
              <el-checkbox label="发布时间抓错" class="w-100"
                >发布时间抓错</el-checkbox
              >
              <el-checkbox label="网站名称错误" class="w-100"
                >网站名称错误</el-checkbox
              >
              <el-checkbox label="媒体类型错误" class="w-100"
                >媒体类型错误</el-checkbox
              >
              <el-checkbox label="抓取慢" class="w-100">抓取慢</el-checkbox>
              <el-checkbox label="其他" class="mar-r-20">其他</el-checkbox>
              <el-input
                v-model="otherIssue"
                class="w-200"
                placeholder="请填写"
              ></el-input>
            </el-checkbox-group>
          </div>
        </section>
        <!--@end 问题反馈 -->
        <!-- 问题说明 -->
        <section class="form-item">
          <h3 class="mod-title">问题说明</h3>
          <div class="form-item-cont">
            <el-input
              type="textarea"
              v-model="issueText"
              placeholder="详细的说明有助于我们解决您反馈的问题"
            >
            </el-input>
          </div>
        </section>
        <!--@end 问题说明 -->
        <!-- 联系方式 -->
        <section class="form-item">
          <h3 class="mod-title">
            <span>联系方式</span>
            <var
              >（提供联系方式可帮助我们更好地解决问题。您的联系方式将会被严格保密。）</var
            >
          </h3>
          <div class="form-item-cont mar-t-20">
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="QQ">
                <el-input
                  v-model="form.qq"
                  class="w-200"
                  placeholder="填写QQ号码"
                ></el-input>
              </el-form-item>
              <el-form-item label="手机号码">
                <el-input
                  v-model="form.phone"
                  class="w-200"
                  placeholder="填写手机号码"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </section>
        <!--@end 联系方式 -->
      </div>
      <div class="button-group">
        <el-button
          type="primary"
          round
          @click="submit"
          :disabled="disabled"
          v-loading="loading"
          class="submit"
          >提交</el-button
        >
      </div>
    </el-dialog>
    <!--@end 纠错弹出框 -->
  </div>
</template>
<script type="application/javascript">
import { index as messageIndex, docFeedback } from '@/api/message';
import { formatMsgArea, isQQ, isPhone } from '@/utils/helpers';
import { mapGetters } from 'vuex';
export default {
  name: 'correct',
  props: ['params'],
  data() {
    return {
      detailDialog: { visible: false, loading: false, detailFrom: '' },
      row: {},
      issueList: [],
      otherIssue: '',
      issueText: '',
      form: {
        qq: '',
        phone: ''
      },
      disabled: true,
      loading: false
    };
  },
  computed: {
    ...mapGetters(['user'])
  },
  watch: {
    issueList: {
      handler() {
        this.disabled = !this.verify();
      },
      deep: true
    },
    form: {
      handler() {
        this.disabled = !this.verify();
      },
      deep: true
    },
    otherIssue: function() {
      this.disabled = !this.verify();
    }
  },
  components: {},
  methods: {
    init() {
      this.disabled = true;
      this.issueList = [];
      this.otherIssue = '';
      this.issueText = '';
      this.form = {
        qq: '',
        phone: ''
      };
    },
    show(rowkey, component = '', keywords = null, pid = null) {
      this.init();
      this.detailDialog.visible = true;
      this.detailDialog.loading = true;
      this.loading = false;
      this.row = {};
      this.detailDialog.detailFrom = component;
      let params = { id: rowkey };
      if (keywords && _.size(keywords) >= 1) {
        params.highlight = keywords.join(',');
      }
      if (this.params) {
        if (this.params.focus != undefined) params.focus = this.params.focus;
        if (this.params.highlight != undefined)
          params.highlight =
            params.highlight == undefined
              ? this.params.highlight
              : params.highlight + ',' + this.params.highlight;
        if (this.params.plan_id) params.plan_id = this.params.plan_id;
      }
      if (pid) {
        params.plan_id = pid;
      }
      messageIndex(params)
        .then(res => {
          if (res.data.state) {
            var result = res.data.data;
            this.row = result;
            this.detailDialog.loading = false;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(e => {
          this.$message.error('加载数据详情错误');
          window.console.error(e);
        });
    },
    verify(warning = false) {
      if (this.issueList.length < 1) {
        if (warning) {
          this.$message.warning('请选择纠错信息原因。');
        }
        return false;
      }

      if (this.issueList.indexOf('其他') !== -1 && this.otherIssue.length < 1) {
        if (warning) {
          this.$message.warning('请填写纠错信息原因。');
        }
        return false;
      }

      if (this.form.qq.length >= 1 && !isQQ(this.form.qq)) {
        if (warning) {
          this.$message.warning('填写的qq号码格式错误。');
        }
        return false;
      }

      if (this.form.phone.length >= 1 && !isPhone(this.form.phone)) {
        if (warning) {
          this.$message.warning('填写的手机号码格式错误。');
        }
        return false;
      }

      return true;
    },
    submit() {
      if (!this.verify(true)) {
        return false;
      }

      const issues = this.issueList.map(issue => {
        return issue === '其他' ? this.otherIssue : issue;
      });

      const data = {
        rowkey: this.row.rowkey,
        qdetail: issues,
        qdesc: this.issueText,
        qq: this.form.qq,
        phone: this.form.phone
      };

      this.loading = true;
      docFeedback(data)
        .then(res => {
          if (res.data.state) {
            this.$message.success('您已提交成功，感谢您的反馈。');
            this.detailDialog.visible = false;
            this.$emit('succ');
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch(e => {
          window.console.error(e);
          this.$message.error('纠错失败，服务错误');
        });
    },
    msgArea(row) {
      return formatMsgArea(row);
    },
    target(url) {
      window.open(url, '_blank');
    }
  }
};
</script>
<style scoped>
/* 纠错弹出框 */
.error-correction >>> .el-dialog {
  width: 900px;
  height: 90%;
}
.error-correction >>> .el-dialog__body {
  height: calc(100% - 80px);
  overflow: auto;
  padding-bottom: 20px;
}
.error-correction .mod-title {
  margin-left: 20px;
}
.error-correction-content {
  border-top: 1px #f2f2f2 solid;
  overflow: hidden;
  padding: 25px 50px 30px;
}
.error-correction-content h3 {
  line-height: 24px;
}
.error-correction-content .zyxx,
.error-correction-content .jzxx {
  font-weight: normal;
  font-size: 12px;
  width: 60px;
  text-align: center;
  height: 20px;
  line-height: 18px;
  border-radius: 4px;
  border-style: solid;
  display: inline-block;
  border-width: 1px;
}
.error-correction-content .zyxx {
  color: rgba(252, 93, 115, 1);
  border-color: rgba(252, 93, 115, 1);
}
.error-correction-content .jzxx {
  color: #4e88fe;
  border-color: #4e88fe;
}
.error-correction-content .abstract {
  background: rgba(248, 249, 250, 1);
  border-radius: 4px;
  padding: 20px;
  line-height: 22px;
  text-indent: 2em;
  font-size: 12px;
}
.error-correction-content .abstract-cont {
  max-height: 108px;
  overflow-y: auto;
}
.error-correction .form-item {
  background: rgba(249, 249, 249, 1);
  border-radius: 4px;
  padding: 20px;
  margin-top: 20px;
  overflow: hidden;
}
.error-correction .form-item .mod-title {
  margin: 0;
  height: 14px;
  line-height: 14px;
  margin: 5px 0;
}
.form-item-cont {
  padding: 0 30px;
  line-height: 26px;
}
.form-item-cont >>> .el-checkbox.mar-r-20 {
  margin-right: 20px;
}
.form-item >>> .el-textarea__inner {
  margin: 10px 0;
  display: block;
  overflow: auto;
  line-height: 22px;
  height: 92px;
  padding-top: 2px;
  resize: none;
}
/*.form-item >>> .el-input--small .el-input__inner{color:#C1C4CB;}*/
.error-correction .mod-title var {
  color: #7c7c7c;
  font-size: 12px;
}
.error-correction .info span {
  font-size: 12px;
  color: #707070;
  padding-right: 30px;
  line-height: 40px;
  text-align: left;
}
.error-correction .info .source_name {
  color: #707070;
}
.el-button.submit.el-button--primary {
  border: none;
}
</style>
